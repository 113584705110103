// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import React, {PropsWithChildren, useContext, useEffect, useState} from "react";
import {useAsyncOnce, useEffectOnce, usePersistedState} from "@ags-oditoo/hooks";
import {API, createAPI} from "./api";
import {User} from "./generated/api/v1/types";
import {Credentials} from "./schemas/auth";

interface APIContext {
  api: API;
  login: (credentials: Credentials) => Promise<boolean>;
  logout: () => Promise<void>;
  authenticated?: boolean;
  // state: State;
  // userId?: string;
  user?: User;
}

const apiContext = React.createContext<APIContext>({
  api: createAPI(""),
  login: async () => false,
  logout: async () => {},
  // state: {}
});

export interface APIProviderProps extends PropsWithChildren<any> {
  url: string;
}

export const APIProvider = ({ url, children }: APIProviderProps) => {
  const [user, setUser] = usePersistedState<User|undefined>(undefined, "ags-oditoo-user")
  const [authenticated, setAuthenticated] = useState<boolean|undefined>(undefined)
  const api = createAPI(url);
  useAsyncOnce(async () => {
    try {
      const {user} = await api.users.readSelf({});
      setUser(user);
      setAuthenticated(true)
    } catch (e) {
      console.error(e);
      setUser(undefined)
      setAuthenticated(false)
    }
  })
  const login = async (credentials: Credentials) => {
    if (user) return true;
    try {
      const {token} = await api.auth.login(credentials);
      const {user} = await api.users.readSelf({});
      setUser(user);
      setAuthenticated(true)
      return !!token;
    } catch (e) {
      console.error(e);
      setAuthenticated(false)
      return false;
    }
  }
  const logout = async () => {
    try {
      await api.auth.logout({});
      setUser(undefined);
      setAuthenticated(false)
    } catch (e) {
      console.error(e);
    }
  }
  return <apiContext.Provider
    value={{ api, login, logout, authenticated, user}}>{children}</apiContext.Provider>;
}
export const useAPI = () => {
  const api = useContext(apiContext);
  return api;
}
