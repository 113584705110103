// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {defaultSpacing} from "@ags-oditoo/theme";
import {ArrowBackIosOutlined} from "@mui/icons-material";
import {Button, Paper, Stack, Typography} from '@mui/material'
import React from 'react'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {DeepPartial} from "../api/generated/api/v1/types";
import {SendResetPasswordEmailRequest} from "../api/generated/api/v1/users.service";
import {Anonymous, Authenticated} from "../api/roles";
import {PasswordConfirm} from '../api/schemas/auth'
import {useAPI} from '../api/useAPI'
import {EmailForm} from "../Components/Auth/EmailForm";
import {PasswordForm} from "../Components/Auth/PasswordForm";
import {useError} from "../hooks";
import {MainRoutesRegistry} from '../routes'

export const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const isReset = !!params.get('code');

  const location = useLocation();

  const isSetup = location.pathname.endsWith('/setup');

  const [done, setDone] = React.useState(false);
  const [Error, _, withError] = useError()


  const {api} = useAPI();

  const handleResetRequest = withError(async (req: DeepPartial<SendResetPasswordEmailRequest>) => {
    if (isReset) {
      return;
    }
    await api.users.sendResetPasswordEmail(req)
    setDone(true)
  })

  const handleReset = withError(async ({password}: PasswordConfirm) => {
    if (!isReset) {
      return;
    }
    const code = params.get('code') ?? ''
    await api.users.resetPassword({code, password})
    navigate(MainRoutesRegistry.login.navigate())
  })

  // Redirect to home / login if we are here for password setup without a code
  if (isSetup && !isReset) {
    navigate(MainRoutesRegistry.home.navigate(), {replace: true})
    return null
  }

  return (
    <Stack flex={1} justifyContent='space-around' spacing={defaultSpacing}>
      <Error />
      <Paper
        sx={{
          // padding: 2,
          margin: 2,
          // paddingTop: 4,
          alignSelf: 'center',
          minWidth: [320, 512],
          background: 'none',
        }}
      >
        <Stack direction='column' spacing={4} paddingBottom={4}>
          <Typography variant='h5'>{
            !done
              ? isReset
                ? isSetup
                  ? 'Configure password'
                  : 'Change password'
                : 'Forgot password ?'
              : "If the account exists, we've sent you an email"
          }</Typography>
          <Typography variant='body1' color='text.secondary'>{
            !done
              ? isReset
                ? 'Please create a complicated password.'
                : 'No worries, we will send you an email to reset your password.'
              : 'Please check your inbox and follow the instructions.'
          }</Typography>
        </Stack>
        {
          !done && (
            isReset
              ? <PasswordForm onSubmit={handleReset} submitLabel={isSetup ? "Setup Password" : "Change Password"}/>
              : <EmailForm submitLabel="Reset Password" onSubmit={handleResetRequest}/>
          )
        }
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <Button
            href={MainRoutesRegistry.login.navigate()}
            startIcon={<ArrowBackIosOutlined/>}
          >
            Go back to login
          </Button>
        </Stack>
      </Paper>
    </Stack>
  )
}

MainRoutesRegistry['sendResetPassword'] = {
  path: '/user/password/reset',
  component: <ResetPasswordPage/>,
  public: true,
  show: false,
  navigate: () => '/user/password/reset',
  roles: [Anonymous, Authenticated],
}

MainRoutesRegistry['setupPassword'] = {
  path: '/user/password/setup',
  component: <ResetPasswordPage/>,
  public: true,
  show: false,
  navigate: () => '/user/password/setup',
  roles: [Anonymous, Authenticated],
}
