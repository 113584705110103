// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {ArrowBackIosOutlined} from "@mui/icons-material";
import {Button, Paper, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {DeepPartial} from "../api/generated/api/v1/types";
import {Anonymous, Authenticated} from "../api/roles";
import {EmailRequest} from "../api/schemas/auth";
import {useAPI} from '../api/useAPI'
import {EmailForm} from "../Components/Auth/EmailForm";
import {useAsyncOnce} from "@ags-oditoo/hooks";
import {MainRoutesRegistry} from '../routes'
import {defaultSpacing} from "@ags-oditoo/theme";

export const ConfirmEmailPage = () => {
  const [params] = useSearchParams();
  const code = params.get('code');

  const navigate = useNavigate();

  const [done, setDone] = useState(false);
  const [error, setError] = useState<string>();


  const {api} = useAPI();

  const goToLogin = () => navigate(MainRoutesRegistry.login.navigate(), {replace: true});

  useAsyncOnce(async () => {
    if (!code) {
      return
    }
    try {
      await api.users.confirmEmail({code});
      setTimeout(() => setDone(true), 1000);
    } catch (e: any) {
      console.error(e);
      setTimeout(() => setError(e.message), 1000);
    }
  })

  const handleEmailConfirmRequest = async ({email}: DeepPartial<EmailRequest>) => {
    try {
      await api.users.sendConfirmEmail({email});
      setError(undefined);
      setDone(true);
    } catch (e: any) {
      setError(e.message);
    }
  }

  if (!code) {
    goToLogin()
    return null;
  }

  return (
    <Stack flex={1} justifyContent='space-around' spacing={defaultSpacing}>
      <Paper
        sx={{
          // padding: 2,
          margin: 2,
          // paddingTop: 4,
          alignSelf: 'center',
          minWidth: [320, 512],
          background: 'none',
        }}
      >
        <Stack direction='column' spacing={4} paddingBottom={4}>
          {
            error
              ? (
                done
                  ? (
                    <>
                      <Typography variant='h5' textAlign='center'>
                        An email has been sent to you.
                      </Typography>
                      <Typography variant='body1'  textAlign='center'>
                        Please check your inbox.
                      </Typography>
                    </>
                  )
                  : (
                    <Typography variant='h5' color='error' textAlign='center'>
                      {error}
                    </Typography>
                  )
              )
              : (
                done
                  ? (
                    <>
                      <Typography variant='h5' textAlign='center'>
                        Your email has been confirmed.
                      </Typography>
                      <Typography variant='body1' textAlign='center'>
                        You can now login.
                      </Typography>
                    </>
                  )
                  : (
                    <Typography variant='h5' textAlign='center'>
                      Please wait while we confirm your email address ...
                    </Typography>
                  )
              )
          }
        </Stack>
        {
          error && (
            <Stack>
              <Typography variant='body1' textAlign='center'>
                An error occurred while confirming your email address. Please try again.
              </Typography>
              <EmailForm submitLabel="Resend link" onSubmit={handleEmailConfirmRequest}/>
            </Stack>
          )
        }
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <Button
            href={MainRoutesRegistry.login.navigate()}
            startIcon={<ArrowBackIosOutlined/>}
          >
            Go back to login
          </Button>
        </Stack>
      </Paper>
    </Stack>
  )
}

MainRoutesRegistry['confirmEmail'] = {
  path: '/user/email/confirm',
  component: <ConfirmEmailPage/>,
  public: true,
  show: false,
  navigate: () => '/user/email/confirm',
  roles: [Anonymous, Authenticated],
}
