// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {Paper, Stack, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {Admin, Anonymous, Authenticated, OrgAdmin, OrgReader, Reader, User} from "../api/roles";
import {Credentials} from '../api/schemas/auth'
import {useAPI} from '../api/useAPI'
import {LoginForm} from '../Components/Auth/LoginForm'
import {Redirected} from "../Router";
import {MainRoutesRegistry} from '../routes'

export const LoginPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const from = (location.state as Redirected)?.from
    ? location.state.from.pathname + location.state.from.search
    : '/'

  const {login: _login, authenticated} = useAPI()
  const [error, setError] = useState('')
  useEffect(() => {
    console.log('authenticated:', authenticated, 'from:', from)
    if (authenticated) navigate(from !== '/login' && from !== '/logout' ? from : '/', {replace: true})
  }, [authenticated])
  const login = async (c: Credentials) => {
    const success = await _login(c)
    if (!success) {
      setError('invalid email or password')
      return
    }
  }
  return (
    <Stack flex={1} justifyContent='space-around'>
      <Paper
        sx={{
          // padding: 2,
          margin: 2,
          // paddingTop: 4,
          alignSelf: 'center',
          minWidth: [320, 512],
          background: 'none',
        }}
      >
        <Stack direction='column' spacing={4} paddingBottom={4}>
          <Typography variant='h5'>Welcome back</Typography>
          <Typography variant='body1' color='text.secondary'>Let's get started! Please enter your
            details.</Typography>
        </Stack>
        <LoginForm onLogin={login} forgotPasswordLink="/user/password/reset"/>
        <Typography variant='h5' color='error' textAlign='center'>
          {error}
        </Typography>
      </Paper>
    </Stack>
  )
}

MainRoutesRegistry['login'] = {
  path: '/login',
  component: <LoginPage/>,
  public: true,
  show: false,
  navigate: () => '/login',
  roles: [Admin, Reader, OrgAdmin, OrgReader, User, Authenticated, Anonymous],
}
