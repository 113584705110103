// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {Avatar, Link} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import md5 from "md5";
import React, {useMemo} from "react";
import {Execution, Organisation, User} from "../../api/generated/api/v1/types";
import {Table} from "../Table";
import {BaseTableProps} from "../Table/BaseTableProps";

export interface HiddenColumns {
  organisation?: boolean;
  version?: boolean;
}

export interface ExecutionsTableProps extends BaseTableProps<Execution> {
  executions?: Execution[];
  userLink?: (user?: User) => string;
  organisationLink?: (organisation?: string) => string;
  versionLink?: (version?: string) => string;
  hide?: HiddenColumns;
}

export const ExecutionsTable = ({executions, userLink, versionLink, hide, organisationLink}: ExecutionsTableProps) => {
  const columns = useMemo<GridColDef<Execution>[]>(() => ([
    {
      field: 'avatar',
      headerName: '',
      type: 'avatar',
      sortable: false,
      renderCell: ({row}) => <Avatar component={userLink ? Link : 'div'} href={userLink ? userLink(row.user) : undefined}  sx={{width: 35, height: 35}}
                                     src={`https://www.gravatar.com/avatar/${md5(row.user?.email || row.user?.name + '@unknown.org')}?d=retro`}/>,
      minWidth: 50,
      width: 50,
    },
    {
      field: 'user.name',
      headerName: 'Username',
      // minWidth: 200,
      valueGetter: ({row}) => row.user?.name,
      renderCell: ({row}) => userLink ? <Link href={userLink(row.user)}>{row.user?.name ?? ''}</Link> : row.user?.name,
      flex: 1,
    },
    {
      field: 'organisation.name',
      headerName: 'Organisation',
      // minWidth: 200,
      valueGetter: ({row}) => row.organisation?.name,
      flex: 1,
      hidden: hide?.organisation,
      renderCell: ({row}) => organisationLink ? <Link href={organisationLink(row.organisation?.name ?? '')}>{row.organisation?.name ?? ''}</Link> : row.organisation?.name ?? '',
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      type: 'dateTime',
      flex: 1,
    },
    {
      field: 'version',
      headerName: 'Version',
      valueGetter: ({row}) => row.release?.version,
      flex: 1,
      hidden: hide?.version,
      renderCell: ({row}) => versionLink ? <Link href={versionLink(row.release?.version)}>{row.release?.version ?? ''}</Link> : row.release?.version,
    }
  ]), [])
  return (
    <Table columns={columns} rows={executions ?? []} loading={!executions} />
  )
}
