/* eslint-disable */
import { messageTypeRegistry } from "../../typeRegistry";
import { Timestamp } from "../../google/protobuf/timestamp";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "oditoo.v1";

/** Role is the user role in the application. */
export enum Role {
  /** NONE - None is the default value. */
  NONE = 0,
  /** USER - User is the default user role. */
  USER = 1,
  /** ORG_READER - OrgReader is the user role with read-only access to the organisation. */
  ORG_READER = 2,
  /** ORG_ADMIN - OrgAdmin is the user role with full access to the organisation. */
  ORG_ADMIN = 3,
  /** READER - Reader is the user role with read-only access to the application and all organisations. */
  READER = 4,
  /** ADMIN - Admin is the user role with full access to the application and all organisations. */
  ADMIN = 5,
  /** RELEASER - Releaser is the role with access to the release process. */
  RELEASER = 6,
  UNRECOGNIZED = -1,
}

export function roleFromJSON(object: any): Role {
  switch (object) {
    case 0:
    case "NONE":
      return Role.NONE;
    case 1:
    case "USER":
      return Role.USER;
    case 2:
    case "ORG_READER":
      return Role.ORG_READER;
    case 3:
    case "ORG_ADMIN":
      return Role.ORG_ADMIN;
    case 4:
    case "READER":
      return Role.READER;
    case 5:
    case "ADMIN":
      return Role.ADMIN;
    case 6:
    case "RELEASER":
      return Role.RELEASER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Role.UNRECOGNIZED;
  }
}

export function roleToJSON(object: Role): string {
  switch (object) {
    case Role.NONE:
      return "NONE";
    case Role.USER:
      return "USER";
    case Role.ORG_READER:
      return "ORG_READER";
    case Role.ORG_ADMIN:
      return "ORG_ADMIN";
    case Role.READER:
      return "READER";
    case Role.ADMIN:
      return "ADMIN";
    case Role.RELEASER:
      return "RELEASER";
    case Role.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum TokenType {
  /** SESSION - Session is a session token. */
  SESSION = 0,
  /** API - API is an API token. */
  API = 1,
  UNRECOGNIZED = -1,
}

export function tokenTypeFromJSON(object: any): TokenType {
  switch (object) {
    case 0:
    case "SESSION":
      return TokenType.SESSION;
    case 1:
    case "API":
      return TokenType.API;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TokenType.UNRECOGNIZED;
  }
}

export function tokenTypeToJSON(object: TokenType): string {
  switch (object) {
    case TokenType.SESSION:
      return "SESSION";
    case TokenType.API:
      return "API";
    case TokenType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Release is client binary release. */
export interface Release {
  $type: "oditoo.v1.Release";
  /**
   * ID is the universal unique identifier of the release.
   * It is generated by the server and is immutable.
   */
  id: string;
  /**
   * CreatedAt is the build creation in the database timestamp.
   * It is generated by the server and is immutable.
   */
  createdAt?: Date;
  /**
   * UpdatedAt is the build last update in the database timestamp.
   * It is generated by the server and is updated each time .
   */
  updatedAt?: Date;
  /**
   * DeletedAt is the build deletion in the database timestamp.
   * It is used to soft delete the build.
   * It is generated by the server on deletion.
   */
  deletedAt?: Date;
  /**
   * Version is the release version.
   * It must be a valid semantic version, see https://semver.org/.
   * Example: 1.0.0-alpha.1+build.1 or v0.0.0-1643ab1-dev
   */
  version: string;
  /**
   * BuildDate is the release binary build date.
   * It is an immutable field.
   */
  buildDate?: Date;
  /**
   * Hash is the binary hash.
   * It is an immutable field.
   */
  hash: string;
  /** Assets are the release assets. */
  assets: Uint8Array;
  /**
   * Executions is the list of executions of this release.
   * The list is ordered by execution date and is read-only.
   */
  executions: Execution[];
  /** OrganisationID is the organisation this release has been built for. */
  organisationId?: string | undefined;
  /**
   * Organisation is the organisation this release has been built for.
   * This field is read-only.
   */
  organisation?: Organisation;
}

export interface Organisation {
  $type: "oditoo.v1.Organisation";
  /**
   * ID is the universal unique identifier of the release.
   * It is generated by the server and is immutable.
   */
  id: string;
  /**
   * CreatedAt is the organisation creation in the database timestamp.
   * It is generated by the server and is immutable.
   */
  createdAt?: Date;
  /**
   * UpdatedAt is the organisation last update in the database timestamp.
   * It is generated by the server and is updated each time .
   */
  updatedAt?: Date;
  /**
   * DeletedAt is the organisation deletion in the database timestamp.
   * It is used to soft delete the organisation.
   * It is generated by the server on deletion.
   */
  deletedAt?: Date;
  /** Disabled is true if the organisation is disabled. */
  disabled: boolean;
  /**
   * Name is the organisation name.
   * It must be unique and between 3 and 100 characters.
   */
  name: string;
  /**
   * Users are the organisation's users
   * The field is read-only
   */
  users: User[];
  /**
   * Executions is the list of distillation executions done by the organisation users.
   * The field is read-only
   */
  executions: Execution[];
  /** Releases is the list of releases built specifically for this organisation. */
  releases: Release[];
}

export interface User {
  $type: "oditoo.v1.User";
  /**
   * ID is the universal unique identifier of the user.
   * It is generated by the server and is immutable.
   */
  id: string;
  /**
   * CreatedAt is the user creation in the database timestamp.
   * It is generated by the server and is immutable.
   */
  createdAt?: Date;
  /**
   * UpdatedAt is the user last update in the database timestamp.
   * It is generated by the server and is updated each time .
   */
  updatedAt?: Date;
  /**
   * DeletedAt is the user deletion in the database timestamp.
   * It is used to soft delete the user.
   * It is generated by the server on deletion.
   */
  deletedAt?: Date;
  /** Disabled is true if the user is disabled. */
  disabled: boolean;
  /** Name is the user name. */
  name: string;
  /** Email is the user email. */
  email: string;
  /** EmailVerified is true if the user email has been verified. */
  emailVerified: boolean;
  /** Role is the user role. */
  role: Role;
  /** OrganisationID is the organisation the user belongs to. */
  organisationId: string;
  /**
   * Organisation is the organisations the user belongs to.
   * This field is read-only.
   */
  organisation?: Organisation;
  /**
   * Executions is the list of distillation executions done by this user.
   * The field is read-only
   */
  executions: Execution[];
}

export interface Execution {
  $type: "oditoo.v1.Execution";
  /**
   * ID is the universal unique identifier of the execution.
   * It is generated by the server and is immutable.
   */
  id: string;
  /**
   * CreatedAt is the execution creation in the database timestamp.
   * It is generated by the server and is immutable.
   */
  createdAt?: Date;
  /**
   * UpdatedAt is the execution last update in the database timestamp.
   * It is generated by the server and is updated each time.
   */
  updatedAt?: Date;
  /**
   * DeletedAt is the execution deletion in the database timestamp.
   * It is used to soft delete the execution.
   * It is generated by the server on deletion.
   */
  deletedAt?: Date;
  /** OrganisationID is the organisation the execution belongs to. */
  organisationId: string;
  /**
   * Organisation is the organisation for which the distillation was executed.
   * The field is read-only
   */
  organisation?: Organisation;
  /** UserID is the user who executed the distillation. */
  userId: string;
  /**
   * User is the user who executed the distillation.
   * The field is read-only
   */
  user?: User;
  /** ReleaseID is the release for which the distillation was executed. */
  releaseId: string;
  /**
   * Release is the release executed.
   * The field is read-only
   */
  release?: Release;
  /**
   * ModelHash is the hash of the model provided by the user.
   * This field is read-only.
   */
  modelHash: string;
  /**
   * TrainDataHash is the hash of the training data provided by the user.
   * This field is read-only.
   */
  trainDataHash: string;
  /**
   * ValidationDataHash is the hash of the validation data provided by the user.
   * This field is read-only.
   */
  validationDataHash: string;
}

export interface Token {
  $type: "oditoo.v1.Token";
  /**
   * ID is the universal unique identifier of the execution.
   * It is generated by the server and is immutable.
   */
  id: string;
  /**
   * CreatedAt is the execution creation in the database timestamp.
   * It is generated by the server and is immutable.
   */
  createdAt?: Date;
  /**
   * UpdatedAt is the execution last update in the database timestamp.
   * It is generated by the server and is updated each time.
   */
  updatedAt?: Date;
  /**
   * DeletedAt is the execution deletion in the database timestamp.
   * It is used to soft delete the execution.
   * It is generated by the server on deletion.
   */
  deletedAt?: Date;
  /**
   * Type is the token type.
   * It is immutable.
   */
  type: TokenType;
  /** Name is the token name. */
  name: string;
  /** Role is the token role. */
  role: Role;
  /** OrganisationID is the organisation the execution belongs to. */
  organisationId: string;
  /**
   * Organisation is the organisation for which the distillation was executed.
   * The field is read-only
   */
  organisation?: Organisation;
  /** UserID is the user who executed the distillation. */
  userId: string;
  /**
   * User is the user who executed the distillation.
   * The field is read-only
   */
  user?: User;
  /** Hash is the hash of the token. */
  hash: Uint8Array;
  /**
   * LastUsedAt is the last time the token was used.
   * It is a read-only field.
   */
  lastUsedAt?: Date;
}

function createBaseRelease(): Release {
  return {
    $type: "oditoo.v1.Release",
    id: "",
    createdAt: undefined,
    updatedAt: undefined,
    deletedAt: undefined,
    version: "",
    buildDate: undefined,
    hash: "",
    assets: new Uint8Array(),
    executions: [],
    organisationId: undefined,
    organisation: undefined,
  };
}

export const Release = {
  $type: "oditoo.v1.Release" as const,

  encode(
    message: Release,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createdAt),
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.updatedAt),
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.deletedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.deletedAt),
        writer.uint32(34).fork()
      ).ldelim();
    }
    if (message.version !== "") {
      writer.uint32(42).string(message.version);
    }
    if (message.buildDate !== undefined) {
      Timestamp.encode(
        toTimestamp(message.buildDate),
        writer.uint32(50).fork()
      ).ldelim();
    }
    if (message.hash !== "") {
      writer.uint32(58).string(message.hash);
    }
    if (message.assets.length !== 0) {
      writer.uint32(66).bytes(message.assets);
    }
    for (const v of message.executions) {
      Execution.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    if (message.organisationId !== undefined) {
      writer.uint32(82).string(message.organisationId);
    }
    if (message.organisation !== undefined) {
      Organisation.encode(
        message.organisation,
        writer.uint32(90).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Release {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRelease();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.createdAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 3:
          message.updatedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 4:
          message.deletedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 5:
          message.version = reader.string();
          break;
        case 6:
          message.buildDate = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 7:
          message.hash = reader.string();
          break;
        case 8:
          message.assets = reader.bytes();
          break;
        case 9:
          message.executions.push(Execution.decode(reader, reader.uint32()));
          break;
        case 10:
          message.organisationId = reader.string();
          break;
        case 11:
          message.organisation = Organisation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Release {
    return {
      $type: Release.$type,
      id: isSet(object.id) ? String(object.id) : "",
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      updatedAt: isSet(object.updatedAt)
        ? fromJsonTimestamp(object.updatedAt)
        : undefined,
      deletedAt: isSet(object.deletedAt)
        ? fromJsonTimestamp(object.deletedAt)
        : undefined,
      version: isSet(object.version) ? String(object.version) : "",
      buildDate: isSet(object.buildDate)
        ? fromJsonTimestamp(object.buildDate)
        : undefined,
      hash: isSet(object.hash) ? String(object.hash) : "",
      assets: isSet(object.assets)
        ? bytesFromBase64(object.assets)
        : new Uint8Array(),
      executions: Array.isArray(object?.executions)
        ? object.executions.map((e: any) => Execution.fromJSON(e))
        : [],
      organisationId: isSet(object.organisationId)
        ? String(object.organisationId)
        : undefined,
      organisation: isSet(object.organisation)
        ? Organisation.fromJSON(object.organisation)
        : undefined,
    };
  },

  toJSON(message: Release): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createdAt !== undefined &&
      (obj.createdAt = message.createdAt.toISOString());
    message.updatedAt !== undefined &&
      (obj.updatedAt = message.updatedAt.toISOString());
    message.deletedAt !== undefined &&
      (obj.deletedAt = message.deletedAt.toISOString());
    message.version !== undefined && (obj.version = message.version);
    message.buildDate !== undefined &&
      (obj.buildDate = message.buildDate.toISOString());
    message.hash !== undefined && (obj.hash = message.hash);
    message.assets !== undefined &&
      (obj.assets = base64FromBytes(
        message.assets !== undefined ? message.assets : new Uint8Array()
      ));
    if (message.executions) {
      obj.executions = message.executions.map((e) =>
        e ? Execution.toJSON(e) : undefined
      );
    } else {
      obj.executions = [];
    }
    message.organisationId !== undefined &&
      (obj.organisationId = message.organisationId);
    message.organisation !== undefined &&
      (obj.organisation = message.organisation
        ? Organisation.toJSON(message.organisation)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<Release>): Release {
    const message = createBaseRelease();
    message.id = object.id ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.deletedAt = object.deletedAt ?? undefined;
    message.version = object.version ?? "";
    message.buildDate = object.buildDate ?? undefined;
    message.hash = object.hash ?? "";
    message.assets = object.assets ?? new Uint8Array();
    message.executions =
      object.executions?.map((e) => Execution.fromPartial(e)) || [];
    message.organisationId = object.organisationId ?? undefined;
    message.organisation =
      object.organisation !== undefined && object.organisation !== null
        ? Organisation.fromPartial(object.organisation)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(Release.$type, Release);

function createBaseOrganisation(): Organisation {
  return {
    $type: "oditoo.v1.Organisation",
    id: "",
    createdAt: undefined,
    updatedAt: undefined,
    deletedAt: undefined,
    disabled: false,
    name: "",
    users: [],
    executions: [],
    releases: [],
  };
}

export const Organisation = {
  $type: "oditoo.v1.Organisation" as const,

  encode(
    message: Organisation,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createdAt),
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.updatedAt),
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.deletedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.deletedAt),
        writer.uint32(34).fork()
      ).ldelim();
    }
    if (message.disabled === true) {
      writer.uint32(40).bool(message.disabled);
    }
    if (message.name !== "") {
      writer.uint32(50).string(message.name);
    }
    for (const v of message.users) {
      User.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.executions) {
      Execution.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.releases) {
      Release.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Organisation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganisation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.createdAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 3:
          message.updatedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 4:
          message.deletedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 5:
          message.disabled = reader.bool();
          break;
        case 6:
          message.name = reader.string();
          break;
        case 7:
          message.users.push(User.decode(reader, reader.uint32()));
          break;
        case 8:
          message.executions.push(Execution.decode(reader, reader.uint32()));
          break;
        case 9:
          message.releases.push(Release.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Organisation {
    return {
      $type: Organisation.$type,
      id: isSet(object.id) ? String(object.id) : "",
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      updatedAt: isSet(object.updatedAt)
        ? fromJsonTimestamp(object.updatedAt)
        : undefined,
      deletedAt: isSet(object.deletedAt)
        ? fromJsonTimestamp(object.deletedAt)
        : undefined,
      disabled: isSet(object.disabled) ? Boolean(object.disabled) : false,
      name: isSet(object.name) ? String(object.name) : "",
      users: Array.isArray(object?.users)
        ? object.users.map((e: any) => User.fromJSON(e))
        : [],
      executions: Array.isArray(object?.executions)
        ? object.executions.map((e: any) => Execution.fromJSON(e))
        : [],
      releases: Array.isArray(object?.releases)
        ? object.releases.map((e: any) => Release.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Organisation): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createdAt !== undefined &&
      (obj.createdAt = message.createdAt.toISOString());
    message.updatedAt !== undefined &&
      (obj.updatedAt = message.updatedAt.toISOString());
    message.deletedAt !== undefined &&
      (obj.deletedAt = message.deletedAt.toISOString());
    message.disabled !== undefined && (obj.disabled = message.disabled);
    message.name !== undefined && (obj.name = message.name);
    if (message.users) {
      obj.users = message.users.map((e) => (e ? User.toJSON(e) : undefined));
    } else {
      obj.users = [];
    }
    if (message.executions) {
      obj.executions = message.executions.map((e) =>
        e ? Execution.toJSON(e) : undefined
      );
    } else {
      obj.executions = [];
    }
    if (message.releases) {
      obj.releases = message.releases.map((e) =>
        e ? Release.toJSON(e) : undefined
      );
    } else {
      obj.releases = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<Organisation>): Organisation {
    const message = createBaseOrganisation();
    message.id = object.id ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.deletedAt = object.deletedAt ?? undefined;
    message.disabled = object.disabled ?? false;
    message.name = object.name ?? "";
    message.users = object.users?.map((e) => User.fromPartial(e)) || [];
    message.executions =
      object.executions?.map((e) => Execution.fromPartial(e)) || [];
    message.releases =
      object.releases?.map((e) => Release.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(Organisation.$type, Organisation);

function createBaseUser(): User {
  return {
    $type: "oditoo.v1.User",
    id: "",
    createdAt: undefined,
    updatedAt: undefined,
    deletedAt: undefined,
    disabled: false,
    name: "",
    email: "",
    emailVerified: false,
    role: 0,
    organisationId: "",
    organisation: undefined,
    executions: [],
  };
}

export const User = {
  $type: "oditoo.v1.User" as const,

  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createdAt),
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.updatedAt),
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.deletedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.deletedAt),
        writer.uint32(34).fork()
      ).ldelim();
    }
    if (message.disabled === true) {
      writer.uint32(40).bool(message.disabled);
    }
    if (message.name !== "") {
      writer.uint32(50).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(58).string(message.email);
    }
    if (message.emailVerified === true) {
      writer.uint32(64).bool(message.emailVerified);
    }
    if (message.role !== 0) {
      writer.uint32(72).int32(message.role);
    }
    if (message.organisationId !== "") {
      writer.uint32(82).string(message.organisationId);
    }
    if (message.organisation !== undefined) {
      Organisation.encode(
        message.organisation,
        writer.uint32(90).fork()
      ).ldelim();
    }
    for (const v of message.executions) {
      Execution.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.createdAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 3:
          message.updatedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 4:
          message.deletedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 5:
          message.disabled = reader.bool();
          break;
        case 6:
          message.name = reader.string();
          break;
        case 7:
          message.email = reader.string();
          break;
        case 8:
          message.emailVerified = reader.bool();
          break;
        case 9:
          message.role = reader.int32() as any;
          break;
        case 10:
          message.organisationId = reader.string();
          break;
        case 11:
          message.organisation = Organisation.decode(reader, reader.uint32());
          break;
        case 12:
          message.executions.push(Execution.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      $type: User.$type,
      id: isSet(object.id) ? String(object.id) : "",
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      updatedAt: isSet(object.updatedAt)
        ? fromJsonTimestamp(object.updatedAt)
        : undefined,
      deletedAt: isSet(object.deletedAt)
        ? fromJsonTimestamp(object.deletedAt)
        : undefined,
      disabled: isSet(object.disabled) ? Boolean(object.disabled) : false,
      name: isSet(object.name) ? String(object.name) : "",
      email: isSet(object.email) ? String(object.email) : "",
      emailVerified: isSet(object.emailVerified)
        ? Boolean(object.emailVerified)
        : false,
      role: isSet(object.role) ? roleFromJSON(object.role) : 0,
      organisationId: isSet(object.organisationId)
        ? String(object.organisationId)
        : "",
      organisation: isSet(object.organisation)
        ? Organisation.fromJSON(object.organisation)
        : undefined,
      executions: Array.isArray(object?.executions)
        ? object.executions.map((e: any) => Execution.fromJSON(e))
        : [],
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createdAt !== undefined &&
      (obj.createdAt = message.createdAt.toISOString());
    message.updatedAt !== undefined &&
      (obj.updatedAt = message.updatedAt.toISOString());
    message.deletedAt !== undefined &&
      (obj.deletedAt = message.deletedAt.toISOString());
    message.disabled !== undefined && (obj.disabled = message.disabled);
    message.name !== undefined && (obj.name = message.name);
    message.email !== undefined && (obj.email = message.email);
    message.emailVerified !== undefined &&
      (obj.emailVerified = message.emailVerified);
    message.role !== undefined && (obj.role = roleToJSON(message.role));
    message.organisationId !== undefined &&
      (obj.organisationId = message.organisationId);
    message.organisation !== undefined &&
      (obj.organisation = message.organisation
        ? Organisation.toJSON(message.organisation)
        : undefined);
    if (message.executions) {
      obj.executions = message.executions.map((e) =>
        e ? Execution.toJSON(e) : undefined
      );
    } else {
      obj.executions = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<User>): User {
    const message = createBaseUser();
    message.id = object.id ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.deletedAt = object.deletedAt ?? undefined;
    message.disabled = object.disabled ?? false;
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.emailVerified = object.emailVerified ?? false;
    message.role = object.role ?? 0;
    message.organisationId = object.organisationId ?? "";
    message.organisation =
      object.organisation !== undefined && object.organisation !== null
        ? Organisation.fromPartial(object.organisation)
        : undefined;
    message.executions =
      object.executions?.map((e) => Execution.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(User.$type, User);

function createBaseExecution(): Execution {
  return {
    $type: "oditoo.v1.Execution",
    id: "",
    createdAt: undefined,
    updatedAt: undefined,
    deletedAt: undefined,
    organisationId: "",
    organisation: undefined,
    userId: "",
    user: undefined,
    releaseId: "",
    release: undefined,
    modelHash: "",
    trainDataHash: "",
    validationDataHash: "",
  };
}

export const Execution = {
  $type: "oditoo.v1.Execution" as const,

  encode(
    message: Execution,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createdAt),
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.updatedAt),
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.deletedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.deletedAt),
        writer.uint32(34).fork()
      ).ldelim();
    }
    if (message.organisationId !== "") {
      writer.uint32(42).string(message.organisationId);
    }
    if (message.organisation !== undefined) {
      Organisation.encode(
        message.organisation,
        writer.uint32(50).fork()
      ).ldelim();
    }
    if (message.userId !== "") {
      writer.uint32(58).string(message.userId);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(66).fork()).ldelim();
    }
    if (message.releaseId !== "") {
      writer.uint32(74).string(message.releaseId);
    }
    if (message.release !== undefined) {
      Release.encode(message.release, writer.uint32(82).fork()).ldelim();
    }
    if (message.modelHash !== "") {
      writer.uint32(90).string(message.modelHash);
    }
    if (message.trainDataHash !== "") {
      writer.uint32(98).string(message.trainDataHash);
    }
    if (message.validationDataHash !== "") {
      writer.uint32(106).string(message.validationDataHash);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Execution {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExecution();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.createdAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 3:
          message.updatedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 4:
          message.deletedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 5:
          message.organisationId = reader.string();
          break;
        case 6:
          message.organisation = Organisation.decode(reader, reader.uint32());
          break;
        case 7:
          message.userId = reader.string();
          break;
        case 8:
          message.user = User.decode(reader, reader.uint32());
          break;
        case 9:
          message.releaseId = reader.string();
          break;
        case 10:
          message.release = Release.decode(reader, reader.uint32());
          break;
        case 11:
          message.modelHash = reader.string();
          break;
        case 12:
          message.trainDataHash = reader.string();
          break;
        case 13:
          message.validationDataHash = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Execution {
    return {
      $type: Execution.$type,
      id: isSet(object.id) ? String(object.id) : "",
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      updatedAt: isSet(object.updatedAt)
        ? fromJsonTimestamp(object.updatedAt)
        : undefined,
      deletedAt: isSet(object.deletedAt)
        ? fromJsonTimestamp(object.deletedAt)
        : undefined,
      organisationId: isSet(object.organisationId)
        ? String(object.organisationId)
        : "",
      organisation: isSet(object.organisation)
        ? Organisation.fromJSON(object.organisation)
        : undefined,
      userId: isSet(object.userId) ? String(object.userId) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      releaseId: isSet(object.releaseId) ? String(object.releaseId) : "",
      release: isSet(object.release)
        ? Release.fromJSON(object.release)
        : undefined,
      modelHash: isSet(object.modelHash) ? String(object.modelHash) : "",
      trainDataHash: isSet(object.trainDataHash)
        ? String(object.trainDataHash)
        : "",
      validationDataHash: isSet(object.validationDataHash)
        ? String(object.validationDataHash)
        : "",
    };
  },

  toJSON(message: Execution): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createdAt !== undefined &&
      (obj.createdAt = message.createdAt.toISOString());
    message.updatedAt !== undefined &&
      (obj.updatedAt = message.updatedAt.toISOString());
    message.deletedAt !== undefined &&
      (obj.deletedAt = message.deletedAt.toISOString());
    message.organisationId !== undefined &&
      (obj.organisationId = message.organisationId);
    message.organisation !== undefined &&
      (obj.organisation = message.organisation
        ? Organisation.toJSON(message.organisation)
        : undefined);
    message.userId !== undefined && (obj.userId = message.userId);
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    message.releaseId !== undefined && (obj.releaseId = message.releaseId);
    message.release !== undefined &&
      (obj.release = message.release
        ? Release.toJSON(message.release)
        : undefined);
    message.modelHash !== undefined && (obj.modelHash = message.modelHash);
    message.trainDataHash !== undefined &&
      (obj.trainDataHash = message.trainDataHash);
    message.validationDataHash !== undefined &&
      (obj.validationDataHash = message.validationDataHash);
    return obj;
  },

  fromPartial(object: DeepPartial<Execution>): Execution {
    const message = createBaseExecution();
    message.id = object.id ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.deletedAt = object.deletedAt ?? undefined;
    message.organisationId = object.organisationId ?? "";
    message.organisation =
      object.organisation !== undefined && object.organisation !== null
        ? Organisation.fromPartial(object.organisation)
        : undefined;
    message.userId = object.userId ?? "";
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    message.releaseId = object.releaseId ?? "";
    message.release =
      object.release !== undefined && object.release !== null
        ? Release.fromPartial(object.release)
        : undefined;
    message.modelHash = object.modelHash ?? "";
    message.trainDataHash = object.trainDataHash ?? "";
    message.validationDataHash = object.validationDataHash ?? "";
    return message;
  },
};

messageTypeRegistry.set(Execution.$type, Execution);

function createBaseToken(): Token {
  return {
    $type: "oditoo.v1.Token",
    id: "",
    createdAt: undefined,
    updatedAt: undefined,
    deletedAt: undefined,
    type: 0,
    name: "",
    role: 0,
    organisationId: "",
    organisation: undefined,
    userId: "",
    user: undefined,
    hash: new Uint8Array(),
    lastUsedAt: undefined,
  };
}

export const Token = {
  $type: "oditoo.v1.Token" as const,

  encode(message: Token, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createdAt),
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.updatedAt),
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.deletedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.deletedAt),
        writer.uint32(34).fork()
      ).ldelim();
    }
    if (message.type !== 0) {
      writer.uint32(40).int32(message.type);
    }
    if (message.name !== "") {
      writer.uint32(50).string(message.name);
    }
    if (message.role !== 0) {
      writer.uint32(56).int32(message.role);
    }
    if (message.organisationId !== "") {
      writer.uint32(66).string(message.organisationId);
    }
    if (message.organisation !== undefined) {
      Organisation.encode(
        message.organisation,
        writer.uint32(74).fork()
      ).ldelim();
    }
    if (message.userId !== "") {
      writer.uint32(82).string(message.userId);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(90).fork()).ldelim();
    }
    if (message.hash.length !== 0) {
      writer.uint32(98).bytes(message.hash);
    }
    if (message.lastUsedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.lastUsedAt),
        writer.uint32(106).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Token {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToken();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.createdAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 3:
          message.updatedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 4:
          message.deletedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 5:
          message.type = reader.int32() as any;
          break;
        case 6:
          message.name = reader.string();
          break;
        case 7:
          message.role = reader.int32() as any;
          break;
        case 8:
          message.organisationId = reader.string();
          break;
        case 9:
          message.organisation = Organisation.decode(reader, reader.uint32());
          break;
        case 10:
          message.userId = reader.string();
          break;
        case 11:
          message.user = User.decode(reader, reader.uint32());
          break;
        case 12:
          message.hash = reader.bytes();
          break;
        case 13:
          message.lastUsedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Token {
    return {
      $type: Token.$type,
      id: isSet(object.id) ? String(object.id) : "",
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      updatedAt: isSet(object.updatedAt)
        ? fromJsonTimestamp(object.updatedAt)
        : undefined,
      deletedAt: isSet(object.deletedAt)
        ? fromJsonTimestamp(object.deletedAt)
        : undefined,
      type: isSet(object.type) ? tokenTypeFromJSON(object.type) : 0,
      name: isSet(object.name) ? String(object.name) : "",
      role: isSet(object.role) ? roleFromJSON(object.role) : 0,
      organisationId: isSet(object.organisationId)
        ? String(object.organisationId)
        : "",
      organisation: isSet(object.organisation)
        ? Organisation.fromJSON(object.organisation)
        : undefined,
      userId: isSet(object.userId) ? String(object.userId) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      hash: isSet(object.hash)
        ? bytesFromBase64(object.hash)
        : new Uint8Array(),
      lastUsedAt: isSet(object.lastUsedAt)
        ? fromJsonTimestamp(object.lastUsedAt)
        : undefined,
    };
  },

  toJSON(message: Token): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createdAt !== undefined &&
      (obj.createdAt = message.createdAt.toISOString());
    message.updatedAt !== undefined &&
      (obj.updatedAt = message.updatedAt.toISOString());
    message.deletedAt !== undefined &&
      (obj.deletedAt = message.deletedAt.toISOString());
    message.type !== undefined && (obj.type = tokenTypeToJSON(message.type));
    message.name !== undefined && (obj.name = message.name);
    message.role !== undefined && (obj.role = roleToJSON(message.role));
    message.organisationId !== undefined &&
      (obj.organisationId = message.organisationId);
    message.organisation !== undefined &&
      (obj.organisation = message.organisation
        ? Organisation.toJSON(message.organisation)
        : undefined);
    message.userId !== undefined && (obj.userId = message.userId);
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    message.hash !== undefined &&
      (obj.hash = base64FromBytes(
        message.hash !== undefined ? message.hash : new Uint8Array()
      ));
    message.lastUsedAt !== undefined &&
      (obj.lastUsedAt = message.lastUsedAt.toISOString());
    return obj;
  },

  fromPartial(object: DeepPartial<Token>): Token {
    const message = createBaseToken();
    message.id = object.id ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.deletedAt = object.deletedAt ?? undefined;
    message.type = object.type ?? 0;
    message.name = object.name ?? "";
    message.role = object.role ?? 0;
    message.organisationId = object.organisationId ?? "";
    message.organisation =
      object.organisation !== undefined && object.organisation !== null
        ? Organisation.fromPartial(object.organisation)
        : undefined;
    message.userId = object.userId ?? "";
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    message.hash = object.hash ?? new Uint8Array();
    message.lastUsedAt = object.lastUsedAt ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(Token.$type, Token);

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

const atob: (b64: string) => string =
  globalThis.atob ||
  ((b64) => globalThis.Buffer.from(b64, "base64").toString("binary"));
function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

const btoa: (bin: string) => string =
  globalThis.btoa ||
  ((bin) => globalThis.Buffer.from(bin, "binary").toString("base64"));
function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach((byte) => {
    bin.push(String.fromCharCode(byte));
  });
  return btoa(bin.join(""));
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { $type: "google.protobuf.Timestamp", seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
