// Copyright 2021 Linka Cloud  All rights reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Stack } from "@mui/material";
import React, { PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import {Anonymous, userRole} from "./api/roles";
import { useAPI } from "./api/useAPI";
import { Loading } from "./Components/Loading";
import { MainRoutesRegistry, RouteDefinition, RoutesRegistry } from "./routes";

export interface Redirected {
  from: Location;
}

export const Router = () => {
  const {user} = useAPI();
  const role = userRole(user);
  const makeRoutes = useCallback(
    (reg?: RoutesRegistry, parent?: RouteDefinition) =>
      Object.values(reg ?? {}).filter(r => !!r.roles.find(r => r === role)).map((r, i) => {
        return r.public ? (
          <Route key={i} path={parent ? parent.path + "/" + r.path : r.path} element={r.component}>
            {makeRoutes(r.subRoutes, r)}
          </Route>
        ) : (
          <Route key={i} path={parent ? parent.path + "/" + r.path : r.path}
                 element={<ProtectedRoute>{r.component}</ProtectedRoute>}>
            {makeRoutes(r.subRoutes, r)}
          </Route>
        );
      }),
    [role]
  );
  return <Routes>{makeRoutes(MainRoutesRegistry)}</Routes>;
};

export const ProtectedRoute = ({ children }: PropsWithChildren<any>) => {
  const location = useLocation();
  const { authenticated } = useAPI();
  if (authenticated === undefined) {
    return <Stack position='relative' flex={1}><Loading /></Stack>;
  }
  if (!authenticated) {
    console.log("Redirecting to login", authenticated);
    return <Navigate to={MainRoutesRegistry.login.navigate()} state={{ from: location }} replace />;
  }
  return children;
};
