// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {defaultPadding, defaultSpacing} from "@ags-oditoo/theme";
import {RocketLaunchOutlined} from "@mui/icons-material";
import {Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import { DOWNLOAD_RELEASE_LINK } from "../api/api";
import {Release} from "../api/generated/api/v1/types";
import {Admin, OrgAdmin, OrgReader, Reader, User} from "../api/roles";
import {useAPI} from "../api/useAPI";
import {ReleasesTable} from "../Components/Releases/ReleasesTable";
import {useAuthenticatedAsyncOnce, useError} from "../hooks";
import {MainRoutesRegistry} from "../routes";

export const ReleasesPage = () => {
  const {api} = useAPI();
  const [releases, setReleases] = useState<Release[] | undefined>();
  const [Error, _, withError] = useError();
  useAuthenticatedAsyncOnce(withError(async () => {
    const {releases} = await api.releases.listReleases({});
    setReleases(releases)
  }))
  return (
    <Stack flex={1} spacing={defaultSpacing} sx={{padding: defaultPadding}}>
      <Error />
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h6'>Releases</Typography>
      </Stack>
      <Stack flex={1}>
        <ReleasesTable
          releases={releases}
          link={v => MainRoutesRegistry.releaseDetails.navigate(v.version)}
          downloadLink={v => DOWNLOAD_RELEASE_LINK + v.id}
          organisationLink={organisation => MainRoutesRegistry.organisationDetails.navigate(organisation)}
        />
      </Stack>
    </Stack>
  )
}

MainRoutesRegistry['releases'] = {
  path: '/releases',
  component: <ReleasesPage/>,
  icon: <RocketLaunchOutlined/>,
  priority: 800,
  public: false,
  label: 'Releases',
  show: true,
  navigate: () => '/releases',
  roles: [Admin, Reader, OrgAdmin, OrgReader, User],
}
