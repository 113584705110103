/* eslint-disable */
import { messageTypeRegistry } from "../../typeRegistry";
import { Release } from "./types";
import { CallContext, CallOptions } from "nice-grpc-common";
import Long from "long";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "oditoo.v1";

export interface CreateReleaseRequest {
  $type: "oditoo.v1.CreateReleaseRequest";
  /** Release is the release to create. */
  release?: Release;
}

export interface CreateReleaseResponse {
  $type: "oditoo.v1.CreateReleaseResponse";
  /** Release is the created release. */
  release?: Release;
}

export interface UploadReleaseRequest {
  $type: "oditoo.v1.UploadReleaseRequest";
  /** ID is the release ID */
  id: string;
  /** Data is the partial binary content */
  data: Uint8Array;
  /** Size is the total binary size */
  size: number;
}

export interface UploadReleaseResponse {
  $type: "oditoo.v1.UploadReleaseResponse";
}

export interface DownloadReleaseRequest {
  $type: "oditoo.v1.DownloadReleaseRequest";
  id: string;
}

export interface DownloadReleaseResponse {
  $type: "oditoo.v1.DownloadReleaseResponse";
  /** Data is the partial binary content */
  data: Uint8Array;
  /** Size is the total binary size */
  size: number;
}

export interface ReadReleaseRequest {
  $type: "oditoo.v1.ReadReleaseRequest";
  /** IDOrVersion is the id or the version of the release to read. */
  idOrVersion: string;
}

export interface ReadReleaseResponse {
  $type: "oditoo.v1.ReadReleaseResponse";
  /** Release is the read release. */
  release?: Release;
}

export interface ListReleasesRequest {
  $type: "oditoo.v1.ListReleasesRequest";
  /**
   * Limit is the maximum number of releases to return.
   * The server may return fewer than this at its discretion.
   * If Limit is negative or zero, the server will decide the number of releases to return.
   */
  limit: number;
  /**
   * Offset is the number of releases to skip before starting to collect the result set.
   * If Offset is negative, the server will decide the number of releases to skip.
   */
  offset: number;
}

export interface ListReleasesResponse {
  $type: "oditoo.v1.ListReleasesResponse";
  /** Releases is the list of releases. */
  releases: Release[];
}

export interface DeleteReleaseRequest {
  $type: "oditoo.v1.DeleteReleaseRequest";
  /** ID is the id of the release to delete. */
  id: string;
}

export interface DeleteReleaseResponse {
  $type: "oditoo.v1.DeleteReleaseResponse";
}

function createBaseCreateReleaseRequest(): CreateReleaseRequest {
  return { $type: "oditoo.v1.CreateReleaseRequest", release: undefined };
}

export const CreateReleaseRequest = {
  $type: "oditoo.v1.CreateReleaseRequest" as const,

  encode(
    message: CreateReleaseRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.release !== undefined) {
      Release.encode(message.release, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CreateReleaseRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateReleaseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.release = Release.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateReleaseRequest {
    return {
      $type: CreateReleaseRequest.$type,
      release: isSet(object.release)
        ? Release.fromJSON(object.release)
        : undefined,
    };
  },

  toJSON(message: CreateReleaseRequest): unknown {
    const obj: any = {};
    message.release !== undefined &&
      (obj.release = message.release
        ? Release.toJSON(message.release)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<CreateReleaseRequest>): CreateReleaseRequest {
    const message = createBaseCreateReleaseRequest();
    message.release =
      object.release !== undefined && object.release !== null
        ? Release.fromPartial(object.release)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(CreateReleaseRequest.$type, CreateReleaseRequest);

function createBaseCreateReleaseResponse(): CreateReleaseResponse {
  return { $type: "oditoo.v1.CreateReleaseResponse", release: undefined };
}

export const CreateReleaseResponse = {
  $type: "oditoo.v1.CreateReleaseResponse" as const,

  encode(
    message: CreateReleaseResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.release !== undefined) {
      Release.encode(message.release, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CreateReleaseResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateReleaseResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.release = Release.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateReleaseResponse {
    return {
      $type: CreateReleaseResponse.$type,
      release: isSet(object.release)
        ? Release.fromJSON(object.release)
        : undefined,
    };
  },

  toJSON(message: CreateReleaseResponse): unknown {
    const obj: any = {};
    message.release !== undefined &&
      (obj.release = message.release
        ? Release.toJSON(message.release)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<CreateReleaseResponse>
  ): CreateReleaseResponse {
    const message = createBaseCreateReleaseResponse();
    message.release =
      object.release !== undefined && object.release !== null
        ? Release.fromPartial(object.release)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(CreateReleaseResponse.$type, CreateReleaseResponse);

function createBaseUploadReleaseRequest(): UploadReleaseRequest {
  return {
    $type: "oditoo.v1.UploadReleaseRequest",
    id: "",
    data: new Uint8Array(),
    size: 0,
  };
}

export const UploadReleaseRequest = {
  $type: "oditoo.v1.UploadReleaseRequest" as const,

  encode(
    message: UploadReleaseRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.data.length !== 0) {
      writer.uint32(18).bytes(message.data);
    }
    if (message.size !== 0) {
      writer.uint32(24).int64(message.size);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UploadReleaseRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadReleaseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.data = reader.bytes();
          break;
        case 3:
          message.size = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UploadReleaseRequest {
    return {
      $type: UploadReleaseRequest.$type,
      id: isSet(object.id) ? String(object.id) : "",
      data: isSet(object.data)
        ? bytesFromBase64(object.data)
        : new Uint8Array(),
      size: isSet(object.size) ? Number(object.size) : 0,
    };
  },

  toJSON(message: UploadReleaseRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.data !== undefined &&
      (obj.data = base64FromBytes(
        message.data !== undefined ? message.data : new Uint8Array()
      ));
    message.size !== undefined && (obj.size = Math.round(message.size));
    return obj;
  },

  fromPartial(object: DeepPartial<UploadReleaseRequest>): UploadReleaseRequest {
    const message = createBaseUploadReleaseRequest();
    message.id = object.id ?? "";
    message.data = object.data ?? new Uint8Array();
    message.size = object.size ?? 0;
    return message;
  },
};

messageTypeRegistry.set(UploadReleaseRequest.$type, UploadReleaseRequest);

function createBaseUploadReleaseResponse(): UploadReleaseResponse {
  return { $type: "oditoo.v1.UploadReleaseResponse" };
}

export const UploadReleaseResponse = {
  $type: "oditoo.v1.UploadReleaseResponse" as const,

  encode(
    _: UploadReleaseResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UploadReleaseResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadReleaseResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): UploadReleaseResponse {
    return {
      $type: UploadReleaseResponse.$type,
    };
  },

  toJSON(_: UploadReleaseResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<UploadReleaseResponse>): UploadReleaseResponse {
    const message = createBaseUploadReleaseResponse();
    return message;
  },
};

messageTypeRegistry.set(UploadReleaseResponse.$type, UploadReleaseResponse);

function createBaseDownloadReleaseRequest(): DownloadReleaseRequest {
  return { $type: "oditoo.v1.DownloadReleaseRequest", id: "" };
}

export const DownloadReleaseRequest = {
  $type: "oditoo.v1.DownloadReleaseRequest" as const,

  encode(
    message: DownloadReleaseRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DownloadReleaseRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDownloadReleaseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DownloadReleaseRequest {
    return {
      $type: DownloadReleaseRequest.$type,
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: DownloadReleaseRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(
    object: DeepPartial<DownloadReleaseRequest>
  ): DownloadReleaseRequest {
    const message = createBaseDownloadReleaseRequest();
    message.id = object.id ?? "";
    return message;
  },
};

messageTypeRegistry.set(DownloadReleaseRequest.$type, DownloadReleaseRequest);

function createBaseDownloadReleaseResponse(): DownloadReleaseResponse {
  return {
    $type: "oditoo.v1.DownloadReleaseResponse",
    data: new Uint8Array(),
    size: 0,
  };
}

export const DownloadReleaseResponse = {
  $type: "oditoo.v1.DownloadReleaseResponse" as const,

  encode(
    message: DownloadReleaseResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.data.length !== 0) {
      writer.uint32(10).bytes(message.data);
    }
    if (message.size !== 0) {
      writer.uint32(16).int64(message.size);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DownloadReleaseResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDownloadReleaseResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.data = reader.bytes();
          break;
        case 2:
          message.size = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DownloadReleaseResponse {
    return {
      $type: DownloadReleaseResponse.$type,
      data: isSet(object.data)
        ? bytesFromBase64(object.data)
        : new Uint8Array(),
      size: isSet(object.size) ? Number(object.size) : 0,
    };
  },

  toJSON(message: DownloadReleaseResponse): unknown {
    const obj: any = {};
    message.data !== undefined &&
      (obj.data = base64FromBytes(
        message.data !== undefined ? message.data : new Uint8Array()
      ));
    message.size !== undefined && (obj.size = Math.round(message.size));
    return obj;
  },

  fromPartial(
    object: DeepPartial<DownloadReleaseResponse>
  ): DownloadReleaseResponse {
    const message = createBaseDownloadReleaseResponse();
    message.data = object.data ?? new Uint8Array();
    message.size = object.size ?? 0;
    return message;
  },
};

messageTypeRegistry.set(DownloadReleaseResponse.$type, DownloadReleaseResponse);

function createBaseReadReleaseRequest(): ReadReleaseRequest {
  return { $type: "oditoo.v1.ReadReleaseRequest", idOrVersion: "" };
}

export const ReadReleaseRequest = {
  $type: "oditoo.v1.ReadReleaseRequest" as const,

  encode(
    message: ReadReleaseRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.idOrVersion !== "") {
      writer.uint32(10).string(message.idOrVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReadReleaseRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReadReleaseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.idOrVersion = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReadReleaseRequest {
    return {
      $type: ReadReleaseRequest.$type,
      idOrVersion: isSet(object.idOrVersion) ? String(object.idOrVersion) : "",
    };
  },

  toJSON(message: ReadReleaseRequest): unknown {
    const obj: any = {};
    message.idOrVersion !== undefined &&
      (obj.idOrVersion = message.idOrVersion);
    return obj;
  },

  fromPartial(object: DeepPartial<ReadReleaseRequest>): ReadReleaseRequest {
    const message = createBaseReadReleaseRequest();
    message.idOrVersion = object.idOrVersion ?? "";
    return message;
  },
};

messageTypeRegistry.set(ReadReleaseRequest.$type, ReadReleaseRequest);

function createBaseReadReleaseResponse(): ReadReleaseResponse {
  return { $type: "oditoo.v1.ReadReleaseResponse", release: undefined };
}

export const ReadReleaseResponse = {
  $type: "oditoo.v1.ReadReleaseResponse" as const,

  encode(
    message: ReadReleaseResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.release !== undefined) {
      Release.encode(message.release, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReadReleaseResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReadReleaseResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.release = Release.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReadReleaseResponse {
    return {
      $type: ReadReleaseResponse.$type,
      release: isSet(object.release)
        ? Release.fromJSON(object.release)
        : undefined,
    };
  },

  toJSON(message: ReadReleaseResponse): unknown {
    const obj: any = {};
    message.release !== undefined &&
      (obj.release = message.release
        ? Release.toJSON(message.release)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<ReadReleaseResponse>): ReadReleaseResponse {
    const message = createBaseReadReleaseResponse();
    message.release =
      object.release !== undefined && object.release !== null
        ? Release.fromPartial(object.release)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(ReadReleaseResponse.$type, ReadReleaseResponse);

function createBaseListReleasesRequest(): ListReleasesRequest {
  return { $type: "oditoo.v1.ListReleasesRequest", limit: 0, offset: 0 };
}

export const ListReleasesRequest = {
  $type: "oditoo.v1.ListReleasesRequest" as const,

  encode(
    message: ListReleasesRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.limit !== 0) {
      writer.uint32(8).int32(message.limit);
    }
    if (message.offset !== 0) {
      writer.uint32(16).int32(message.offset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListReleasesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListReleasesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = reader.int32();
          break;
        case 2:
          message.offset = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ListReleasesRequest {
    return {
      $type: ListReleasesRequest.$type,
      limit: isSet(object.limit) ? Number(object.limit) : 0,
      offset: isSet(object.offset) ? Number(object.offset) : 0,
    };
  },

  toJSON(message: ListReleasesRequest): unknown {
    const obj: any = {};
    message.limit !== undefined && (obj.limit = Math.round(message.limit));
    message.offset !== undefined && (obj.offset = Math.round(message.offset));
    return obj;
  },

  fromPartial(object: DeepPartial<ListReleasesRequest>): ListReleasesRequest {
    const message = createBaseListReleasesRequest();
    message.limit = object.limit ?? 0;
    message.offset = object.offset ?? 0;
    return message;
  },
};

messageTypeRegistry.set(ListReleasesRequest.$type, ListReleasesRequest);

function createBaseListReleasesResponse(): ListReleasesResponse {
  return { $type: "oditoo.v1.ListReleasesResponse", releases: [] };
}

export const ListReleasesResponse = {
  $type: "oditoo.v1.ListReleasesResponse" as const,

  encode(
    message: ListReleasesResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.releases) {
      Release.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListReleasesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListReleasesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.releases.push(Release.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ListReleasesResponse {
    return {
      $type: ListReleasesResponse.$type,
      releases: Array.isArray(object?.releases)
        ? object.releases.map((e: any) => Release.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListReleasesResponse): unknown {
    const obj: any = {};
    if (message.releases) {
      obj.releases = message.releases.map((e) =>
        e ? Release.toJSON(e) : undefined
      );
    } else {
      obj.releases = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<ListReleasesResponse>): ListReleasesResponse {
    const message = createBaseListReleasesResponse();
    message.releases =
      object.releases?.map((e) => Release.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ListReleasesResponse.$type, ListReleasesResponse);

function createBaseDeleteReleaseRequest(): DeleteReleaseRequest {
  return { $type: "oditoo.v1.DeleteReleaseRequest", id: "" };
}

export const DeleteReleaseRequest = {
  $type: "oditoo.v1.DeleteReleaseRequest" as const,

  encode(
    message: DeleteReleaseRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DeleteReleaseRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteReleaseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteReleaseRequest {
    return {
      $type: DeleteReleaseRequest.$type,
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: DeleteReleaseRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<DeleteReleaseRequest>): DeleteReleaseRequest {
    const message = createBaseDeleteReleaseRequest();
    message.id = object.id ?? "";
    return message;
  },
};

messageTypeRegistry.set(DeleteReleaseRequest.$type, DeleteReleaseRequest);

function createBaseDeleteReleaseResponse(): DeleteReleaseResponse {
  return { $type: "oditoo.v1.DeleteReleaseResponse" };
}

export const DeleteReleaseResponse = {
  $type: "oditoo.v1.DeleteReleaseResponse" as const,

  encode(
    _: DeleteReleaseResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DeleteReleaseResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteReleaseResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): DeleteReleaseResponse {
    return {
      $type: DeleteReleaseResponse.$type,
    };
  },

  toJSON(_: DeleteReleaseResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<DeleteReleaseResponse>): DeleteReleaseResponse {
    const message = createBaseDeleteReleaseResponse();
    return message;
  },
};

messageTypeRegistry.set(DeleteReleaseResponse.$type, DeleteReleaseResponse);

export type ReleasesDefinition = typeof ReleasesDefinition;
export const ReleasesDefinition = {
  name: "Releases",
  fullName: "oditoo.v1.Releases",
  methods: {
    createRelease: {
      name: "CreateRelease",
      requestType: CreateReleaseRequest,
      requestStream: false,
      responseType: CreateReleaseResponse,
      responseStream: false,
      options: {},
    },
    uploadRelease: {
      name: "UploadRelease",
      requestType: UploadReleaseRequest,
      requestStream: true,
      responseType: UploadReleaseResponse,
      responseStream: false,
      options: {},
    },
    downloadRelease: {
      name: "DownloadRelease",
      requestType: DownloadReleaseRequest,
      requestStream: false,
      responseType: DownloadReleaseResponse,
      responseStream: true,
      options: {},
    },
    readRelease: {
      name: "ReadRelease",
      requestType: ReadReleaseRequest,
      requestStream: false,
      responseType: ReadReleaseResponse,
      responseStream: false,
      options: {},
    },
    listReleases: {
      name: "ListReleases",
      requestType: ListReleasesRequest,
      requestStream: false,
      responseType: ListReleasesResponse,
      responseStream: false,
      options: {},
    },
    deleteRelease: {
      name: "DeleteRelease",
      requestType: DeleteReleaseRequest,
      requestStream: false,
      responseType: DeleteReleaseResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface ReleasesServiceImplementation<CallContextExt = {}> {
  createRelease(
    request: CreateReleaseRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<CreateReleaseResponse>>;
  uploadRelease(
    request: AsyncIterable<UploadReleaseRequest>,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<UploadReleaseResponse>>;
  downloadRelease(
    request: DownloadReleaseRequest,
    context: CallContext & CallContextExt
  ): ServerStreamingMethodResult<DeepPartial<DownloadReleaseResponse>>;
  readRelease(
    request: ReadReleaseRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<ReadReleaseResponse>>;
  listReleases(
    request: ListReleasesRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<ListReleasesResponse>>;
  deleteRelease(
    request: DeleteReleaseRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<DeleteReleaseResponse>>;
}

export interface ReleasesClient<CallOptionsExt = {}> {
  createRelease(
    request: DeepPartial<CreateReleaseRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<CreateReleaseResponse>;
  uploadRelease(
    request: AsyncIterable<DeepPartial<UploadReleaseRequest>>,
    options?: CallOptions & CallOptionsExt
  ): Promise<UploadReleaseResponse>;
  downloadRelease(
    request: DeepPartial<DownloadReleaseRequest>,
    options?: CallOptions & CallOptionsExt
  ): AsyncIterable<DownloadReleaseResponse>;
  readRelease(
    request: DeepPartial<ReadReleaseRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<ReadReleaseResponse>;
  listReleases(
    request: DeepPartial<ListReleasesRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<ListReleasesResponse>;
  deleteRelease(
    request: DeepPartial<DeleteReleaseRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<DeleteReleaseResponse>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

const atob: (b64: string) => string =
  globalThis.atob ||
  ((b64) => globalThis.Buffer.from(b64, "base64").toString("binary"));
function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

const btoa: (bin: string) => string =
  globalThis.btoa ||
  ((bin) => globalThis.Buffer.from(bin, "binary").toString("base64"));
function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach((byte) => {
    bin.push(String.fromCharCode(byte));
  });
  return btoa(bin.join(""));
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export type ServerStreamingMethodResult<Response> = {
  [Symbol.asyncIterator](): AsyncIterator<Response, void>;
};
