// Copyright 2022 Linka Cloud  All rights reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Theme, useMediaQuery } from '@mui/material'
import { DependencyList, EffectCallback, useCallback, useEffect, useRef, useState } from 'react'

export const useIsMobile = () => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  return isMobile
}

export const useSynchronizingState = <V,>(value: V) => {
  const ref = useRef<V>(value)
  const [state, setState] = useState<V>(value)

  useEffect(() => {
    if (value != ref.current) {
      console.log('updating value from', ref.current, 'to', value)
      ref.current = value
      setState(value)
    }
  }, [value])

  return [state, setState] as const
}

export const useReload = (): [number, () => Promise<void>] => {
  const [reloadTrigger, setNum] = useState(0)
  const reload = async () => {
    setNum((n) => n + 1)
  }
  return [reloadTrigger, reload]
}

export const useEffectOnce = (effect: EffectCallback) => {
  useEffect(() => {
    return effect()
  }, [])
}

export const usePromiseOnce = <T,>(p: () => Promise<T | void>) => {
  const state = usePromise(p, [])
  return state
}

export const usePromise = <T,>(p: () => Promise<T | void>, deps?: DependencyList) => {
  const [state, setState] = useState<T | void | null>(null)
  useEffect(() => {
    p().then((res) => res && setState(res))
  }, deps)
  return state
}

export const useQuery = <T,>(q: () => Promise<T | void | undefined> | undefined, deps?: DependencyList) => {
  const [state, setState] = useState<T | undefined | null>(null)
  useAsync(async () => {
    if (!q) {
      return
    }
    const res = await q()
    if (res) setState(res)
  }, deps)
  return state
}

export const useQueryOnce = <T,>(q: () => Promise<T | undefined> | undefined) => {
  const state = useQuery(q, [])
  return state
}

export const useAsync = (effect: () => Promise<void> | undefined, deps?: DependencyList) => {
  useEffect(() => {
    if (!effect) return
    // @ts-ignore
    effect().then()
  }, deps)
}

export const useAsyncOnce = (effect: () => Promise<void> | undefined) => {
  useAsync(effect, [])
}

export const useAsyncReturn = <T,>(effect: () => Promise<T | void>, deps?: DependencyList) => {
  const [state, setState] = useState<T | void | null>(null)
  useEffect(() => {
    if (!effect) return voidFn
    effect().then((res) => res && setState(res))
  }, deps)
  return [state, setState] as const
}

export const useAsyncReturnOnce = <T,>(effect: () => Promise<T | void>) => {
  const [state, setState] = useAsyncReturn(effect, [])
  return [state, setState] as const
}

export const useCallbackOnce = <T extends (...args: any[]) => any>(callback: T): T => {
  return useCallback(callback, [])
}

export const useChanged = <T,>(data: T, timeout?: number): [boolean, (b: boolean) => void] => {
  const ref = useRef<T>(data)
  const [changed, setChanged] = useState(false)
  const _timeout = timeout || 250 // 100
  useEffect(() => {
    if (JSON.stringify(data) === JSON.stringify(ref.current)) {
      return
    }
    console.log('data updated')
    ref.current = data
    setChanged(true)
    setTimeout(() => setChanged(false), _timeout)
  }, [data])
  return [changed, setChanged]
}

export const usePersistedState = <T,>(defaultValue: T, key: string): [T, (state: T) => void] => {
  const [state, _setState] = useState(defaultValue)
  useEffectOnce(() => {
    const s = localStorage.getItem(key)
    if (s) {
      setState(JSON.parse(s))
    }
  })
  const setState = (state: T) => {
    if (state === undefined) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, JSON.stringify(state))
    }
    _setState(state)
  }
  return [state, setState]
}

const voidFn = () => {}
