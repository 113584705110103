// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


import {ResponsiveButton} from "@ags-oditoo/components";
import {defaultPadding, defaultSpacing} from "@ags-oditoo/theme";
import {AddCircleOutlineOutlined, PeopleOutlined} from "@mui/icons-material";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {DeepPartial, User} from "../api/generated/api/v1/types";
import {Admin, OrgAdmin, OrgReader, Reader} from "../api/roles";
import {useAPI} from "../api/useAPI";
import {UserForm} from "../Components/Users/UserForm";
import {UsersTable} from "../Components/Users/UsersTable";
import {useAuthenticatedAsyncOnce, useError} from "../hooks";
import {MainRoutesRegistry} from "../routes";

export const UsersPage = () => {
  const {api} = useAPI()

  const [users, setUsers] = useState<User[]>()
  const [editing, setEditing] = useState<DeepPartial<User>>()
  const [toggling, setToggling] = useState<User>()
  const [deleting, setDeleting] = useState<User>()
  const [Error, setError, withError] = useError()

  const loadUsers = withError(async () => {
    const {users} = await api.users.listUsers({})
    setUsers(users)
  })
  useAuthenticatedAsyncOnce(loadUsers)
  const saveUser = withError(async (user: DeepPartial<User>) => {
    user.id ? await api.users.updateUser({user: {...user, executions: []}}) : await api.users.createUser(user)
    setEditing(undefined)
    await loadUsers()
  })

  const toggleUserDisabled = withError(async (user: DeepPartial<User>) => {
    user.disabled = !user.disabled
    await api.users.updateUser({user: {...user, executions: []}})
    setToggling(undefined)
    await loadUsers()
  })

  const deleteUser = withError(async (user: DeepPartial<User>) => {
    await api.users.deleteUser({id: user.id!})
    setDeleting(undefined)
    await loadUsers()
  })

  return (
    <Stack flex={1} spacing={defaultSpacing} sx={{padding: defaultPadding}}>
      <Error/>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h6'>Users</Typography>
        <ResponsiveButton
          variant='outlined'
          icon={<AddCircleOutlineOutlined/>}
          onClick={() => setEditing({} as DeepPartial<User>)}
          label="Add User"
        />
      </Stack>
      <Stack flex={1}>
        <UsersTable
          users={users}
          onEdit={setEditing}
          onToggleDisabled={setToggling}
          onDelete={setDeleting}
          link={user => MainRoutesRegistry.userDetails.navigate(user.name)}
          organisationLink={organisation => MainRoutesRegistry.organisationDetails.navigate(organisation)}
        />
      </Stack>
      <Dialog maxWidth='sm' fullWidth open={!!toggling} onClose={() => setToggling(undefined)}>
        <DialogTitle>{toggling?.disabled ? 'Enable user' : 'Disable user'}</DialogTitle>
        <DialogContent>
          {`Are you sure you want to ${toggling?.disabled ? 'enable' : 'disable'} the user "${toggling?.name}" ?`}
        </DialogContent>
        <DialogActions>
          <Stack direction='row'>
            <Button variant='outlined' onClick={() => setToggling(undefined)}>Cancel</Button>
            <Button variant='contained'
                    onClick={() => toggleUserDisabled(toggling!)}>{toggling?.disabled ? 'Enable' : 'Disable'}</Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth='sm' fullWidth open={!!editing} onClose={() => setEditing(undefined)}>
        <DialogTitle>{editing?.id ? 'Edit user' : 'Create user'}</DialogTitle>
        <DialogContent>
          <UserForm user={editing!} submitLabel={editing?.id ? "Update User" : "Create User"} onSubmit={saveUser}
                    onCancel={() => setEditing(undefined)}/>
        </DialogContent>
      </Dialog>
      <Dialog maxWidth='sm' fullWidth open={!!deleting} onClose={() => setDeleting(undefined)}>
        <DialogTitle>Delete user</DialogTitle>
        <DialogContent>
          {`Are you sure you want to delete user ${deleting?.name} ?`}
        </DialogContent>
        <DialogContent>This action cannot be undone.</DialogContent>
        <DialogActions>
          <Stack direction='row'>
            <Button variant='outlined' onClick={() => setDeleting(undefined)}>Cancel</Button>
            <Button variant='contained' onClick={() => deleteUser(deleting!)}>Delete</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

MainRoutesRegistry['users'] = {
  path: '/users',
  component: <UsersPage/>,
  icon: <PeopleOutlined/>,
  priority: 600,
  public: false,
  label: 'Users',
  show: true,
  navigate: () => '/users',
  roles: [Admin, Reader, OrgAdmin, OrgReader],
}
