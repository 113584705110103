// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


import {ResponsiveButton} from "@ags-oditoo/components";
import {defaultPadding, defaultSpacing} from "@ags-oditoo/theme";
import {AddCircleOutlineOutlined, GroupsOutlined} from "@mui/icons-material";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {DeepPartial, Organisation} from "../api/generated/api/v1/types";
import {Admin, Reader} from "../api/roles";
import {useAPI} from "../api/useAPI";
import {OrganisationForm} from "../Components/Organisations/OrganisationForm";
import {OrganisationsTable} from "../Components/Organisations/OrganisationsTable";
import {useAuthenticatedAsyncOnce, useError} from "../hooks";
import {MainRoutesRegistry} from "../routes";

export const OrganisationsPage = () => {
  const {api} = useAPI();
  const [organisations, setOrganisations] = useState<Organisation[]>();
  const [editing, setEditing] = useState<DeepPartial<Organisation>>();
  const [toggling, setToggling] = useState<Organisation>();
  const [deleting, setDeleting] = useState<Organisation>();
  const [Error, _, withError] = useError();

  const loadOrganisations = withError(async () => {
    const {organisations} = await api.organisations.listOrganisations({});
    setOrganisations(organisations);
  })
  useAuthenticatedAsyncOnce(loadOrganisations);
  const saveOrganisation = withError(async ({id, name, disabled}: DeepPartial<Organisation>) => {
    const organisation = {id, name, disabled};
    id ? await api.organisations.updateOrganisation({organisation}) : await api.organisations.createOrganisation({organisation})
    setEditing(undefined);
    await loadOrganisations();
  })

  const toggleOrganisationDisabled = withError(async ({id, name, disabled}: Organisation) => {
    await api.organisations.updateOrganisation({organisation: {id, name, disabled: !disabled}});
    setToggling(undefined);
    await loadOrganisations();
  })

  const deleteOrganisation = withError(async ({id}: Organisation) => {
    await api.organisations.deleteOrganisation({id});
    setDeleting(undefined);
    await loadOrganisations();
  })

  return (
    <Stack flex={1} spacing={defaultSpacing} sx={{padding: defaultPadding}}>
      <Error />
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h6'>Organisations</Typography>
        <ResponsiveButton
          variant='outlined'
          icon={<AddCircleOutlineOutlined/>}
          onClick={() => setEditing({} as DeepPartial<Organisation>)}
          label="Add Organisation"
        />
      </Stack>
      <Stack flex={1}>
        <OrganisationsTable
          organisations={organisations}
          onEdit={setEditing}
          onToggleDisabled={setToggling}
          onDelete={setDeleting}
          link={organisation => MainRoutesRegistry.organisationDetails.navigate(organisation.name)}
        />
      </Stack>
      <Dialog maxWidth='sm' fullWidth open={!!toggling} onClose={() => setToggling(undefined)}>
        <DialogTitle>{toggling?.disabled ? 'Enable organisation' : 'Disable organisation'}</DialogTitle>
        <DialogContent>
          {`Are you sure you want to ${toggling?.disabled ? 'enable' : 'disable'} the organisation "${toggling?.name}" ?`}
        </DialogContent>
        <DialogContent>
          {`This will ${toggling?.disabled ? 'enable' : 'disable'} all the ${toggling?.name}'s users ?`}
        </DialogContent>
        <DialogActions>
          <Stack direction='row'>
            <Button variant='outlined' onClick={() => setToggling(undefined)}>Cancel</Button>
            <Button variant='contained'
                    onClick={() => toggleOrganisationDisabled(toggling!)}>{toggling?.disabled ? 'Enable' : 'Disable'}</Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth='sm' fullWidth open={!!editing} onClose={() => setEditing(undefined)}>
        <DialogTitle>{editing?.id ? 'Edit organisation' : 'Create organisation'}</DialogTitle>
        <DialogContent>
          <OrganisationForm organisation={editing!}
                            submitLabel={editing?.id ? "Update Organisation" : "Create Organisation"}
                            onSubmit={saveOrganisation}
                            onCancel={() => setEditing(undefined)}/>
        </DialogContent>
      </Dialog>
      <Dialog maxWidth='sm' fullWidth open={!!deleting} onClose={() => setDeleting(undefined)}>
        <DialogTitle>Delete organisation</DialogTitle>
        <DialogContent>
          {`Are you sure you want to delete organisation ${deleting?.name} ?`}
        </DialogContent>
        <DialogContent>This will delete all the organisation's users.</DialogContent>
        <DialogContent>This action cannot be undone.</DialogContent>
        <DialogActions>
          <Stack direction='row'>
            <Button variant='outlined' onClick={() => setDeleting(undefined)}>Cancel</Button>
            <Button variant='contained' onClick={() => deleteOrganisation(deleting!)}>Delete</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

MainRoutesRegistry['organisations'] = {
  path: '/organisations',
  component: <OrganisationsPage/>,
  icon: <GroupsOutlined/>,
  priority: 500,
  public: false,
  label: 'Organisations',
  show: true,
  navigate: () => '/organisations',
  roles: [Admin, Reader],
}
