// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {MoreHoriz} from "@mui/icons-material";
import {BottomNavigation as MuiBottomNavigation, BottomNavigationAction} from "@mui/material";
import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Anonymous, userRole} from "../../api/roles";
import {useAPI} from "../../api/useAPI";
import {MainRoutesRegistry} from "../../routes";
import {Menu} from "./Menu";
import {SwipeableEdgeDrawer} from "./SwipeableEdgeDrawer";

export const BottomNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {user} = useAPI();
  const role = userRole(user);

  const [more, setMore] = useState(false);
  const routes = Object.values(MainRoutesRegistry).filter(r => !!r.roles.find(r => r === role))
    .sort((a, b) => (b.priority || 0) - (a.priority || 0))
    .filter((v) => v.show);
  const topRoutes = routes.filter((r) => !r.bottomEnd);
  const bottomRoutes = routes.filter((r) => r.bottomEnd);
  const routesByPath = [...routes].sort((a, b) => b.path.length - a.path.length);
  const hasMore = topRoutes.length > 5 || bottomRoutes.length > 0;
  const value = routesByPath.find((v) => location.pathname.startsWith(v.path))?.label;
  const handleChange = (_: React.SyntheticEvent, value: string) => {
    const to = routes.find((v) => v.label === value)?.navigate();
    if (to) navigate(to);
  };
  return (
    <>
      <MuiBottomNavigation value={value} onChange={handleChange}>
        {(hasMore ? topRoutes.slice(0, 4) : topRoutes).map((v) => (
          <BottomNavigationAction key={v.label} sx={{ minWidth: 0 }} label={v.label} value={v.label} icon={v.icon} />
        ))}
        {hasMore && <BottomNavigationAction key="More" label="More" value="More" icon={<MoreHoriz />}
                                            onClick={() => setMore(true)} />}
      </MuiBottomNavigation>
      {more && (
        <SwipeableEdgeDrawer onClose={() => setMore(false)}>
          <Menu open={true} routes={[...topRoutes.slice(4), ...bottomRoutes]} />
        </SwipeableEdgeDrawer>
      )}
    </>
  );
};
