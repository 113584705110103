// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


import {ClearOutlined, EditOutlined, ToggleOff, ToggleOn} from "@mui/icons-material";
import {Link} from "@mui/material";
import {DataGrid, GridActionsCellItem, GridColDef} from "@mui/x-data-grid";
import React, {useMemo} from "react";
import {Organisation, Role, User} from "../../api/generated/api/v1/types";
import {useAPI} from "../../api/useAPI";
import {Table} from "../Table";
import {BaseTableProps} from "../Table/BaseTableProps";

export interface OrganisationsTableProps extends BaseTableProps<Organisation>{
  organisations?: Organisation[];
}

export const OrganisationsTable = ({organisations, link, onEdit, onToggleDisabled, onDelete}: OrganisationsTableProps) => {
  const {user: currentUser} = useAPI()
  const canEdit = currentUser?.role === Role.ADMIN || currentUser?.role === Role.ORG_ADMIN
  const columns = useMemo<GridColDef<Organisation>[]>(() => {
    const actions: ((org: Organisation) => JSX.Element)[] = [];
    if (canEdit && onToggleDisabled) {
      actions.push(
        (org) => (
          <GridActionsCellItem
            icon={org.disabled ? <ToggleOff color={org.id === currentUser?.organisationId ? 'disabled' : 'error'}/> : <ToggleOn color={org.id === currentUser?.organisationId ? 'disabled': 'success'}/>}
            disabled={org.id === currentUser?.id}
            label={org.disabled ? "Enable" : "Disable"}
            onClick={() => onToggleDisabled(org)}
          />
        )
      )
    }
    if (canEdit && onEdit) {
      actions.push(
        (org) => (
          <GridActionsCellItem
            icon={<EditOutlined/>}
            label={"Edit"}
            onClick={() => onEdit(org)}
          />
        )
      )
    }
    if (canEdit && onDelete) {
      actions.push(
        (org) => (
          <GridActionsCellItem
            icon={<ClearOutlined/>}
            label={"Delete"}
            disabled={org.id === currentUser?.organisationId}
            onClick={() => onDelete(org)}
          />
        )
      )
    }
    return ([
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: ({row}) => link ? <Link href={link(row)}>{row.name}</Link> : row.name,
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        type: 'dateTime',
        flex: 1,
      },
      // {
      //   field: 'updatedAt',
      //   headerName: 'Updated At',
      //   minWidth: 250,
      //   type: 'dateTime',
      // },
      {
        field: 'users',
        headerName: 'Users',
        valueGetter: ({row}) => row.users.length,
      },
      {
        field: 'executions',
        headerName: 'Executions',
        valueGetter: ({row}) => row.executions.length,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 150,
        getActions: ({row}) => [...actions.map(action => action(row))],
      }
    ])
  }, []);
  return (
    <Table
      columns={columns}
      rows={organisations ?? []}
      loading={!organisations}
    />
  )
}

