// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {mixed, object, ObjectSchema, ref, string} from "yup";
import {DeepPartial, Role, User} from "../generated/api/v1/types";
import {CreateUserRequest} from "../generated/api/v1/users.service";

// @ts-ignore
export const userSchema: ObjectSchema<DeepPartial<CreateUserRequest>> = object({
  name: string().required('Required').matches(/^[a-zA-Z0-9-., ]+$/, 'Name must be alphanumeric'),
  email: string().email().required('Required'),
  organization: string().nullable(),
  role: mixed().oneOf([Role.ADMIN, Role.READER, Role.ORG_ADMIN, Role.ORG_READER, Role.USER])
})
