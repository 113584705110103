// Copyright 2022 Linka Cloud  All rights reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {
  alpha,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import {Anonymous, userRole} from "../../api/roles";
import { useAPI } from "../../api/useAPI";
import { MainRoutesRegistry, RouteDefinition } from "../../routes";
import { Tooltip } from "../Tooltip";

export interface MenuProps {
  open?: boolean;
  routes?: RouteDefinition[];
}

export const Menu = ({ open = true, routes: _routes }: MenuProps) => {
  const theme = useTheme();
  const location = useLocation();
  const {user} = useAPI();
  const role = userRole(user);
  const routes =
    _routes ||
    Object.values(MainRoutesRegistry)
      .filter(r => !!r.roles.find(r => r === role))
      .sort((a, b) => (b.priority || 0) - (a.priority || 0))
      .filter((v) => v.show);
  const topRoutes = routes.filter((r) => !r.bottomEnd);
  const bottomRoutes = routes.filter((r) => r.bottomEnd);
  const routesByPath = [...routes].sort((a, b) => b.path.length - a.path.length);

  const Routes = ({ routes }: { routes: RouteDefinition[] }) => (
    <>
      {routes.map((v) => {
        const isCurrent = routesByPath.find((v) => location.pathname.startsWith(v.path))?.path === v.path;
        return (
          <Tooltip key={v.label} title={v.label|| ''} disabled={open} placement='right-end'>
            <ListItem
              disablePadding
              sx={{
                display: "block",
                "& > a:hover": {
                  backgroundColor: "inherit",
                  "& > div": {
                    color: theme.palette.primary.light
                  }
                },
                ".MuiListItemIcon-root": {
                  padding: theme.spacing(1),
                  borderRadius: open ? 0 : theme.spacing(1)
                },
                "&.Mui-selected": {
                  backgroundColor: "inherit",
                  border: "none",
                  color: theme.palette.primary.main,
                  ".MuiListItemIcon-root": {
                    // margin: "-2px",
                    backgroundColor: open ? "inherit" : alpha(theme.palette.primary.light, 0.2)
                  }
                }
              }}
              selected={isCurrent}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  //justifyContent: open ? 'initial' : 'center',
                  justifyContent: "initial",
                  px: 2.5,
                  padding: theme.spacing(0, 1.5)
                }}
                href={v.path}
                component={Link}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: isCurrent ? "primary.main" : "default"
                  }}
                >
                  {v.icon}
                </ListItemIcon>
                <ListItemText primary={v.label} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        );
      })}
    </>
  );

  return (
    <List sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <Routes routes={topRoutes} />
      <ListItem sx={{ flex: 1 }} />
      <Routes routes={bottomRoutes} />
    </List>
  );
};
