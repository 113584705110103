// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {defaultPadding, defaultSpacing} from "@ags-oditoo/theme";
import {CloudDownloadOutlined, HomeOutlined} from "@mui/icons-material";
import {Button, Card, CardActions, CardHeader, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {DOWNLOAD_RELEASE_LINK} from "../api/api";
import {Release} from "../api/generated/api/v1/types";
import {Admin, Anonymous, Authenticated, OrgAdmin, OrgReader, Reader, User} from "../api/roles";
import {useAPI} from "../api/useAPI";
import {Loading} from "../Components/Loading";
import {useAuthenticatedAsyncOnce, useError} from "../hooks";
import {MainRoutesRegistry} from "../routes";
import {TokenPage} from "./TokenPage";

export const HomePage = () => {
  const {api} = useAPI();
  const [loading, setLoading] = useState(true);
  const [release, setRelease] = useState<Release | undefined>();
  const [Error, _, withError] = useError();
  useAuthenticatedAsyncOnce(withError(async () => {
    setLoading(true)
    const {releases} = await api.releases.listReleases({});
    const release = releases.sort((a, b) => (a.createdAt?.getTime() ?? 0) > (b.createdAt?.getTime() ?? 0) ? -1 : 1)[0];
    setRelease(release)
    setLoading(false)
  }))
  if (loading) return <Loading/>
  return (
    release
      ? (
        <>
          <Stack spacing={defaultSpacing} sx={{padding: defaultPadding}}>
            <Error/>
            <Stack direction='row' justifyContent='space-between'>
              <Typography variant='h6'>Latest Release</Typography>
            </Stack>
            <Stack>
              <Stack direction='row'>
                <Card>
                  <CardHeader
                    title={release.version}
                    subheader={release.buildDate?.toLocaleDateString()}
                  />
                  <CardActions sx={{justifyContent: 'flex-end'}}>
                    <Button
                      component='a'
                      href={DOWNLOAD_RELEASE_LINK + release.id}
                      variant='text'
                      startIcon={<CloudDownloadOutlined/>}
                    >
                      Download
                    </Button>
                  </CardActions>
                </Card>
              </Stack>
            </Stack>
          </Stack>
          <TokenPage/>
        </>
      )
      : (
        <Stack spacing={defaultSpacing} sx={{padding: defaultPadding}}>
          <Error/>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h6'>Ooops</Typography>
          </Stack>
          <Stack>
            <Typography variant='body1'>No release available yet. Please check back later.</Typography>
          </Stack>
        </Stack>
      )

  )
}

MainRoutesRegistry['home'] = {
  path: '/',
  component: <HomePage/>,
  icon: <HomeOutlined/>,
  priority: 10000,
  public: false,
  label: 'Home',
  show: true,
  navigate: () => '/',
  roles: [Admin, Reader, OrgAdmin, OrgReader, User, Authenticated, Anonymous],
}
