// Copyright 2022 Linka Cloud  All rights reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {useAsyncOnce, useEffectOnce} from "@ags-oditoo/hooks";
import {Alert} from "@mui/material";
import { CallOptions } from 'nice-grpc-common'
import React, { DependencyList, useEffect, useRef, useState } from 'react'
import { watch } from './api/api'
import {useAPI} from "./api/useAPI";


export const useAuthenticatedAsync = (effect: () => Promise<void> | undefined, deps?: DependencyList) => {
  const {authenticated } = useAPI();
  useEffect(() => {
    if (!authenticated) return
    if (!effect) return
    // @ts-ignore
    effect().then()
  }, [authenticated, ...(deps ?? [])])
}

export const useAuthenticatedAsyncOnce = (effect: () => Promise<void> | undefined) => {
  useAuthenticatedAsync(effect, [])
}

export const useAsyncIterableOnce = <T,>(fn: (opts: CallOptions) => AsyncIterable<T>, key?: keyof T) => {
  const abortRef = useRef(new AbortController())
  useEffectOnce(() => () => abortRef.current.abort())
  const [response, setResponse] = useState<T | any>()
  useAsyncOnce(async () => {
    await watch(fn, (res) => setResponse(key ? res[key] : res), abortRef.current.signal)
    console.log('stopped watching')
  })
  return response
}

export const useError = (deps : DependencyList = []): [React.FC, (e: string) => void, (fn: (params?: any) => Promise<void>) => (params?: any) => Promise<void>] => {
  const [error, setError] = useState<string>()
  useEffect(() => setError(undefined), deps)
  useEffect(() => {
    if (!error) return
    setTimeout(() => setError(undefined), 10000)
  })
  const alert = () => (
    error ? <Alert severity="error" variant="outlined" onClose={() => setError(undefined)}>{error}</Alert> : null
  )
  const withError = (fn: (params?: any) => Promise<void>) => async (params?: any) => {
    try {
      await fn(params)
    } catch (e: any) {
      console.log(e)
      setError(e.message)
    }
  }
  return [alert, setError, withError]
}
