// Copyright 2022 Linka Cloud  All rights reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Stack, ListItem as MuiListItem, alpha, useMediaQuery, Paper } from '@mui/material'
import { useEffect } from 'react'
import * as React from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Menu } from './Menu'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  justifyContent: 'space-between',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  justifyContent: 'space-between',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  borderTop: 'solid 1px',
  borderTopColor: alpha(theme.palette.text.disabled, 0.2),
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  //'& .MuiDrawer-paper': {
  // flexDirection: 'column',
  // justifyContent: 'space-between'
  //}
}))

const ListItem = styled(MuiListItem)(({ theme }) => ({
  '& > div:hover': {
    backgroundColor: 'inherit',
    '& > div': {
      color: theme.palette.primary.light,
    },
  },
  '.MuiListItemIcon-root': {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  '&.Mui-selected': {
    backgroundColor: 'inherit',
    border: 'none',
    color: theme.palette.primary.main,
    '.MuiListItemIcon-root': {
      // margin: "-2px",
      backgroundColor: alpha(theme.palette.primary.light, 0.2),
    },
  },
}))

export const DrawerLayout = ({ children }: React.PropsWithChildren<any>) => {
  const upLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))
  const [open, setOpen] = React.useState(upLg)

  useEffect(() => {
    setOpen((open) => {
      if (upLg && open) {
        return true
      }
      return upLg
    })
  }, [upLg])

  const toggleDrawerOpen = () => {
    setOpen(!open)
  }

  return (
    <Box sx={{ display: 'flex', flex: 1, minHeight: '100%' }}>
        <Drawer variant='permanent' open={open}>
          <Stack flex={1}>
            <DrawerHeader sx={{ border: 'none' }} />
            <Menu open={open} />
          </Stack>
          <DrawerHeader>
            <IconButton onClick={toggleDrawerOpen}>{!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
          </DrawerHeader>
        </Drawer>
      <Stack component='main' id='main' sx={{ flexGrow: 1, minHeight: '100%', p: 3 }}>
        {/*<DrawerHeader/>*/}
        {children}
      </Stack>
    </Box>
  )
}
