// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {Footer} from "@ags-oditoo/components";
import React from "react";
import {Box, Paper, Stack} from "@mui/material";
import {useLocation} from "react-router-dom";
import {useAPI} from "../../api/useAPI";
import { BottomNavigation } from "./BottomNavigation";
import {Header} from "./Header";
import { HideOnScrollAndShowAtBottom } from "./HideOnScrollAndShowAtBottom";
import {UnauthenticatedLayout} from "./UnauthenticatedLayout";

export const MobileLayout = ({ children }: React.PropsWithChildren<any>) => {
  const { authenticated } = useAPI();
  const { pathname } = useLocation();
  const show = authenticated && pathname !== "/logout";

  return show ? (
    <>
      <Stack component="main" id="main" minWidth='100%'>
        <Stack minHeight='calc(100% + 46px)' marginBottom='54px'>
          <Header padding={{ xs: 2, sm: 2 }} paddingBottom={0} />
          <Stack flex={1}>
            {children}
          </Stack>
          <Footer />
        </Stack>
      </Stack>
      <HideOnScrollAndShowAtBottom>
        <Paper
          component="nav"
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            overflow: "hidden",
            backgroundColor: (theme) => theme.palette.background.default,
          }}
          elevation={0}
        >
          <BottomNavigation />
        </Paper>
      </HideOnScrollAndShowAtBottom>
    </>
  ) : (
    <UnauthenticatedLayout>{children}</UnauthenticatedLayout>
  );
};
