// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


import {ChevronRight, ClearOutlined, EditOutlined, ToggleOff, ToggleOn} from "@mui/icons-material";
import {Avatar, Link} from "@mui/material";
import {GridActionsCellItem, GridColDef} from "@mui/x-data-grid";
import md5 from "md5";
import React, {useMemo} from "react";
import {Organisation, Role, User} from "../../api/generated/api/v1/types";
import {RoleName} from "../../api/roles";
import {useAPI} from "../../api/useAPI";
import {Table} from "../Table";
import {BaseTableProps} from "../Table/BaseTableProps";

export interface UsersTableProps extends BaseTableProps<User> {
  users?: User[];
  hideOrganisation?: boolean;
  organisationLink?: (name: string) => string;
}

export const UsersTable = ({users, link, onEdit, onToggleDisabled, onDelete, hideOrganisation, organisationLink}: UsersTableProps) => {
  const {user: currentUser} = useAPI()
  const canEdit = currentUser?.role === Role.ADMIN || currentUser?.role === Role.ORG_ADMIN
  const columns: GridColDef<User>[] = useMemo<GridColDef<User>[]>(() => {
    const actions: ((user: User) => JSX.Element)[] = [];
    if (canEdit && onToggleDisabled) {
      actions.push(
        (user) => (
          <GridActionsCellItem
            icon={
              (user.disabled || user.organisation?.disabled)
                ?
                <ToggleOff
                  color={(user.id === currentUser?.id || user.organisation?.disabled) ? 'disabled' : 'error'}
                />
                : <ToggleOn
                  color={(user.id === currentUser?.id || user.organisation?.disabled) ? 'disabled' : 'success'}
                />
            }
            disabled={user.id === currentUser?.id || user.organisation?.disabled}
            label={user.disabled ? "Enable" : "Disable"}
            onClick={() => onToggleDisabled(user)}
          />
        )
      )
    }
    if (canEdit && onEdit) {
      actions.push(
        (user) => (
          <GridActionsCellItem
            icon={<EditOutlined/>}
            label={"Edit"}
            onClick={() => onEdit(user)}
          />
        )
      )
    }
    if (canEdit && onDelete) {
      actions.push(
        (user) => (
          <GridActionsCellItem
            icon={<ClearOutlined/>}
            label={"Delete"}
            disabled={user.id === currentUser?.id}
          />
        )
      )
    }
    return [
      {
        field: 'avatar',
        headerName: '',
        type: 'avatar',
        sortable: false,
        renderCell: ({row}) => <Avatar component={link ? Link : 'div'} href={link ? link(row) : undefined} sx={{width: 35, height: 35}}
                                       src={`https://www.gravatar.com/avatar/${md5(row.email || row.name + '@unknown.org')}?d=retro`}/>,
        minWidth: 50,
        width: 50,
      },
      {
        field: 'name',
        headerName: 'Username',
        // minWidth: 200,
        valueGetter: ({row}) => row.name,
        renderCell: ({row}) => link ? <Link href={link(row)}>{row.name}</Link> : row.name,
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'Email',
        // minWidth: 200,
        flex: 1,
        renderCell: ({row}) => <Link href={`mailto:${row.email}`}>{row.email}</Link>,
      },
      {
        field: 'emailVerified',
        headerName: 'Verified',
        type: 'boolean',
        // minWidth: 150,
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        type: 'dateTime',
        // minWidth: 200,
        flex: 1,
      },
      // {
      //   field: 'updatedAt',
      //   headerName: 'Updated At',
      //   type: 'dateTime',
      //   // minWidth: 200,
      // },
      {
        field: 'organisation',
        headerName: 'Organisation',
        flex: 1,
        hidden: hideOrganisation,
        renderCell: ({row}) => organisationLink ? <Link href={organisationLink(row.organisation?.name ?? '')}>{row.organisation?.name ?? 'Unknown'}</Link> : row.organisation?.name ?? 'Unknown',
      },
      {
        field: 'role',
        headerName: 'Role',
        valueGetter: ({row}) => RoleName(row.role) ?? 'Unknown',
        flex: 1,
      },
      {
        field: 'executions',
        headerName: 'Executions',
        valueGetter: ({row}) => row.executions.length,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 150,
        getActions: ({row}) => [
          ...actions.map((action) => action(row)),
        ]
      }
    ]
  }, []);
  return (
    <Table
      columns={columns}
      rows={users ?? []}
      loading={!users}
    />
  )
}
