// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {DarkModeOutlined, LightModeOutlined} from "@mui/icons-material";
import {ButtonGroup, Container, IconButton, Stack, Tooltip} from "@mui/material";
import React from "react";
import {useIsMobile} from "@ags-oditoo/hooks";
import {useUiMode} from "@ags-oditoo/theme";
import {Footer} from "@ags-oditoo/components";
import backgroundDark from '../../images/neronal-dark.jpg';
import backgroundLight from '../../images/neuronal.jpg';

export const UnauthenticatedLayout = ({children}: React.PropsWithChildren<any>) => {
  const [mode, setMode] = useUiMode();
  const isMobile = useIsMobile();
  return (
    <Stack
      component='main'
      id="nain"
      minWidth='100%'
      minHeight="100%"
      direction={isMobile ? 'column' : 'row'}
      sx={{
        flexGrow: 1,
        background: theme => isMobile
          ? mode == 'light' ? `url(${backgroundLight})` : `url(${backgroundDark})`
          : mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
        backgroundSize: 'cover',
        // backgroundSize: {xs: '300% auto', sm: '100% auto'},
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'top',
        // filter: 'blur(8px)'
      }}
    >
      <Stack flex={1} sx={{background: 'none', backdropFilter: isMobile ? 'blur(4px)' : 'none'}} padding={(theme) => theme.spacing(2)}>
        <Container sx={{display: "flex", flexDirection: "column", flex: 1}}>
          <Stack direction="row" justifyContent="flex-end">
            <ButtonGroup>
              <Tooltip title={`Switch to ${mode === "light" ? "Dark" : "Light"} mode`}>
                <IconButton onClick={() => setMode(mode === "light" ? "dark" : "light")}>
                  {mode === "light" ? <DarkModeOutlined/> : <LightModeOutlined/>}
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          </Stack>
          <Stack flex={1}>{children}</Stack>
          <Footer/>
        </Container>
      </Stack>
      {
        !isMobile && (
          <Stack
            flex={1}
            sx={{
              flexGrow: 1,
              backgroundImage: mode == 'light' ? `url(${backgroundLight})` : `url(${backgroundDark})`,
              backgroundSize: 'cover',
              // backgroundSize: {xs: '300% auto', sm: '100% auto'},
              // backgroundRepeat: 'no-repeat',
              // backgroundPosition: 'top',
              // filter: 'blur(8px)'
            }}
          />
        )
      }
    </Stack>
  );
};
