// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {styled, Tab as MuiTab, TabProps, Tabs as MuiTabs, TabsProps} from "@mui/material";
import React from "react";

export const Tabs = styled((props: TabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
  />
))(({theme}) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const Tab = styled((props: TabProps) => (
  <MuiTab disableRipple {...props} />
))(({theme}) => ({
  textTransform: 'none',
  margin: 0,
  padding: 0,
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
}));
