/* eslint-disable */
import { messageTypeRegistry } from "../../typeRegistry";
import { CallContext, CallOptions } from "nice-grpc-common";
import { Execution } from "./types";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "oditoo.v1";

export interface RequestExecutionRequest {
  $type: "oditoo.v1.RequestExecutionRequest";
  /** Execution is the execution to create. */
  requestToken: string;
}

export interface RequestExecutionResponse {
  $type: "oditoo.v1.RequestExecutionResponse";
  /** Execution is the created execution. */
  executionToken: string;
}

export interface ListExecutionsRequest {
  $type: "oditoo.v1.ListExecutionsRequest";
  /**
   * Limit is the maximum number of users to return.
   * The server may return fewer than this at its discretion.
   * If Limit is negative or zero, the server will decide the number of users to return.
   */
  limit: number;
  /**
   * Offset is the number of users to skip before starting to collect the result set.
   * If Offset is negative, the server will decide the number of users to skip.
   */
  offset: number;
  /** OrganisationID is the optional organisation ID to filter on. */
  organisationId?: string | undefined;
  /** UserID is the optional user ID to filter on. */
  userId?: string | undefined;
}

export interface ListExecutionsResponse {
  $type: "oditoo.v1.ListExecutionsResponse";
  /** Execution is the list of executions. */
  executions: Execution[];
}

export interface SignConfigRequest {
  $type: "oditoo.v1.SignConfigRequest";
  config: Uint8Array;
}

export interface SignConfigResponse {
  $type: "oditoo.v1.SignConfigResponse";
  signedConfig?: SignedConfig;
}

export interface PublicKeyRequest {
  $type: "oditoo.v1.PublicKeyRequest";
}

export interface PublicKeyResponse {
  $type: "oditoo.v1.PublicKeyResponse";
  publicKey: string;
}

export interface SignedConfig {
  $type: "oditoo.v1.SignedConfig";
  data: string;
}

function createBaseRequestExecutionRequest(): RequestExecutionRequest {
  return { $type: "oditoo.v1.RequestExecutionRequest", requestToken: "" };
}

export const RequestExecutionRequest = {
  $type: "oditoo.v1.RequestExecutionRequest" as const,

  encode(
    message: RequestExecutionRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.requestToken !== "") {
      writer.uint32(10).string(message.requestToken);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RequestExecutionRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestExecutionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestToken = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RequestExecutionRequest {
    return {
      $type: RequestExecutionRequest.$type,
      requestToken: isSet(object.requestToken)
        ? String(object.requestToken)
        : "",
    };
  },

  toJSON(message: RequestExecutionRequest): unknown {
    const obj: any = {};
    message.requestToken !== undefined &&
      (obj.requestToken = message.requestToken);
    return obj;
  },

  fromPartial(
    object: DeepPartial<RequestExecutionRequest>
  ): RequestExecutionRequest {
    const message = createBaseRequestExecutionRequest();
    message.requestToken = object.requestToken ?? "";
    return message;
  },
};

messageTypeRegistry.set(RequestExecutionRequest.$type, RequestExecutionRequest);

function createBaseRequestExecutionResponse(): RequestExecutionResponse {
  return { $type: "oditoo.v1.RequestExecutionResponse", executionToken: "" };
}

export const RequestExecutionResponse = {
  $type: "oditoo.v1.RequestExecutionResponse" as const,

  encode(
    message: RequestExecutionResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.executionToken !== "") {
      writer.uint32(10).string(message.executionToken);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RequestExecutionResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestExecutionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.executionToken = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RequestExecutionResponse {
    return {
      $type: RequestExecutionResponse.$type,
      executionToken: isSet(object.executionToken)
        ? String(object.executionToken)
        : "",
    };
  },

  toJSON(message: RequestExecutionResponse): unknown {
    const obj: any = {};
    message.executionToken !== undefined &&
      (obj.executionToken = message.executionToken);
    return obj;
  },

  fromPartial(
    object: DeepPartial<RequestExecutionResponse>
  ): RequestExecutionResponse {
    const message = createBaseRequestExecutionResponse();
    message.executionToken = object.executionToken ?? "";
    return message;
  },
};

messageTypeRegistry.set(
  RequestExecutionResponse.$type,
  RequestExecutionResponse
);

function createBaseListExecutionsRequest(): ListExecutionsRequest {
  return {
    $type: "oditoo.v1.ListExecutionsRequest",
    limit: 0,
    offset: 0,
    organisationId: undefined,
    userId: undefined,
  };
}

export const ListExecutionsRequest = {
  $type: "oditoo.v1.ListExecutionsRequest" as const,

  encode(
    message: ListExecutionsRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.limit !== 0) {
      writer.uint32(8).int32(message.limit);
    }
    if (message.offset !== 0) {
      writer.uint32(16).int32(message.offset);
    }
    if (message.organisationId !== undefined) {
      writer.uint32(26).string(message.organisationId);
    }
    if (message.userId !== undefined) {
      writer.uint32(34).string(message.userId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListExecutionsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListExecutionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = reader.int32();
          break;
        case 2:
          message.offset = reader.int32();
          break;
        case 3:
          message.organisationId = reader.string();
          break;
        case 4:
          message.userId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ListExecutionsRequest {
    return {
      $type: ListExecutionsRequest.$type,
      limit: isSet(object.limit) ? Number(object.limit) : 0,
      offset: isSet(object.offset) ? Number(object.offset) : 0,
      organisationId: isSet(object.organisationId)
        ? String(object.organisationId)
        : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
    };
  },

  toJSON(message: ListExecutionsRequest): unknown {
    const obj: any = {};
    message.limit !== undefined && (obj.limit = Math.round(message.limit));
    message.offset !== undefined && (obj.offset = Math.round(message.offset));
    message.organisationId !== undefined &&
      (obj.organisationId = message.organisationId);
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ListExecutionsRequest>
  ): ListExecutionsRequest {
    const message = createBaseListExecutionsRequest();
    message.limit = object.limit ?? 0;
    message.offset = object.offset ?? 0;
    message.organisationId = object.organisationId ?? undefined;
    message.userId = object.userId ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(ListExecutionsRequest.$type, ListExecutionsRequest);

function createBaseListExecutionsResponse(): ListExecutionsResponse {
  return { $type: "oditoo.v1.ListExecutionsResponse", executions: [] };
}

export const ListExecutionsResponse = {
  $type: "oditoo.v1.ListExecutionsResponse" as const,

  encode(
    message: ListExecutionsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.executions) {
      Execution.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListExecutionsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListExecutionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.executions.push(Execution.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ListExecutionsResponse {
    return {
      $type: ListExecutionsResponse.$type,
      executions: Array.isArray(object?.executions)
        ? object.executions.map((e: any) => Execution.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListExecutionsResponse): unknown {
    const obj: any = {};
    if (message.executions) {
      obj.executions = message.executions.map((e) =>
        e ? Execution.toJSON(e) : undefined
      );
    } else {
      obj.executions = [];
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<ListExecutionsResponse>
  ): ListExecutionsResponse {
    const message = createBaseListExecutionsResponse();
    message.executions =
      object.executions?.map((e) => Execution.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ListExecutionsResponse.$type, ListExecutionsResponse);

function createBaseSignConfigRequest(): SignConfigRequest {
  return { $type: "oditoo.v1.SignConfigRequest", config: new Uint8Array() };
}

export const SignConfigRequest = {
  $type: "oditoo.v1.SignConfigRequest" as const,

  encode(
    message: SignConfigRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.config.length !== 0) {
      writer.uint32(10).bytes(message.config);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignConfigRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.config = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignConfigRequest {
    return {
      $type: SignConfigRequest.$type,
      config: isSet(object.config)
        ? bytesFromBase64(object.config)
        : new Uint8Array(),
    };
  },

  toJSON(message: SignConfigRequest): unknown {
    const obj: any = {};
    message.config !== undefined &&
      (obj.config = base64FromBytes(
        message.config !== undefined ? message.config : new Uint8Array()
      ));
    return obj;
  },

  fromPartial(object: DeepPartial<SignConfigRequest>): SignConfigRequest {
    const message = createBaseSignConfigRequest();
    message.config = object.config ?? new Uint8Array();
    return message;
  },
};

messageTypeRegistry.set(SignConfigRequest.$type, SignConfigRequest);

function createBaseSignConfigResponse(): SignConfigResponse {
  return { $type: "oditoo.v1.SignConfigResponse", signedConfig: undefined };
}

export const SignConfigResponse = {
  $type: "oditoo.v1.SignConfigResponse" as const,

  encode(
    message: SignConfigResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.signedConfig !== undefined) {
      SignedConfig.encode(
        message.signedConfig,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignConfigResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.signedConfig = SignedConfig.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignConfigResponse {
    return {
      $type: SignConfigResponse.$type,
      signedConfig: isSet(object.signedConfig)
        ? SignedConfig.fromJSON(object.signedConfig)
        : undefined,
    };
  },

  toJSON(message: SignConfigResponse): unknown {
    const obj: any = {};
    message.signedConfig !== undefined &&
      (obj.signedConfig = message.signedConfig
        ? SignedConfig.toJSON(message.signedConfig)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<SignConfigResponse>): SignConfigResponse {
    const message = createBaseSignConfigResponse();
    message.signedConfig =
      object.signedConfig !== undefined && object.signedConfig !== null
        ? SignedConfig.fromPartial(object.signedConfig)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(SignConfigResponse.$type, SignConfigResponse);

function createBasePublicKeyRequest(): PublicKeyRequest {
  return { $type: "oditoo.v1.PublicKeyRequest" };
}

export const PublicKeyRequest = {
  $type: "oditoo.v1.PublicKeyRequest" as const,

  encode(
    _: PublicKeyRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PublicKeyRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublicKeyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): PublicKeyRequest {
    return {
      $type: PublicKeyRequest.$type,
    };
  },

  toJSON(_: PublicKeyRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<PublicKeyRequest>): PublicKeyRequest {
    const message = createBasePublicKeyRequest();
    return message;
  },
};

messageTypeRegistry.set(PublicKeyRequest.$type, PublicKeyRequest);

function createBasePublicKeyResponse(): PublicKeyResponse {
  return { $type: "oditoo.v1.PublicKeyResponse", publicKey: "" };
}

export const PublicKeyResponse = {
  $type: "oditoo.v1.PublicKeyResponse" as const,

  encode(
    message: PublicKeyResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.publicKey !== "") {
      writer.uint32(10).string(message.publicKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PublicKeyResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublicKeyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.publicKey = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PublicKeyResponse {
    return {
      $type: PublicKeyResponse.$type,
      publicKey: isSet(object.publicKey) ? String(object.publicKey) : "",
    };
  },

  toJSON(message: PublicKeyResponse): unknown {
    const obj: any = {};
    message.publicKey !== undefined && (obj.publicKey = message.publicKey);
    return obj;
  },

  fromPartial(object: DeepPartial<PublicKeyResponse>): PublicKeyResponse {
    const message = createBasePublicKeyResponse();
    message.publicKey = object.publicKey ?? "";
    return message;
  },
};

messageTypeRegistry.set(PublicKeyResponse.$type, PublicKeyResponse);

function createBaseSignedConfig(): SignedConfig {
  return { $type: "oditoo.v1.SignedConfig", data: "" };
}

export const SignedConfig = {
  $type: "oditoo.v1.SignedConfig" as const,

  encode(
    message: SignedConfig,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.data !== "") {
      writer.uint32(10).string(message.data);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignedConfig {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignedConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.data = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignedConfig {
    return {
      $type: SignedConfig.$type,
      data: isSet(object.data) ? String(object.data) : "",
    };
  },

  toJSON(message: SignedConfig): unknown {
    const obj: any = {};
    message.data !== undefined && (obj.data = message.data);
    return obj;
  },

  fromPartial(object: DeepPartial<SignedConfig>): SignedConfig {
    const message = createBaseSignedConfig();
    message.data = object.data ?? "";
    return message;
  },
};

messageTypeRegistry.set(SignedConfig.$type, SignedConfig);

export type ExecutionsDefinition = typeof ExecutionsDefinition;
export const ExecutionsDefinition = {
  name: "Executions",
  fullName: "oditoo.v1.Executions",
  methods: {
    requestExecution: {
      name: "RequestExecution",
      requestType: RequestExecutionRequest,
      requestStream: false,
      responseType: RequestExecutionResponse,
      responseStream: false,
      options: {},
    },
    listExecutions: {
      name: "ListExecutions",
      requestType: ListExecutionsRequest,
      requestStream: false,
      responseType: ListExecutionsResponse,
      responseStream: false,
      options: {},
    },
    signConfig: {
      name: "SignConfig",
      requestType: SignConfigRequest,
      requestStream: false,
      responseType: SignConfigResponse,
      responseStream: false,
      options: {},
    },
    publicKey: {
      name: "PublicKey",
      requestType: PublicKeyRequest,
      requestStream: false,
      responseType: PublicKeyResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface ExecutionsServiceImplementation<CallContextExt = {}> {
  requestExecution(
    request: RequestExecutionRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<RequestExecutionResponse>>;
  listExecutions(
    request: ListExecutionsRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<ListExecutionsResponse>>;
  signConfig(
    request: SignConfigRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<SignConfigResponse>>;
  publicKey(
    request: PublicKeyRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<PublicKeyResponse>>;
}

export interface ExecutionsClient<CallOptionsExt = {}> {
  requestExecution(
    request: DeepPartial<RequestExecutionRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<RequestExecutionResponse>;
  listExecutions(
    request: DeepPartial<ListExecutionsRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<ListExecutionsResponse>;
  signConfig(
    request: DeepPartial<SignConfigRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<SignConfigResponse>;
  publicKey(
    request: DeepPartial<PublicKeyRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<PublicKeyResponse>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

const atob: (b64: string) => string =
  globalThis.atob ||
  ((b64) => globalThis.Buffer.from(b64, "base64").toString("binary"));
function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

const btoa: (bin: string) => string =
  globalThis.btoa ||
  ((bin) => globalThis.Buffer.from(bin, "binary").toString("base64"));
function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach((byte) => {
    bin.push(String.fromCharCode(byte));
  });
  return btoa(bin.join(""));
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
