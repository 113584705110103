// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {Footer} from "@ags-oditoo/components";
import {Container, Stack, Theme, useMediaQuery} from "@mui/material";
import React from "react";
import {useLocation} from "react-router-dom";
import {useAPI} from "../../api/useAPI";
import {DrawerLayout} from "./DrawerLayout";
import {Header} from "./Header";
import {UnauthenticatedLayout} from "./UnauthenticatedLayout";

export const TabletOrDesktopLayout = ({children}: React.PropsWithChildren<any>) => {
  const {authenticated} = useAPI();
  const {pathname} = useLocation();
  const show = authenticated && pathname !== "/logout";
  return show ? (
    <Stack
      id="main"
      component="main"
      minWidth='100%'
      minHeight="100%"
      sx={{flexGrow: 1}}
    >
      <DrawerLayout>
        {/*<Container sx={{*/}
        {/*  display: "flex",*/}
        {/*  flexDirection: "column",*/}
        {/*  flex: 1,*/}
        {/*  marginLeft: isXl ? 0 : undefined,*/}
        {/*  marginBottom: "42px",*/}
        {/*}}>*/}
          <Header padding={0} paddingLeft={1} paddingBottom={(theme) => theme.spacing(2)}/>
          <Stack flex={1}>{children}</Stack>
          <Footer/>
        {/*</Container>*/}
      </DrawerLayout>
    </Stack>
  ) : (
    <UnauthenticatedLayout>{children}</UnauthenticatedLayout>
  );
};
