// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {ResponsiveButton} from "@ags-oditoo/components";
import {defaultPadding, defaultSpacing} from "@ags-oditoo/theme";
import {AddCircleOutlineOutlined, PlayArrowOutlined} from "@mui/icons-material";
import {Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {Execution} from "../api/generated/api/v1/types";
import {Admin, OrgAdmin, OrgReader, Reader, User} from "../api/roles";
import {useAPI} from "../api/useAPI";
import {ExecutionsTable} from "../Components/Executions/ExecutionsTable";
import {useAuthenticatedAsyncOnce, useError} from "../hooks";
import {MainRoutesRegistry} from "../routes";

export const ExecutionsPage = () => {
  const {api} = useAPI();
  const [executions, setExecutions] = useState<Execution[]>();
  const [Error, _, withError] = useError();
  useAuthenticatedAsyncOnce(withError(async () => {
    const {executions} = await api.exec.listExecutions({});
    setExecutions(executions);
  }))
  return (
    <Stack flex={1} spacing={defaultSpacing} sx={{padding: defaultPadding}}>
      <Error/>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h6'>Executions</Typography>
        <ResponsiveButton
          variant='outlined'
          icon={<AddCircleOutlineOutlined/>}
          href={MainRoutesRegistry.token.navigate()}
          label="Request token"
        />
      </Stack>
      <Stack flex={1}>
        <ExecutionsTable
          executions={executions}
          userLink={user => MainRoutesRegistry.userDetails.navigate(user?.name) ?? ''}
          organisationLink={organisation => MainRoutesRegistry.organisationDetails.navigate(organisation)}
          versionLink={version => MainRoutesRegistry.releaseDetails.navigate(version) ?? ''}
        />
      </Stack>
    </Stack>
  )
}

MainRoutesRegistry['executions'] = {
  path: '/executions',
  component: <ExecutionsPage/>,
  icon: <PlayArrowOutlined/>,
  priority: 900,
  public: false,
  label: 'Executions',
  show: true,
  navigate: () => '/executions',
  roles: [Admin, Reader, OrgAdmin, OrgReader, User],
}
