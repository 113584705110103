// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {object, ObjectSchema, ref, string} from 'yup'
import {DeepPartial} from "../generated/api/v1/types";
import {ResetPasswordRequest, SendResetPasswordEmailRequest} from "../generated/api/v1/users.service";

export interface Credentials {
  email: string
  password: string
}

export const credentialsSchema: ObjectSchema<Credentials> = object({
  email: string().email().required('Required'),
  password: string().min(8).required('Required'),
})

export type EmailRequest = SendResetPasswordEmailRequest

export const emailSchema: ObjectSchema<DeepPartial<EmailRequest>> = object({
  email: string().email().required('Required'),
})

export interface PasswordConfirm {
  password: string
  passwordConfirm: string
}

export const passwordConfirmSchema: ObjectSchema<PasswordConfirm> = object({
  password: string().min(8).required('Required'),
  passwordConfirm: string().oneOf([ref('password'), undefined], 'Passwords must match').required('Required'),
})
