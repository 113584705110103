// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {CloudDownloadOutlined} from "@mui/icons-material";
import {Button, Link} from "@mui/material";
import {GridActionsCellItem, GridColDef} from "@mui/x-data-grid";
import React, {useMemo} from "react";
import {Release} from "../../api/generated/api/v1/types";
import {Table} from "../Table";
import {BaseTableProps} from "../Table/BaseTableProps";

export interface ReleasesTableProps extends BaseTableProps<Release> {
  releases?: Release[];
  downloadLink?: (release: Release) => string;
  organisationLink?: (name: string) => string;
}

export const ReleasesTable = ({releases, link, downloadLink, organisationLink}: ReleasesTableProps) => {
  const columns = useMemo<GridColDef<Release>[]>(() => {
    return ([
      {
        field: 'version',
        headerName: 'Version',
        flex: 1,
        renderCell: ({row}) => link ? <Link href={link(row)}>{row.version}</Link> : row.version,
      },
      {
        field: 'createdAt',
        headerName: 'Date',
        type: 'dateTime',
        flex: 1,
      },
      {
        field: 'organisation',
        headerName: 'Organisation',
        flex: 1,
        renderCell: ({row}) => organisationLink ? <Link href={organisationLink(row.organisation?.name ?? '')}>{row.organisation?.name ?? ''}</Link> : row.organisation?.name ?? '',
      },
      {
        field: 'executions',
        headerName: 'Executions',
        valueGetter: (params) => params.row.executions.length,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 150,
        renderCell: ({row}) => (
          <Button component='a' href={downloadLink?.(row)} variant='text' startIcon={<CloudDownloadOutlined/>}>Download</Button>
        )
      }
    ])
  }, []);
  return (
    <Table columns={columns} rows={releases ?? []} loading={!releases}/>
  )
}
