/* eslint-disable */
import { messageTypeRegistry } from "../../typeRegistry";
import { Role, User, roleFromJSON, roleToJSON } from "./types";
import { CallContext, CallOptions } from "nice-grpc-common";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "oditoo.v1";

export interface CreateUserRequest {
  $type: "oditoo.v1.CreateUserRequest";
  /** Name is the user name. */
  name: string;
  /** Email is the user email. */
  email: string;
  /**
   * OrganisationID is the organisations' id the user belongs to.
   * If not set, the user will be created in the organisation of the user creating the user.
   */
  organisationId?: string | undefined;
  /** Role is the user role. */
  role: Role;
}

export interface CreateUserResponse {
  $type: "oditoo.v1.CreateUserResponse";
  /** User is the created user. */
  user?: User;
}

export interface ReadSelfRequest {
  $type: "oditoo.v1.ReadSelfRequest";
}

export interface ReadSelfResponse {
  $type: "oditoo.v1.ReadSelfResponse";
  /** User is the read user. */
  user?: User;
}

export interface ReadUserRequest {
  $type: "oditoo.v1.ReadUserRequest";
  /** ID is the id of the user to read. */
  idOrName: string;
}

export interface ReadUserResponse {
  $type: "oditoo.v1.ReadUserResponse";
  /** User is the read user. */
  user?: User;
}

export interface UpdateUserRequest {
  $type: "oditoo.v1.UpdateUserRequest";
  /** User is the user to update. */
  user?: User;
}

export interface UpdateUserResponse {
  $type: "oditoo.v1.UpdateUserResponse";
  /** User is the updated user. */
  user?: User;
}

export interface SendResetPasswordEmailRequest {
  $type: "oditoo.v1.SendResetPasswordEmailRequest";
  /** Email is the user email. */
  email: string;
}

export interface SendResetPasswordEmailResponse {
  $type: "oditoo.v1.SendResetPasswordEmailResponse";
}

export interface ResetPasswordRequest {
  $type: "oditoo.v1.ResetPasswordRequest";
  /** Code is the reset password code. */
  code: string;
  /** Password is the user password. */
  password: string;
}

export interface ResetPasswordResponse {
  $type: "oditoo.v1.ResetPasswordResponse";
}

export interface ListUsersRequest {
  $type: "oditoo.v1.ListUsersRequest";
  /**
   * Limit is the maximum number of users to return.
   * The server may return fewer than this at its discretion.
   * If Limit is negative or zero, the server will decide the number of users to return.
   */
  limit: number;
  /**
   * Offset is the number of users to skip before starting to collect the result set.
   * If Offset is negative, the server will decide the number of users to skip.
   */
  offset: number;
}

export interface ListUsersResponse {
  $type: "oditoo.v1.ListUsersResponse";
  /** Users is the list of users. */
  users: User[];
}

export interface DeleteUserRequest {
  $type: "oditoo.v1.DeleteUserRequest";
  /** ID is the id of the user to delete. */
  id: string;
}

export interface DeleteUserResponse {
  $type: "oditoo.v1.DeleteUserResponse";
}

export interface SendConfirmEmailRequest {
  $type: "oditoo.v1.SendConfirmEmailRequest";
  /** Email is the user email. */
  email: string;
}

export interface SendConfirmEmailResponse {
  $type: "oditoo.v1.SendConfirmEmailResponse";
}

export interface ConfirmEmailRequest {
  $type: "oditoo.v1.ConfirmEmailRequest";
  /** Code is the confirm email code. */
  code: string;
}

export interface ConfirmEmailResponse {
  $type: "oditoo.v1.ConfirmEmailResponse";
}

function createBaseCreateUserRequest(): CreateUserRequest {
  return {
    $type: "oditoo.v1.CreateUserRequest",
    name: "",
    email: "",
    organisationId: undefined,
    role: 0,
  };
}

export const CreateUserRequest = {
  $type: "oditoo.v1.CreateUserRequest" as const,

  encode(
    message: CreateUserRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(50).string(message.email);
    }
    if (message.organisationId !== undefined) {
      writer.uint32(58).string(message.organisationId);
    }
    if (message.role !== 0) {
      writer.uint32(64).int32(message.role);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          message.name = reader.string();
          break;
        case 6:
          message.email = reader.string();
          break;
        case 7:
          message.organisationId = reader.string();
          break;
        case 8:
          message.role = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateUserRequest {
    return {
      $type: CreateUserRequest.$type,
      name: isSet(object.name) ? String(object.name) : "",
      email: isSet(object.email) ? String(object.email) : "",
      organisationId: isSet(object.organisationId)
        ? String(object.organisationId)
        : undefined,
      role: isSet(object.role) ? roleFromJSON(object.role) : 0,
    };
  },

  toJSON(message: CreateUserRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.email !== undefined && (obj.email = message.email);
    message.organisationId !== undefined &&
      (obj.organisationId = message.organisationId);
    message.role !== undefined && (obj.role = roleToJSON(message.role));
    return obj;
  },

  fromPartial(object: DeepPartial<CreateUserRequest>): CreateUserRequest {
    const message = createBaseCreateUserRequest();
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.organisationId = object.organisationId ?? undefined;
    message.role = object.role ?? 0;
    return message;
  },
};

messageTypeRegistry.set(CreateUserRequest.$type, CreateUserRequest);

function createBaseCreateUserResponse(): CreateUserResponse {
  return { $type: "oditoo.v1.CreateUserResponse", user: undefined };
}

export const CreateUserResponse = {
  $type: "oditoo.v1.CreateUserResponse" as const,

  encode(
    message: CreateUserResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.user = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateUserResponse {
    return {
      $type: CreateUserResponse.$type,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: CreateUserResponse): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<CreateUserResponse>): CreateUserResponse {
    const message = createBaseCreateUserResponse();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(CreateUserResponse.$type, CreateUserResponse);

function createBaseReadSelfRequest(): ReadSelfRequest {
  return { $type: "oditoo.v1.ReadSelfRequest" };
}

export const ReadSelfRequest = {
  $type: "oditoo.v1.ReadSelfRequest" as const,

  encode(
    _: ReadSelfRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReadSelfRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReadSelfRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ReadSelfRequest {
    return {
      $type: ReadSelfRequest.$type,
    };
  },

  toJSON(_: ReadSelfRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<ReadSelfRequest>): ReadSelfRequest {
    const message = createBaseReadSelfRequest();
    return message;
  },
};

messageTypeRegistry.set(ReadSelfRequest.$type, ReadSelfRequest);

function createBaseReadSelfResponse(): ReadSelfResponse {
  return { $type: "oditoo.v1.ReadSelfResponse", user: undefined };
}

export const ReadSelfResponse = {
  $type: "oditoo.v1.ReadSelfResponse" as const,

  encode(
    message: ReadSelfResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReadSelfResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReadSelfResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.user = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReadSelfResponse {
    return {
      $type: ReadSelfResponse.$type,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: ReadSelfResponse): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<ReadSelfResponse>): ReadSelfResponse {
    const message = createBaseReadSelfResponse();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(ReadSelfResponse.$type, ReadSelfResponse);

function createBaseReadUserRequest(): ReadUserRequest {
  return { $type: "oditoo.v1.ReadUserRequest", idOrName: "" };
}

export const ReadUserRequest = {
  $type: "oditoo.v1.ReadUserRequest" as const,

  encode(
    message: ReadUserRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.idOrName !== "") {
      writer.uint32(10).string(message.idOrName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReadUserRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReadUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.idOrName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReadUserRequest {
    return {
      $type: ReadUserRequest.$type,
      idOrName: isSet(object.idOrName) ? String(object.idOrName) : "",
    };
  },

  toJSON(message: ReadUserRequest): unknown {
    const obj: any = {};
    message.idOrName !== undefined && (obj.idOrName = message.idOrName);
    return obj;
  },

  fromPartial(object: DeepPartial<ReadUserRequest>): ReadUserRequest {
    const message = createBaseReadUserRequest();
    message.idOrName = object.idOrName ?? "";
    return message;
  },
};

messageTypeRegistry.set(ReadUserRequest.$type, ReadUserRequest);

function createBaseReadUserResponse(): ReadUserResponse {
  return { $type: "oditoo.v1.ReadUserResponse", user: undefined };
}

export const ReadUserResponse = {
  $type: "oditoo.v1.ReadUserResponse" as const,

  encode(
    message: ReadUserResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReadUserResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReadUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.user = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReadUserResponse {
    return {
      $type: ReadUserResponse.$type,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: ReadUserResponse): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<ReadUserResponse>): ReadUserResponse {
    const message = createBaseReadUserResponse();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(ReadUserResponse.$type, ReadUserResponse);

function createBaseUpdateUserRequest(): UpdateUserRequest {
  return { $type: "oditoo.v1.UpdateUserRequest", user: undefined };
}

export const UpdateUserRequest = {
  $type: "oditoo.v1.UpdateUserRequest" as const,

  encode(
    message: UpdateUserRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.user = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateUserRequest {
    return {
      $type: UpdateUserRequest.$type,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: UpdateUserRequest): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<UpdateUserRequest>): UpdateUserRequest {
    const message = createBaseUpdateUserRequest();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(UpdateUserRequest.$type, UpdateUserRequest);

function createBaseUpdateUserResponse(): UpdateUserResponse {
  return { $type: "oditoo.v1.UpdateUserResponse", user: undefined };
}

export const UpdateUserResponse = {
  $type: "oditoo.v1.UpdateUserResponse" as const,

  encode(
    message: UpdateUserResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.user = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateUserResponse {
    return {
      $type: UpdateUserResponse.$type,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: UpdateUserResponse): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<UpdateUserResponse>): UpdateUserResponse {
    const message = createBaseUpdateUserResponse();
    message.user =
      object.user !== undefined && object.user !== null
        ? User.fromPartial(object.user)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(UpdateUserResponse.$type, UpdateUserResponse);

function createBaseSendResetPasswordEmailRequest(): SendResetPasswordEmailRequest {
  return { $type: "oditoo.v1.SendResetPasswordEmailRequest", email: "" };
}

export const SendResetPasswordEmailRequest = {
  $type: "oditoo.v1.SendResetPasswordEmailRequest" as const,

  encode(
    message: SendResetPasswordEmailRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SendResetPasswordEmailRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendResetPasswordEmailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SendResetPasswordEmailRequest {
    return {
      $type: SendResetPasswordEmailRequest.$type,
      email: isSet(object.email) ? String(object.email) : "",
    };
  },

  toJSON(message: SendResetPasswordEmailRequest): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    return obj;
  },

  fromPartial(
    object: DeepPartial<SendResetPasswordEmailRequest>
  ): SendResetPasswordEmailRequest {
    const message = createBaseSendResetPasswordEmailRequest();
    message.email = object.email ?? "";
    return message;
  },
};

messageTypeRegistry.set(
  SendResetPasswordEmailRequest.$type,
  SendResetPasswordEmailRequest
);

function createBaseSendResetPasswordEmailResponse(): SendResetPasswordEmailResponse {
  return { $type: "oditoo.v1.SendResetPasswordEmailResponse" };
}

export const SendResetPasswordEmailResponse = {
  $type: "oditoo.v1.SendResetPasswordEmailResponse" as const,

  encode(
    _: SendResetPasswordEmailResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SendResetPasswordEmailResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendResetPasswordEmailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SendResetPasswordEmailResponse {
    return {
      $type: SendResetPasswordEmailResponse.$type,
    };
  },

  toJSON(_: SendResetPasswordEmailResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(
    _: DeepPartial<SendResetPasswordEmailResponse>
  ): SendResetPasswordEmailResponse {
    const message = createBaseSendResetPasswordEmailResponse();
    return message;
  },
};

messageTypeRegistry.set(
  SendResetPasswordEmailResponse.$type,
  SendResetPasswordEmailResponse
);

function createBaseResetPasswordRequest(): ResetPasswordRequest {
  return { $type: "oditoo.v1.ResetPasswordRequest", code: "", password: "" };
}

export const ResetPasswordRequest = {
  $type: "oditoo.v1.ResetPasswordRequest" as const,

  encode(
    message: ResetPasswordRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    if (message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ResetPasswordRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetPasswordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.code = reader.string();
          break;
        case 2:
          message.password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ResetPasswordRequest {
    return {
      $type: ResetPasswordRequest.$type,
      code: isSet(object.code) ? String(object.code) : "",
      password: isSet(object.password) ? String(object.password) : "",
    };
  },

  toJSON(message: ResetPasswordRequest): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    message.password !== undefined && (obj.password = message.password);
    return obj;
  },

  fromPartial(object: DeepPartial<ResetPasswordRequest>): ResetPasswordRequest {
    const message = createBaseResetPasswordRequest();
    message.code = object.code ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

messageTypeRegistry.set(ResetPasswordRequest.$type, ResetPasswordRequest);

function createBaseResetPasswordResponse(): ResetPasswordResponse {
  return { $type: "oditoo.v1.ResetPasswordResponse" };
}

export const ResetPasswordResponse = {
  $type: "oditoo.v1.ResetPasswordResponse" as const,

  encode(
    _: ResetPasswordResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ResetPasswordResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetPasswordResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ResetPasswordResponse {
    return {
      $type: ResetPasswordResponse.$type,
    };
  },

  toJSON(_: ResetPasswordResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<ResetPasswordResponse>): ResetPasswordResponse {
    const message = createBaseResetPasswordResponse();
    return message;
  },
};

messageTypeRegistry.set(ResetPasswordResponse.$type, ResetPasswordResponse);

function createBaseListUsersRequest(): ListUsersRequest {
  return { $type: "oditoo.v1.ListUsersRequest", limit: 0, offset: 0 };
}

export const ListUsersRequest = {
  $type: "oditoo.v1.ListUsersRequest" as const,

  encode(
    message: ListUsersRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.limit !== 0) {
      writer.uint32(8).int32(message.limit);
    }
    if (message.offset !== 0) {
      writer.uint32(16).int32(message.offset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUsersRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = reader.int32();
          break;
        case 2:
          message.offset = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ListUsersRequest {
    return {
      $type: ListUsersRequest.$type,
      limit: isSet(object.limit) ? Number(object.limit) : 0,
      offset: isSet(object.offset) ? Number(object.offset) : 0,
    };
  },

  toJSON(message: ListUsersRequest): unknown {
    const obj: any = {};
    message.limit !== undefined && (obj.limit = Math.round(message.limit));
    message.offset !== undefined && (obj.offset = Math.round(message.offset));
    return obj;
  },

  fromPartial(object: DeepPartial<ListUsersRequest>): ListUsersRequest {
    const message = createBaseListUsersRequest();
    message.limit = object.limit ?? 0;
    message.offset = object.offset ?? 0;
    return message;
  },
};

messageTypeRegistry.set(ListUsersRequest.$type, ListUsersRequest);

function createBaseListUsersResponse(): ListUsersResponse {
  return { $type: "oditoo.v1.ListUsersResponse", users: [] };
}

export const ListUsersResponse = {
  $type: "oditoo.v1.ListUsersResponse" as const,

  encode(
    message: ListUsersResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.users) {
      User.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUsersResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.users.push(User.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ListUsersResponse {
    return {
      $type: ListUsersResponse.$type,
      users: Array.isArray(object?.users)
        ? object.users.map((e: any) => User.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListUsersResponse): unknown {
    const obj: any = {};
    if (message.users) {
      obj.users = message.users.map((e) => (e ? User.toJSON(e) : undefined));
    } else {
      obj.users = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<ListUsersResponse>): ListUsersResponse {
    const message = createBaseListUsersResponse();
    message.users = object.users?.map((e) => User.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ListUsersResponse.$type, ListUsersResponse);

function createBaseDeleteUserRequest(): DeleteUserRequest {
  return { $type: "oditoo.v1.DeleteUserRequest", id: "" };
}

export const DeleteUserRequest = {
  $type: "oditoo.v1.DeleteUserRequest" as const,

  encode(
    message: DeleteUserRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteUserRequest {
    return {
      $type: DeleteUserRequest.$type,
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: DeleteUserRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<DeleteUserRequest>): DeleteUserRequest {
    const message = createBaseDeleteUserRequest();
    message.id = object.id ?? "";
    return message;
  },
};

messageTypeRegistry.set(DeleteUserRequest.$type, DeleteUserRequest);

function createBaseDeleteUserResponse(): DeleteUserResponse {
  return { $type: "oditoo.v1.DeleteUserResponse" };
}

export const DeleteUserResponse = {
  $type: "oditoo.v1.DeleteUserResponse" as const,

  encode(
    _: DeleteUserResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteUserResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): DeleteUserResponse {
    return {
      $type: DeleteUserResponse.$type,
    };
  },

  toJSON(_: DeleteUserResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<DeleteUserResponse>): DeleteUserResponse {
    const message = createBaseDeleteUserResponse();
    return message;
  },
};

messageTypeRegistry.set(DeleteUserResponse.$type, DeleteUserResponse);

function createBaseSendConfirmEmailRequest(): SendConfirmEmailRequest {
  return { $type: "oditoo.v1.SendConfirmEmailRequest", email: "" };
}

export const SendConfirmEmailRequest = {
  $type: "oditoo.v1.SendConfirmEmailRequest" as const,

  encode(
    message: SendConfirmEmailRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SendConfirmEmailRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendConfirmEmailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SendConfirmEmailRequest {
    return {
      $type: SendConfirmEmailRequest.$type,
      email: isSet(object.email) ? String(object.email) : "",
    };
  },

  toJSON(message: SendConfirmEmailRequest): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    return obj;
  },

  fromPartial(
    object: DeepPartial<SendConfirmEmailRequest>
  ): SendConfirmEmailRequest {
    const message = createBaseSendConfirmEmailRequest();
    message.email = object.email ?? "";
    return message;
  },
};

messageTypeRegistry.set(SendConfirmEmailRequest.$type, SendConfirmEmailRequest);

function createBaseSendConfirmEmailResponse(): SendConfirmEmailResponse {
  return { $type: "oditoo.v1.SendConfirmEmailResponse" };
}

export const SendConfirmEmailResponse = {
  $type: "oditoo.v1.SendConfirmEmailResponse" as const,

  encode(
    _: SendConfirmEmailResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SendConfirmEmailResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSendConfirmEmailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SendConfirmEmailResponse {
    return {
      $type: SendConfirmEmailResponse.$type,
    };
  },

  toJSON(_: SendConfirmEmailResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(
    _: DeepPartial<SendConfirmEmailResponse>
  ): SendConfirmEmailResponse {
    const message = createBaseSendConfirmEmailResponse();
    return message;
  },
};

messageTypeRegistry.set(
  SendConfirmEmailResponse.$type,
  SendConfirmEmailResponse
);

function createBaseConfirmEmailRequest(): ConfirmEmailRequest {
  return { $type: "oditoo.v1.ConfirmEmailRequest", code: "" };
}

export const ConfirmEmailRequest = {
  $type: "oditoo.v1.ConfirmEmailRequest" as const,

  encode(
    message: ConfirmEmailRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConfirmEmailRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfirmEmailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.code = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ConfirmEmailRequest {
    return {
      $type: ConfirmEmailRequest.$type,
      code: isSet(object.code) ? String(object.code) : "",
    };
  },

  toJSON(message: ConfirmEmailRequest): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    return obj;
  },

  fromPartial(object: DeepPartial<ConfirmEmailRequest>): ConfirmEmailRequest {
    const message = createBaseConfirmEmailRequest();
    message.code = object.code ?? "";
    return message;
  },
};

messageTypeRegistry.set(ConfirmEmailRequest.$type, ConfirmEmailRequest);

function createBaseConfirmEmailResponse(): ConfirmEmailResponse {
  return { $type: "oditoo.v1.ConfirmEmailResponse" };
}

export const ConfirmEmailResponse = {
  $type: "oditoo.v1.ConfirmEmailResponse" as const,

  encode(
    _: ConfirmEmailResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ConfirmEmailResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfirmEmailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ConfirmEmailResponse {
    return {
      $type: ConfirmEmailResponse.$type,
    };
  },

  toJSON(_: ConfirmEmailResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<ConfirmEmailResponse>): ConfirmEmailResponse {
    const message = createBaseConfirmEmailResponse();
    return message;
  },
};

messageTypeRegistry.set(ConfirmEmailResponse.$type, ConfirmEmailResponse);

export type UsersDefinition = typeof UsersDefinition;
export const UsersDefinition = {
  name: "Users",
  fullName: "oditoo.v1.Users",
  methods: {
    createUser: {
      name: "CreateUser",
      requestType: CreateUserRequest,
      requestStream: false,
      responseType: CreateUserResponse,
      responseStream: false,
      options: {},
    },
    readSelf: {
      name: "ReadSelf",
      requestType: ReadSelfRequest,
      requestStream: false,
      responseType: ReadSelfResponse,
      responseStream: false,
      options: {},
    },
    readUser: {
      name: "ReadUser",
      requestType: ReadUserRequest,
      requestStream: false,
      responseType: ReadUserResponse,
      responseStream: false,
      options: {},
    },
    updateUser: {
      name: "UpdateUser",
      requestType: UpdateUserRequest,
      requestStream: false,
      responseType: UpdateUserResponse,
      responseStream: false,
      options: {},
    },
    listUsers: {
      name: "ListUsers",
      requestType: ListUsersRequest,
      requestStream: false,
      responseType: ListUsersResponse,
      responseStream: false,
      options: {},
    },
    deleteUser: {
      name: "DeleteUser",
      requestType: DeleteUserRequest,
      requestStream: false,
      responseType: DeleteUserResponse,
      responseStream: false,
      options: {},
    },
    sendResetPasswordEmail: {
      name: "SendResetPasswordEmail",
      requestType: SendResetPasswordEmailRequest,
      requestStream: false,
      responseType: SendResetPasswordEmailResponse,
      responseStream: false,
      options: {},
    },
    resetPassword: {
      name: "ResetPassword",
      requestType: ResetPasswordRequest,
      requestStream: false,
      responseType: ResetPasswordResponse,
      responseStream: false,
      options: {},
    },
    sendConfirmEmail: {
      name: "SendConfirmEmail",
      requestType: SendConfirmEmailRequest,
      requestStream: false,
      responseType: SendConfirmEmailResponse,
      responseStream: false,
      options: {},
    },
    confirmEmail: {
      name: "ConfirmEmail",
      requestType: ConfirmEmailRequest,
      requestStream: false,
      responseType: ConfirmEmailResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface UsersServiceImplementation<CallContextExt = {}> {
  createUser(
    request: CreateUserRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<CreateUserResponse>>;
  readSelf(
    request: ReadSelfRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<ReadSelfResponse>>;
  readUser(
    request: ReadUserRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<ReadUserResponse>>;
  updateUser(
    request: UpdateUserRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<UpdateUserResponse>>;
  listUsers(
    request: ListUsersRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<ListUsersResponse>>;
  deleteUser(
    request: DeleteUserRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<DeleteUserResponse>>;
  sendResetPasswordEmail(
    request: SendResetPasswordEmailRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<SendResetPasswordEmailResponse>>;
  resetPassword(
    request: ResetPasswordRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<ResetPasswordResponse>>;
  sendConfirmEmail(
    request: SendConfirmEmailRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<SendConfirmEmailResponse>>;
  confirmEmail(
    request: ConfirmEmailRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<ConfirmEmailResponse>>;
}

export interface UsersClient<CallOptionsExt = {}> {
  createUser(
    request: DeepPartial<CreateUserRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<CreateUserResponse>;
  readSelf(
    request: DeepPartial<ReadSelfRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<ReadSelfResponse>;
  readUser(
    request: DeepPartial<ReadUserRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<ReadUserResponse>;
  updateUser(
    request: DeepPartial<UpdateUserRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<UpdateUserResponse>;
  listUsers(
    request: DeepPartial<ListUsersRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<ListUsersResponse>;
  deleteUser(
    request: DeepPartial<DeleteUserRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<DeleteUserResponse>;
  sendResetPasswordEmail(
    request: DeepPartial<SendResetPasswordEmailRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<SendResetPasswordEmailResponse>;
  resetPassword(
    request: DeepPartial<ResetPasswordRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<ResetPasswordResponse>;
  sendConfirmEmail(
    request: DeepPartial<SendConfirmEmailRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<SendConfirmEmailResponse>;
  confirmEmail(
    request: DeepPartial<ConfirmEmailRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<ConfirmEmailResponse>;
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
