// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {AlternateEmailOutlined, PersonOutlined} from "@mui/icons-material";
import {Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import {Formik} from "formik";
import React from "react";
import {DeepPartial, Organisation, Role, User} from "../../api/generated/api/v1/types";
import {RoleName} from "../../api/roles";
import {userSchema} from "../../api/schemas/users";
import {useAPI} from "../../api/useAPI";
import {useAuthenticatedAsyncOnce} from "../../hooks";
import {defaultSpacing} from "@ags-oditoo/theme";
import {FAutocomplete, FTextField} from "../Form";

export interface UserFormProps {
  user: DeepPartial<User>
  submitLabel?: string
  onSubmit: (user: DeepPartial<User>) => Promise<void>
  onCancel?: () => void
}

const baseRoles = [Role.USER, Role.ORG_READER, Role.ORG_ADMIN]

export const UserForm = ({user, submitLabel, onSubmit, onCancel}: UserFormProps) => {
  const {user: currentUser, api} = useAPI();
  const isAdmin = currentUser?.role === Role.ADMIN;
  const [organisations, setOrganisations] = React.useState<Organisation[]>([])
  useAuthenticatedAsyncOnce(async () => {
    if (!isAdmin) return;
    const {organisations} = await api.organisations.listOrganisations({});
    setOrganisations(organisations)
  })
  if (!currentUser) return null;
  const roles = isAdmin ? [...baseRoles, Role.READER, Role.ADMIN] : [...baseRoles]
  return (
    <Formik<DeepPartial<User>> initialValues={{name:'', email: '', role: Role.USER, organisationId: currentUser.organisationId, ...user}} validationSchema={userSchema} onSubmit={onSubmit}>
      {({values, setValues, isSubmitting, handleReset, handleSubmit, errors}) => (
        <Stack component='form' onReset={handleReset} onSubmit={handleSubmit} spacing={defaultSpacing}>
          <Stack spacing={defaultSpacing}>
            <FTextField
              name='name'
              label='Name'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PersonOutlined/>
                  </InputAdornment>
                ),
              }}
            />
            <FTextField
              name='email'
              label='Email'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AlternateEmailOutlined/>
                  </InputAdornment>
                ),
              }}
            />
            {
              currentUser?.role === Role.ADMIN && (
                <FAutocomplete
                  id='organisationId'
                  name='organisationId'
                  autoSelect
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      label='Organisation'
                      {...params}
                    />
                  )}
                  loading={organisations.length === 0}
                  options={organisations.sort((a, b) => a.name.localeCompare(b.name))}
                  getOptionLabel={v => typeof v === 'string' ? v : v.name}

                  // renderOption={(_, v: Organisation) => v.name}
                  // @ts-ignore
                  onChange={(_, v) => v && setValues({...values, organisationId: (v as Organisation).id})}
                  value={organisations.find(v => v.id === values.organisationId) ?? ''}
                />
              )
            }
            <FormControl>
              <InputLabel id='role-label'>Role</InputLabel>
              <Select
                labelId='role-label'
                id='role'
                name='role'
                value={values.role}
                onChange={e => setValues({...values, role: e.target.value as Role})}
              >
                {roles.filter(v => v <= currentUser?.role).map((v, i) => <MenuItem key={i} value={v}>{RoleName(v)}</MenuItem>)}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction='row' spacing={4}>
            {onCancel && <Button fullWidth disabled={isSubmitting} onClick={onCancel} variant="outlined">Cancel</Button>}
            <Button fullWidth disabled={isSubmitting} type='submit' variant="contained">
              {submitLabel || "Save User"}
            </Button>
          </Stack>
        </Stack>
      )}
    </Formik>
  )
}
