// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


import {roleToJSON, User as APIUser, Role as APIRole} from "./generated/api/v1/types";

export const Admin = "ADMIN";
export const Reader = "READER";
export const OrgAdmin = "ORG_ADMIN";
export const OrgReader = "ORG_READER";
export const User = "USER";
export const Authenticated = "AUTHENTICATED";
export const Anonymous = "ANONYMOUS";

export type Role = "ADMIN" | "READER" | "ORG_ADMIN" | "ORG_READER" | "USER" | "AUTHENTICATED" | "ANONYMOUS";

export const userRole = (user?: APIUser): Role => {
  if (!user) return Anonymous;
  return roleToJSON(user.role) as Role;
}

export const RoleName = (role: APIRole) => {
  switch (role) {
    case APIRole.ADMIN:
      return "Application Administrator";
    case APIRole.READER:
      return "Application Read Only";
    case APIRole.ORG_ADMIN:
      return "Organisation Administrator";
    case APIRole.ORG_READER:
      return "Organisation Read Only";
    case APIRole.USER:
      return "User";
    default:
      return APIRole[role];
  }
}
