/* eslint-disable */
import { messageTypeRegistry } from "../../typeRegistry";
import { Organisation } from "./types";
import { CallContext, CallOptions } from "nice-grpc-common";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "oditoo.v1";

export interface CreateOrganisationRequest {
  $type: "oditoo.v1.CreateOrganisationRequest";
  /** Organisation is the organisation to create. */
  organisation?: Organisation;
}

export interface CreateOrganisationResponse {
  $type: "oditoo.v1.CreateOrganisationResponse";
  /** Organisation is the created organisation. */
  organisation?: Organisation;
}

export interface ReadOrganisationRequest {
  $type: "oditoo.v1.ReadOrganisationRequest";
  /** IDOrName is the id or the name of the organisation to read. */
  idOrName: string;
}

export interface ReadOrganisationResponse {
  $type: "oditoo.v1.ReadOrganisationResponse";
  /** Organisation is the read organisation. */
  organisation?: Organisation;
}

export interface UpdateOrganisationRequest {
  $type: "oditoo.v1.UpdateOrganisationRequest";
  /** Organisation is the organisation to update. */
  organisation?: Organisation;
}

export interface UpdateOrganisationResponse {
  $type: "oditoo.v1.UpdateOrganisationResponse";
  /** Organisation is the updated organisation. */
  organisation?: Organisation;
}

export interface ListOrganisationsRequest {
  $type: "oditoo.v1.ListOrganisationsRequest";
  /**
   * Limit is the maximum number of organisations to return.
   * The server may return fewer than this at its discretion.
   * If Limit is negative or zero, the server will decide the number of organisations to return.
   */
  limit: number;
  /**
   * Offset is the number of organisations to skip before starting to collect the result set.
   * If Offset is negative, the server will decide the number of organisations to skip.
   */
  offset: number;
}

export interface ListOrganisationsResponse {
  $type: "oditoo.v1.ListOrganisationsResponse";
  /** Organisations is the list of organisations. */
  organisations: Organisation[];
}

export interface DeleteOrganisationRequest {
  $type: "oditoo.v1.DeleteOrganisationRequest";
  /** ID is the id of the organisation to delete. */
  id: string;
}

export interface DeleteOrganisationResponse {
  $type: "oditoo.v1.DeleteOrganisationResponse";
}

function createBaseCreateOrganisationRequest(): CreateOrganisationRequest {
  return {
    $type: "oditoo.v1.CreateOrganisationRequest",
    organisation: undefined,
  };
}

export const CreateOrganisationRequest = {
  $type: "oditoo.v1.CreateOrganisationRequest" as const,

  encode(
    message: CreateOrganisationRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.organisation !== undefined) {
      Organisation.encode(
        message.organisation,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CreateOrganisationRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrganisationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.organisation = Organisation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateOrganisationRequest {
    return {
      $type: CreateOrganisationRequest.$type,
      organisation: isSet(object.organisation)
        ? Organisation.fromJSON(object.organisation)
        : undefined,
    };
  },

  toJSON(message: CreateOrganisationRequest): unknown {
    const obj: any = {};
    message.organisation !== undefined &&
      (obj.organisation = message.organisation
        ? Organisation.toJSON(message.organisation)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<CreateOrganisationRequest>
  ): CreateOrganisationRequest {
    const message = createBaseCreateOrganisationRequest();
    message.organisation =
      object.organisation !== undefined && object.organisation !== null
        ? Organisation.fromPartial(object.organisation)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(
  CreateOrganisationRequest.$type,
  CreateOrganisationRequest
);

function createBaseCreateOrganisationResponse(): CreateOrganisationResponse {
  return {
    $type: "oditoo.v1.CreateOrganisationResponse",
    organisation: undefined,
  };
}

export const CreateOrganisationResponse = {
  $type: "oditoo.v1.CreateOrganisationResponse" as const,

  encode(
    message: CreateOrganisationResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.organisation !== undefined) {
      Organisation.encode(
        message.organisation,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): CreateOrganisationResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrganisationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.organisation = Organisation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateOrganisationResponse {
    return {
      $type: CreateOrganisationResponse.$type,
      organisation: isSet(object.organisation)
        ? Organisation.fromJSON(object.organisation)
        : undefined,
    };
  },

  toJSON(message: CreateOrganisationResponse): unknown {
    const obj: any = {};
    message.organisation !== undefined &&
      (obj.organisation = message.organisation
        ? Organisation.toJSON(message.organisation)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<CreateOrganisationResponse>
  ): CreateOrganisationResponse {
    const message = createBaseCreateOrganisationResponse();
    message.organisation =
      object.organisation !== undefined && object.organisation !== null
        ? Organisation.fromPartial(object.organisation)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(
  CreateOrganisationResponse.$type,
  CreateOrganisationResponse
);

function createBaseReadOrganisationRequest(): ReadOrganisationRequest {
  return { $type: "oditoo.v1.ReadOrganisationRequest", idOrName: "" };
}

export const ReadOrganisationRequest = {
  $type: "oditoo.v1.ReadOrganisationRequest" as const,

  encode(
    message: ReadOrganisationRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.idOrName !== "") {
      writer.uint32(10).string(message.idOrName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ReadOrganisationRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReadOrganisationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.idOrName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReadOrganisationRequest {
    return {
      $type: ReadOrganisationRequest.$type,
      idOrName: isSet(object.idOrName) ? String(object.idOrName) : "",
    };
  },

  toJSON(message: ReadOrganisationRequest): unknown {
    const obj: any = {};
    message.idOrName !== undefined && (obj.idOrName = message.idOrName);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ReadOrganisationRequest>
  ): ReadOrganisationRequest {
    const message = createBaseReadOrganisationRequest();
    message.idOrName = object.idOrName ?? "";
    return message;
  },
};

messageTypeRegistry.set(ReadOrganisationRequest.$type, ReadOrganisationRequest);

function createBaseReadOrganisationResponse(): ReadOrganisationResponse {
  return {
    $type: "oditoo.v1.ReadOrganisationResponse",
    organisation: undefined,
  };
}

export const ReadOrganisationResponse = {
  $type: "oditoo.v1.ReadOrganisationResponse" as const,

  encode(
    message: ReadOrganisationResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.organisation !== undefined) {
      Organisation.encode(
        message.organisation,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ReadOrganisationResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReadOrganisationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.organisation = Organisation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReadOrganisationResponse {
    return {
      $type: ReadOrganisationResponse.$type,
      organisation: isSet(object.organisation)
        ? Organisation.fromJSON(object.organisation)
        : undefined,
    };
  },

  toJSON(message: ReadOrganisationResponse): unknown {
    const obj: any = {};
    message.organisation !== undefined &&
      (obj.organisation = message.organisation
        ? Organisation.toJSON(message.organisation)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ReadOrganisationResponse>
  ): ReadOrganisationResponse {
    const message = createBaseReadOrganisationResponse();
    message.organisation =
      object.organisation !== undefined && object.organisation !== null
        ? Organisation.fromPartial(object.organisation)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(
  ReadOrganisationResponse.$type,
  ReadOrganisationResponse
);

function createBaseUpdateOrganisationRequest(): UpdateOrganisationRequest {
  return {
    $type: "oditoo.v1.UpdateOrganisationRequest",
    organisation: undefined,
  };
}

export const UpdateOrganisationRequest = {
  $type: "oditoo.v1.UpdateOrganisationRequest" as const,

  encode(
    message: UpdateOrganisationRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.organisation !== undefined) {
      Organisation.encode(
        message.organisation,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UpdateOrganisationRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOrganisationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.organisation = Organisation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateOrganisationRequest {
    return {
      $type: UpdateOrganisationRequest.$type,
      organisation: isSet(object.organisation)
        ? Organisation.fromJSON(object.organisation)
        : undefined,
    };
  },

  toJSON(message: UpdateOrganisationRequest): unknown {
    const obj: any = {};
    message.organisation !== undefined &&
      (obj.organisation = message.organisation
        ? Organisation.toJSON(message.organisation)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<UpdateOrganisationRequest>
  ): UpdateOrganisationRequest {
    const message = createBaseUpdateOrganisationRequest();
    message.organisation =
      object.organisation !== undefined && object.organisation !== null
        ? Organisation.fromPartial(object.organisation)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(
  UpdateOrganisationRequest.$type,
  UpdateOrganisationRequest
);

function createBaseUpdateOrganisationResponse(): UpdateOrganisationResponse {
  return {
    $type: "oditoo.v1.UpdateOrganisationResponse",
    organisation: undefined,
  };
}

export const UpdateOrganisationResponse = {
  $type: "oditoo.v1.UpdateOrganisationResponse" as const,

  encode(
    message: UpdateOrganisationResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.organisation !== undefined) {
      Organisation.encode(
        message.organisation,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): UpdateOrganisationResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOrganisationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.organisation = Organisation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateOrganisationResponse {
    return {
      $type: UpdateOrganisationResponse.$type,
      organisation: isSet(object.organisation)
        ? Organisation.fromJSON(object.organisation)
        : undefined,
    };
  },

  toJSON(message: UpdateOrganisationResponse): unknown {
    const obj: any = {};
    message.organisation !== undefined &&
      (obj.organisation = message.organisation
        ? Organisation.toJSON(message.organisation)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<UpdateOrganisationResponse>
  ): UpdateOrganisationResponse {
    const message = createBaseUpdateOrganisationResponse();
    message.organisation =
      object.organisation !== undefined && object.organisation !== null
        ? Organisation.fromPartial(object.organisation)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(
  UpdateOrganisationResponse.$type,
  UpdateOrganisationResponse
);

function createBaseListOrganisationsRequest(): ListOrganisationsRequest {
  return { $type: "oditoo.v1.ListOrganisationsRequest", limit: 0, offset: 0 };
}

export const ListOrganisationsRequest = {
  $type: "oditoo.v1.ListOrganisationsRequest" as const,

  encode(
    message: ListOrganisationsRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.limit !== 0) {
      writer.uint32(8).int32(message.limit);
    }
    if (message.offset !== 0) {
      writer.uint32(16).int32(message.offset);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOrganisationsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOrganisationsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = reader.int32();
          break;
        case 2:
          message.offset = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ListOrganisationsRequest {
    return {
      $type: ListOrganisationsRequest.$type,
      limit: isSet(object.limit) ? Number(object.limit) : 0,
      offset: isSet(object.offset) ? Number(object.offset) : 0,
    };
  },

  toJSON(message: ListOrganisationsRequest): unknown {
    const obj: any = {};
    message.limit !== undefined && (obj.limit = Math.round(message.limit));
    message.offset !== undefined && (obj.offset = Math.round(message.offset));
    return obj;
  },

  fromPartial(
    object: DeepPartial<ListOrganisationsRequest>
  ): ListOrganisationsRequest {
    const message = createBaseListOrganisationsRequest();
    message.limit = object.limit ?? 0;
    message.offset = object.offset ?? 0;
    return message;
  },
};

messageTypeRegistry.set(
  ListOrganisationsRequest.$type,
  ListOrganisationsRequest
);

function createBaseListOrganisationsResponse(): ListOrganisationsResponse {
  return { $type: "oditoo.v1.ListOrganisationsResponse", organisations: [] };
}

export const ListOrganisationsResponse = {
  $type: "oditoo.v1.ListOrganisationsResponse" as const,

  encode(
    message: ListOrganisationsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.organisations) {
      Organisation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ListOrganisationsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOrganisationsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.organisations.push(
            Organisation.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ListOrganisationsResponse {
    return {
      $type: ListOrganisationsResponse.$type,
      organisations: Array.isArray(object?.organisations)
        ? object.organisations.map((e: any) => Organisation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListOrganisationsResponse): unknown {
    const obj: any = {};
    if (message.organisations) {
      obj.organisations = message.organisations.map((e) =>
        e ? Organisation.toJSON(e) : undefined
      );
    } else {
      obj.organisations = [];
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<ListOrganisationsResponse>
  ): ListOrganisationsResponse {
    const message = createBaseListOrganisationsResponse();
    message.organisations =
      object.organisations?.map((e) => Organisation.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(
  ListOrganisationsResponse.$type,
  ListOrganisationsResponse
);

function createBaseDeleteOrganisationRequest(): DeleteOrganisationRequest {
  return { $type: "oditoo.v1.DeleteOrganisationRequest", id: "" };
}

export const DeleteOrganisationRequest = {
  $type: "oditoo.v1.DeleteOrganisationRequest" as const,

  encode(
    message: DeleteOrganisationRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DeleteOrganisationRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteOrganisationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteOrganisationRequest {
    return {
      $type: DeleteOrganisationRequest.$type,
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: DeleteOrganisationRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(
    object: DeepPartial<DeleteOrganisationRequest>
  ): DeleteOrganisationRequest {
    const message = createBaseDeleteOrganisationRequest();
    message.id = object.id ?? "";
    return message;
  },
};

messageTypeRegistry.set(
  DeleteOrganisationRequest.$type,
  DeleteOrganisationRequest
);

function createBaseDeleteOrganisationResponse(): DeleteOrganisationResponse {
  return { $type: "oditoo.v1.DeleteOrganisationResponse" };
}

export const DeleteOrganisationResponse = {
  $type: "oditoo.v1.DeleteOrganisationResponse" as const,

  encode(
    _: DeleteOrganisationResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DeleteOrganisationResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteOrganisationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): DeleteOrganisationResponse {
    return {
      $type: DeleteOrganisationResponse.$type,
    };
  },

  toJSON(_: DeleteOrganisationResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(
    _: DeepPartial<DeleteOrganisationResponse>
  ): DeleteOrganisationResponse {
    const message = createBaseDeleteOrganisationResponse();
    return message;
  },
};

messageTypeRegistry.set(
  DeleteOrganisationResponse.$type,
  DeleteOrganisationResponse
);

export type OrganisationsDefinition = typeof OrganisationsDefinition;
export const OrganisationsDefinition = {
  name: "Organisations",
  fullName: "oditoo.v1.Organisations",
  methods: {
    createOrganisation: {
      name: "CreateOrganisation",
      requestType: CreateOrganisationRequest,
      requestStream: false,
      responseType: CreateOrganisationResponse,
      responseStream: false,
      options: {},
    },
    readOrganisation: {
      name: "ReadOrganisation",
      requestType: ReadOrganisationRequest,
      requestStream: false,
      responseType: ReadOrganisationResponse,
      responseStream: false,
      options: {},
    },
    updateOrganisation: {
      name: "UpdateOrganisation",
      requestType: UpdateOrganisationRequest,
      requestStream: false,
      responseType: UpdateOrganisationResponse,
      responseStream: false,
      options: {},
    },
    listOrganisations: {
      name: "ListOrganisations",
      requestType: ListOrganisationsRequest,
      requestStream: false,
      responseType: ListOrganisationsResponse,
      responseStream: false,
      options: {},
    },
    deleteOrganisation: {
      name: "DeleteOrganisation",
      requestType: DeleteOrganisationRequest,
      requestStream: false,
      responseType: DeleteOrganisationResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface OrganisationsServiceImplementation<CallContextExt = {}> {
  createOrganisation(
    request: CreateOrganisationRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<CreateOrganisationResponse>>;
  readOrganisation(
    request: ReadOrganisationRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<ReadOrganisationResponse>>;
  updateOrganisation(
    request: UpdateOrganisationRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<UpdateOrganisationResponse>>;
  listOrganisations(
    request: ListOrganisationsRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<ListOrganisationsResponse>>;
  deleteOrganisation(
    request: DeleteOrganisationRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<DeleteOrganisationResponse>>;
}

export interface OrganisationsClient<CallOptionsExt = {}> {
  createOrganisation(
    request: DeepPartial<CreateOrganisationRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<CreateOrganisationResponse>;
  readOrganisation(
    request: DeepPartial<ReadOrganisationRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<ReadOrganisationResponse>;
  updateOrganisation(
    request: DeepPartial<UpdateOrganisationRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<UpdateOrganisationResponse>;
  listOrganisations(
    request: DeepPartial<ListOrganisationsRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<ListOrganisationsResponse>;
  deleteOrganisation(
    request: DeepPartial<DeleteOrganisationRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<DeleteOrganisationResponse>;
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
