// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {Check, ContentCopyOutlined} from "@mui/icons-material";
import {Box, IconButton, Stack, Theme} from "@mui/material";
import React, {useEffect, useState} from "react";
import "./code.css";

export interface CodeProps {
  code?: string;
}

export const Code = ({code = ''}: CodeProps) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    setCopied(false)
  }, [code])
  const copyToClipboard = async () => {
    await navigator.clipboard?.writeText(code);
    setCopied(true);
  };
  return (
    <Box>
      <Stack
        component="code"
        direction='row'
        sx={{
          backgroundColor: "black",
          color: "primary.main",
          overflow: "scroll",
          paddingLeft: 2,
          border: `1px solid #e0e0e0`,
          borderRadius: (theme: Theme) => `${theme.shape.borderRadius}px`,
        }}
      >
        <Box
          component="pre"
          flex={1}
        >
          {code}
        </Box>
        <IconButton
          sx={{
            color: "white",
            opacity: 0.5,
            "&:hover": {opacity: 1}
          }}
          onClick={copyToClipboard}
        >
          {copied ? <Check/> : <ContentCopyOutlined/>}
        </IconButton>
      </Stack>
    </Box>
  );
};
