// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


import {defaultPadding, defaultSpacing} from "@ags-oditoo/theme";
import {Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {User} from "../api/generated/api/v1/types";
import {Admin, OrgAdmin, OrgReader, Reader} from "../api/roles";
import {useAPI} from "../api/useAPI";
import {ExecutionsTable} from "../Components/Executions/ExecutionsTable";
import {Loading} from "../Components/Loading";
import {UserCard} from "../Components/Users/UserCard";
import {useAuthenticatedAsyncOnce, useError} from "../hooks";
import {MainRoutesRegistry} from "../routes";


export const UserDetailsPage = () => {
  const [user, setUser] = useState<User | undefined>()
  const {api} = useAPI();
  const {name} = useParams<{ name: string }>()
  const [Error, _, withError] = useError()
  useAuthenticatedAsyncOnce(withError(async () => {
    const {user} = await api.users.readUser({idOrName: name})
    if (!user) return
    setUser({
      ...user,
      executions: user.executions?.map(e => ({
          ...e,
          user: user,
          organisation: user.organisation
        }
      )) ?? []
    })
  }))
  if (!user) return <Loading/>
  return (
    <Stack flex={1} spacing={defaultSpacing} sx={{padding: defaultPadding}}>
      <Error/>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h6'>User</Typography>
      </Stack>
      <Stack>
        <UserCard
          user={user}
          organisationLink={organisation => MainRoutesRegistry.organisationDetails.navigate(organisation)}
        />
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h6'>Executions</Typography>
      </Stack>
      <Stack flex={1}>
        <ExecutionsTable
          executions={user.executions}
          hide={{organisation: true}}
          organisationLink={organisation => MainRoutesRegistry.organisationDetails.navigate(organisation)}
          versionLink={version => MainRoutesRegistry.releaseDetails.navigate(version) ?? ''}
        />
      </Stack>
    </Stack>
  )
}


MainRoutesRegistry['userDetails'] = {
  path: '/users/:name',
  component: <UserDetailsPage/>,
  priority: 900,
  public: false,
  navigate: (name: string) => '/users/' + name,
  roles: [Admin, Reader, OrgAdmin, OrgReader],
}
