// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {ArrowBackOutlined, ArrowForwardOutlined} from "@mui/icons-material";
import {Button, Pagination, Paper, Stack} from "@mui/material";
import {styled} from "@mui/material/styles";
import {DataGrid as MuiDataGrid, DataGridProps} from "@mui/x-data-grid";
import {GridColDef as DataGridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import React, {forwardRef} from "react";

export interface TableProps extends Omit<DataGridProps, 'columns'> {
  columns: (DataGridColDef&{hidden?: boolean})[]
}

const DataGrid = styled(MuiDataGrid)(({theme}) => ({
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden !important',
  },
  '& .MuiDataGrid-columnHeader--sorted': {
    color: theme.palette.primary.main,
  },
  '& .MuiDataGrid-booleanCell[data-value="true"]': {
    color: theme.palette.success.main,
  },
  '& .MuiDataGrid-booleanCell[data-value="false"]': {
    color: theme.palette.error.main,
  }
}))

export const Table = forwardRef<any, TableProps>(({rows, columns, ...props}, ref) => {
  const pageSize = 10
  const [page, setPage] = React.useState(1)
  const pageCount = Math.ceil(rows.length / pageSize)
  return (
    <Stack flex={1}>
      <Paper sx={{flex: 1}}>
        <DataGrid
          ref={ref}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
          disableDensitySelector
          hideFooter={true}
          slotProps={{
            footer: {

            }
          }}
          paginationModel={{
            page: page - 1,
            pageSize,
          }}
          {...props}
          rows={rows}
          columns={columns.filter(v => !v.hidden)}
        />
      </Paper>
      <Stack direction='row' justifyContent='space-between'>
        <Button
          startIcon={< ArrowBackOutlined/>}
          onClick={() => setPage(page > 1 ? page - 1 : 1)}
          disabled={page === 1}
        >
          Previous
        </Button>
        <Pagination
          hideNextButton
          hidePrevButton
          color='primary'
          siblingCount={1}
          boundaryCount={2}
          page={page}
          count={pageCount}
          onChange={(_, page) => setPage(page)}
        />
        <Button
          endIcon={< ArrowForwardOutlined/>}
          onClick={() => setPage(page < pageCount ? page + 1 : pageCount)}
          disabled={page === pageCount}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  )
})
