// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {Code, ResponsiveButton} from "@ags-oditoo/components";
import {QRCodeDialog} from "@ags-oditoo/components/src/QRCodeDialog";
import {useAsync} from "@ags-oditoo/hooks";
import {defaultPadding, defaultSpacing} from "@ags-oditoo/theme";
import {ContentPasteOutlined, DownloadOutlined, QrCode2Outlined, UploadOutlined} from "@mui/icons-material";
import {Button, Paper, Stack, TextField, Typography} from "@mui/material";
import React from "react";
import {useSearchParams} from "react-router-dom";
import {Admin, Anonymous, Authenticated, OrgAdmin, OrgReader, Reader, User} from "../api/roles";
import {useAPI} from "../api/useAPI";
import {useError} from "../hooks";
import {MainRoutesRegistry} from "../routes";

export const TokenPage = () => {
  const {api} = useAPI();
  const [request, setRequest] = React.useState('')
  const [response, setResponse] = React.useState('')
  const [showQRCode, setShowQRCode] = React.useState(false)
  const [qrCode, setQRCode] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [params] = useSearchParams();
  const [Error, setError] = useError([request])

  useAsync(async () => {
    const request = params.get('request')
    if (request) {
      setRequest(request)
      await submit(request)
    }
  }, [params])

  const pasteFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setRequest(text);
    } catch (e: any) {
      setError(e.message);
    }
  }


  const handleFile = async (file: File | undefined) => {
    if (!file) {
      return;
    }
    try {
      const text = await file.text();
      setRequest(text);
    } catch (e: any) {
      setError(e);
    }
  }

  const handleSubmit = async () => {
    await submit(request)
  }

  const submit = async (code: string) => {
    setIsSubmitting(true)
    try {
      const {executionToken} = await api.exec.requestExecution({requestToken: code})
      setResponse(executionToken)
      setQRCode(executionToken)
    } catch (e: any) {
      setError(e.message)
      console.error(e)
    }
    setIsSubmitting(false)
  }

  return (
    <Stack flex={1} spacing={defaultSpacing} sx={{padding: defaultPadding}}>
      <Error/>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h6'>Request Token</Typography>
        {
          response ? (
            <Stack direction='row'>
              <ResponsiveButton onClick={() => setShowQRCode(true)} icon={<QrCode2Outlined/>} label="Show QR Code"/>
              <ResponsiveButton
                icon={<DownloadOutlined/>}
                download="oditoo-token.txt"
                href={"data:text/plain;charset=utf-8," + encodeURIComponent(response)}
                LinkComponent="a"
                label="Download Code"/>
            </Stack>
          ) : (
            <Stack direction='row'>
              <ResponsiveButton component="label" label={"Load from file"} icon={<UploadOutlined/>}>
                <input
                  type="file"
                  hidden
                  accept="text/*"
                  onChange={e => handleFile(e.target.files?.[0])}
                />
              </ResponsiveButton>
              {
                typeof navigator.clipboard?.readText === 'function' && <ResponsiveButton
                  label="Paste clipboard"
                  icon={<ContentPasteOutlined/>}
                  onClick={pasteFromClipboard}
                />
              }
            </Stack>
          )
        }
      </Stack>
      {!response
        ? (
          <Paper sx={{padding: 2}}>
            <Stack>
              <TextField
                InputProps={{
                  sx: {
                    background: 'black',
                    color: 'white',
                    margin: 0,
                  }
                }}
                disabled={isSubmitting || !!response}
                multiline
                fullWidth
                rows={5}
                placeholder="Paste your request token here..."
                onChange={e => setRequest(e.target.value)}
                value={request}
              />
              <Button disabled={isSubmitting} onClick={handleSubmit}>Submit</Button>
            </Stack>
          </Paper>
        )
        : (
          <Paper sx={{padding: 2}}>
            <Stack>
              <Code code={response}/>
            </Stack>
          </Paper>
        )
      }
      <QRCodeDialog
        sx={{padding: 2}}
        open={showQRCode}
        onClose={() => setShowQRCode(false)}
        value={qrCode}
      />
    </Stack>
  )
}

MainRoutesRegistry['token'] = {
  path: '/token',
  component: <TokenPage/>,
  priority: 10000,
  public: false,
  label: 'Token',
  show: false,
  navigate: () => '/token',
  roles: [Admin, Reader, OrgAdmin, OrgReader, User, Authenticated, Anonymous],
}
