/* eslint-disable */
import { messageTypeRegistry } from "../../typeRegistry";
import { Role, Token, roleFromJSON, roleToJSON } from "./types";
import { CallContext, CallOptions } from "nice-grpc-common";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "oditoo.v1";

export interface LoginRequest {
  $type: "oditoo.v1.LoginRequest";
  /** Email is the mail of the user. */
  email: string;
  /** Password is the password of the user. */
  password: string;
}

export interface LoginResponse {
  $type: "oditoo.v1.LoginResponse";
  /** Token is the JWT token. */
  token: string;
}

export interface LogoutRequest {
  $type: "oditoo.v1.LogoutRequest";
}

export interface LogoutResponse {
  $type: "oditoo.v1.LogoutResponse";
}

export interface CreateTokenRequest {
  $type: "oditoo.v1.CreateTokenRequest";
  name: string;
  role: Role;
}

export interface CreateTokenResponse {
  $type: "oditoo.v1.CreateTokenResponse";
  /** Token is the API token. */
  token: string;
}

export interface ListTokensRequest {
  $type: "oditoo.v1.ListTokensRequest";
  userId?: string | undefined;
}

export interface ListTokensResponse {
  $type: "oditoo.v1.ListTokensResponse";
  /** Tokens is the list of API tokens. */
  tokens: Token[];
}

export interface DeleteTokenRequest {
  $type: "oditoo.v1.DeleteTokenRequest";
  /** ID is the API token ID. */
  id: string;
}

export interface DeleteTokenResponse {
  $type: "oditoo.v1.DeleteTokenResponse";
}

function createBaseLoginRequest(): LoginRequest {
  return { $type: "oditoo.v1.LoginRequest", email: "", password: "" };
}

export const LoginRequest = {
  $type: "oditoo.v1.LoginRequest" as const,

  encode(
    message: LoginRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        case 2:
          message.password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LoginRequest {
    return {
      $type: LoginRequest.$type,
      email: isSet(object.email) ? String(object.email) : "",
      password: isSet(object.password) ? String(object.password) : "",
    };
  },

  toJSON(message: LoginRequest): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.password !== undefined && (obj.password = message.password);
    return obj;
  },

  fromPartial(object: DeepPartial<LoginRequest>): LoginRequest {
    const message = createBaseLoginRequest();
    message.email = object.email ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

messageTypeRegistry.set(LoginRequest.$type, LoginRequest);

function createBaseLoginResponse(): LoginResponse {
  return { $type: "oditoo.v1.LoginResponse", token: "" };
}

export const LoginResponse = {
  $type: "oditoo.v1.LoginResponse" as const,

  encode(
    message: LoginResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LoginResponse {
    return {
      $type: LoginResponse.$type,
      token: isSet(object.token) ? String(object.token) : "",
    };
  },

  toJSON(message: LoginResponse): unknown {
    const obj: any = {};
    message.token !== undefined && (obj.token = message.token);
    return obj;
  },

  fromPartial(object: DeepPartial<LoginResponse>): LoginResponse {
    const message = createBaseLoginResponse();
    message.token = object.token ?? "";
    return message;
  },
};

messageTypeRegistry.set(LoginResponse.$type, LoginResponse);

function createBaseLogoutRequest(): LogoutRequest {
  return { $type: "oditoo.v1.LogoutRequest" };
}

export const LogoutRequest = {
  $type: "oditoo.v1.LogoutRequest" as const,

  encode(
    _: LogoutRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogoutRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogoutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): LogoutRequest {
    return {
      $type: LogoutRequest.$type,
    };
  },

  toJSON(_: LogoutRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<LogoutRequest>): LogoutRequest {
    const message = createBaseLogoutRequest();
    return message;
  },
};

messageTypeRegistry.set(LogoutRequest.$type, LogoutRequest);

function createBaseLogoutResponse(): LogoutResponse {
  return { $type: "oditoo.v1.LogoutResponse" };
}

export const LogoutResponse = {
  $type: "oditoo.v1.LogoutResponse" as const,

  encode(
    _: LogoutResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogoutResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogoutResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): LogoutResponse {
    return {
      $type: LogoutResponse.$type,
    };
  },

  toJSON(_: LogoutResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<LogoutResponse>): LogoutResponse {
    const message = createBaseLogoutResponse();
    return message;
  },
};

messageTypeRegistry.set(LogoutResponse.$type, LogoutResponse);

function createBaseCreateTokenRequest(): CreateTokenRequest {
  return { $type: "oditoo.v1.CreateTokenRequest", name: "", role: 0 };
}

export const CreateTokenRequest = {
  $type: "oditoo.v1.CreateTokenRequest" as const,

  encode(
    message: CreateTokenRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.role !== 0) {
      writer.uint32(16).int32(message.role);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTokenRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.role = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateTokenRequest {
    return {
      $type: CreateTokenRequest.$type,
      name: isSet(object.name) ? String(object.name) : "",
      role: isSet(object.role) ? roleFromJSON(object.role) : 0,
    };
  },

  toJSON(message: CreateTokenRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.role !== undefined && (obj.role = roleToJSON(message.role));
    return obj;
  },

  fromPartial(object: DeepPartial<CreateTokenRequest>): CreateTokenRequest {
    const message = createBaseCreateTokenRequest();
    message.name = object.name ?? "";
    message.role = object.role ?? 0;
    return message;
  },
};

messageTypeRegistry.set(CreateTokenRequest.$type, CreateTokenRequest);

function createBaseCreateTokenResponse(): CreateTokenResponse {
  return { $type: "oditoo.v1.CreateTokenResponse", token: "" };
}

export const CreateTokenResponse = {
  $type: "oditoo.v1.CreateTokenResponse" as const,

  encode(
    message: CreateTokenResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTokenResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTokenResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateTokenResponse {
    return {
      $type: CreateTokenResponse.$type,
      token: isSet(object.token) ? String(object.token) : "",
    };
  },

  toJSON(message: CreateTokenResponse): unknown {
    const obj: any = {};
    message.token !== undefined && (obj.token = message.token);
    return obj;
  },

  fromPartial(object: DeepPartial<CreateTokenResponse>): CreateTokenResponse {
    const message = createBaseCreateTokenResponse();
    message.token = object.token ?? "";
    return message;
  },
};

messageTypeRegistry.set(CreateTokenResponse.$type, CreateTokenResponse);

function createBaseListTokensRequest(): ListTokensRequest {
  return { $type: "oditoo.v1.ListTokensRequest", userId: undefined };
}

export const ListTokensRequest = {
  $type: "oditoo.v1.ListTokensRequest" as const,

  encode(
    message: ListTokensRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.userId !== undefined) {
      writer.uint32(10).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTokensRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTokensRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.userId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ListTokensRequest {
    return {
      $type: ListTokensRequest.$type,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
    };
  },

  toJSON(message: ListTokensRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  fromPartial(object: DeepPartial<ListTokensRequest>): ListTokensRequest {
    const message = createBaseListTokensRequest();
    message.userId = object.userId ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(ListTokensRequest.$type, ListTokensRequest);

function createBaseListTokensResponse(): ListTokensResponse {
  return { $type: "oditoo.v1.ListTokensResponse", tokens: [] };
}

export const ListTokensResponse = {
  $type: "oditoo.v1.ListTokensResponse" as const,

  encode(
    message: ListTokensResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.tokens) {
      Token.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTokensResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTokensResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.tokens.push(Token.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ListTokensResponse {
    return {
      $type: ListTokensResponse.$type,
      tokens: Array.isArray(object?.tokens)
        ? object.tokens.map((e: any) => Token.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListTokensResponse): unknown {
    const obj: any = {};
    if (message.tokens) {
      obj.tokens = message.tokens.map((e) => (e ? Token.toJSON(e) : undefined));
    } else {
      obj.tokens = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<ListTokensResponse>): ListTokensResponse {
    const message = createBaseListTokensResponse();
    message.tokens = object.tokens?.map((e) => Token.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ListTokensResponse.$type, ListTokensResponse);

function createBaseDeleteTokenRequest(): DeleteTokenRequest {
  return { $type: "oditoo.v1.DeleteTokenRequest", id: "" };
}

export const DeleteTokenRequest = {
  $type: "oditoo.v1.DeleteTokenRequest" as const,

  encode(
    message: DeleteTokenRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTokenRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteTokenRequest {
    return {
      $type: DeleteTokenRequest.$type,
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: DeleteTokenRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<DeleteTokenRequest>): DeleteTokenRequest {
    const message = createBaseDeleteTokenRequest();
    message.id = object.id ?? "";
    return message;
  },
};

messageTypeRegistry.set(DeleteTokenRequest.$type, DeleteTokenRequest);

function createBaseDeleteTokenResponse(): DeleteTokenResponse {
  return { $type: "oditoo.v1.DeleteTokenResponse" };
}

export const DeleteTokenResponse = {
  $type: "oditoo.v1.DeleteTokenResponse" as const,

  encode(
    _: DeleteTokenResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTokenResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTokenResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): DeleteTokenResponse {
    return {
      $type: DeleteTokenResponse.$type,
    };
  },

  toJSON(_: DeleteTokenResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<DeleteTokenResponse>): DeleteTokenResponse {
    const message = createBaseDeleteTokenResponse();
    return message;
  },
};

messageTypeRegistry.set(DeleteTokenResponse.$type, DeleteTokenResponse);

export type AuthDefinition = typeof AuthDefinition;
export const AuthDefinition = {
  name: "Auth",
  fullName: "oditoo.v1.Auth",
  methods: {
    login: {
      name: "Login",
      requestType: LoginRequest,
      requestStream: false,
      responseType: LoginResponse,
      responseStream: false,
      options: {},
    },
    logout: {
      name: "Logout",
      requestType: LogoutRequest,
      requestStream: false,
      responseType: LogoutResponse,
      responseStream: false,
      options: {},
    },
    createToken: {
      name: "CreateToken",
      requestType: CreateTokenRequest,
      requestStream: false,
      responseType: CreateTokenResponse,
      responseStream: false,
      options: {},
    },
    listTokens: {
      name: "ListTokens",
      requestType: ListTokensRequest,
      requestStream: false,
      responseType: ListTokensResponse,
      responseStream: false,
      options: {},
    },
    deleteToken: {
      name: "DeleteToken",
      requestType: DeleteTokenRequest,
      requestStream: false,
      responseType: DeleteTokenResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface AuthServiceImplementation<CallContextExt = {}> {
  login(
    request: LoginRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<LoginResponse>>;
  logout(
    request: LogoutRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<LogoutResponse>>;
  createToken(
    request: CreateTokenRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<CreateTokenResponse>>;
  listTokens(
    request: ListTokensRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<ListTokensResponse>>;
  deleteToken(
    request: DeleteTokenRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<DeleteTokenResponse>>;
}

export interface AuthClient<CallOptionsExt = {}> {
  login(
    request: DeepPartial<LoginRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<LoginResponse>;
  logout(
    request: DeepPartial<LogoutRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<LogoutResponse>;
  createToken(
    request: DeepPartial<CreateTokenRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<CreateTokenResponse>;
  listTokens(
    request: DeepPartial<ListTokensRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<ListTokensResponse>;
  deleteToken(
    request: DeepPartial<DeleteTokenRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<DeleteTokenResponse>;
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
