// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {Tab, Tabs} from "@ags-oditoo/components";
import {defaultPadding, defaultSpacing} from "@ags-oditoo/theme";
import {PeopleOutlined, PlayArrowOutlined} from "@mui/icons-material";
import {Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Organisation} from "../api/generated/api/v1/types";
import {Admin, Reader} from "../api/roles";
import {useAPI} from "../api/useAPI";
import {ExecutionsTable} from "../Components/Executions/ExecutionsTable";
import {Loading} from "../Components/Loading";
import {OrganisationCard} from "../Components/Organisations/OrganisationCard";
import {UsersTable} from "../Components/Users/UsersTable";
import {useAuthenticatedAsyncOnce, useError} from "../hooks";
import {MainRoutesRegistry} from "../routes";

export const OrganisationDetailsPage = () => {
  const [organisation, setOrganisation] = useState<Organisation | undefined>()
  const {api} = useAPI();
  const {name} = useParams<{ name: string }>()
  const [Error, _, withError] = useError()
  useAuthenticatedAsyncOnce(withError(async () => {
    const {organisation} = await api.organisations.readOrganisation({idOrName: name})
    if (!organisation) return
    setOrganisation(organisation)
  }))

  const [selectedTab, setSelectedTab] = useState<'Users' | 'Executions'>('Users')
  useEffect(() => {
    console.log('selectedTab', selectedTab)
  }, [selectedTab])

  if (!organisation) return <Loading/>
  return (
    <Stack flex={1} spacing={defaultSpacing} sx={{padding: defaultPadding}}>
      <Error/>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h6'>Organisation</Typography>
      </Stack>
      <Stack>
        <OrganisationCard organisation={organisation}/>
      </Stack>
      <Tabs value={selectedTab} onChange={(_, value) => setSelectedTab(value)}>
        <Tab label='Users' value='Users' iconPosition="start" icon={<PeopleOutlined/>}/>
        <Tab label='Executions' value='Executions' iconPosition="start" icon={<PlayArrowOutlined/>}/>
      </Tabs>
      {
        selectedTab === 'Users' && (
          <Stack flex={1}>
            <UsersTable
              users={organisation.users}
              link={user => MainRoutesRegistry.userDetails.navigate(user?.name) ?? ''}
              hideOrganisation
            />
          </Stack>
        )
      }
      {
        selectedTab === 'Executions' && (
          <Stack flex={1}>
            <ExecutionsTable
              executions={organisation.executions}
              hide={{organisation: true}}
              userLink={user => MainRoutesRegistry.userDetails.navigate(user?.name) ?? ''}
              versionLink={version => MainRoutesRegistry.releaseDetails.navigate(version) ?? ''}
            />
          </Stack>
        )
      }
    </Stack>
  )
}

MainRoutesRegistry['organisationDetails'] = {
  path: '/organisations/:name',
  component: <OrganisationDetailsPage/>,
  priority: 500,
  public: false,
  navigate: (name) => `/organisations/${name}`,
  roles: [Admin, Reader],
}
