// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.


import {CloudDownloadOutlined} from "@mui/icons-material";
import {Box, Button, Card, CardContent, CardHeader, Chip, Link} from "@mui/material";
import React from "react";
import {Release} from "../../api/generated/api/v1/types";

export interface ReleaseCardProps {
  release: Release;
  downloadLink?: string;
  organisationLink?: string;
}

export const ReleaseCard = ({release, downloadLink, organisationLink}: ReleaseCardProps) => (
  <Card>
    <CardHeader
      title={release.version}
      subheader={release.buildDate?.toLocaleDateString()}
      action={downloadLink && (
        <Button component='a' href={downloadLink} variant='text' startIcon={<CloudDownloadOutlined/>}>Download</Button>
      )}
    />
    <CardContent>
      {release.organisation && (
        <Box sx={{display: "inline-flex", direction: "row", flexWrap: "wrap", gap: 2}}>
          <Chip sx={{cursor: 'pointer'}} component={Link} href={organisationLink} label={release.organisation.name}/>
        </Box>
      )
      }
    </CardContent>
  </Card>
)
