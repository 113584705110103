// Copyright 2021 Linka Cloud  All rights reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Slide, useScrollTrigger } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

export interface HideOnScrollProps {
  children: React.ReactElement
  direction?: 'left' | 'right' | 'up' | 'down'
}

export const HideOnScroll = ({ direction = 'down', children }: HideOnScrollProps) => {
  const trigger = useScrollTrigger()
  // const trigger = false
  return (
    <Slide appear={false} direction={direction} in={!trigger} timeout={400}>
      {children}
    </Slide>
  )
}

export const ShowOnBottom = ({ direction = 'up', children }: HideOnScrollProps) => {
  const trigger = useBottomTrigger()
  return (
    <Slide appear={false} direction={direction} in={trigger} timeout={400}>
      {children}
    </Slide>
  )
}

export interface BottomTriggerOptions {
  offset: number
  target?: Node | Window;
}

export const useBottomTrigger = (options?: BottomTriggerOptions) => {
  const offset = options?.offset || 64
  const target = options?.target || window
  const store = React.useRef<boolean>(false)
  const [trigger, setTrigger] = React.useState(false)
  React.useEffect(() => {
    const handleScroll = (e?: any) => {
      if (e?.target?.scrollTop === undefined) {
        return
      }
      const bottom =
        e?.target?.scrollTop + e?.target?.clientHeight + offset >= e?.target?.scrollHeight
      // console.log(e?.target?.scrollTop + e?.target?.clientHeight + offset, bottom)
      if (bottom !== undefined && store.current !== bottom) {
        store.current = bottom
        setTrigger(store.current)
      }
    }

    handleScroll() // Re-evaluate trigger when dependencies change
    target.addEventListener('scroll', handleScroll, true)
    return () => {
      target.removeEventListener('scroll', handleScroll, true)
    }
  }, [target, offset])
  return trigger
}

export interface HideOnScrollAndShowOnBottomProps extends HideOnScrollProps {
  offset?: number
  resetOnLocationChanges?: boolean
}

export const HideOnScrollAndShowAtBottom = ({
  direction = 'up',
  children,
  offset = 64,
  resetOnLocationChanges = true,
}: HideOnScrollAndShowOnBottomProps) => {
  const target = document.getElementById('main') || window
  const scroll = useScrollTrigger({target})
  const bottom = useBottomTrigger({ offset, target })
  const [show, setShow] = useState(!scroll || bottom)
  const location = useLocation()
  const ref = useRef(location.pathname)
  useEffect(() => {
    if (ref.current == location.pathname) {
      setShow(!scroll || bottom)
      return
    }
    ref.current = location.pathname
    if (resetOnLocationChanges) {
      setShow(true)
    }
  }, [scroll, bottom, location])
  return (
    <Slide appear={false} direction={direction} in={show} timeout={400}>
      {children}
    </Slide>
  )
}
