// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {
  AlternateEmailOutlined,
  LockOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material'
import {Button, IconButton, InputAdornment, Link, Stack} from '@mui/material'
import {Formik} from 'formik'
import {useState} from 'react'
import {Credentials, credentialsSchema, PasswordConfirm, passwordConfirmSchema} from '../../api/schemas/auth'
import {defaultSpacing} from "@ags-oditoo/theme";
import {FTextField} from '../Form'

export interface PasswordFormProps {
  onSubmit: (password: PasswordConfirm) => Promise<void>
  submitLabel?: string
}

export const PasswordForm = ({submitLabel, onSubmit}: PasswordFormProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  return (
    <Formik<PasswordConfirm> initialValues={{password: '', passwordConfirm: ''}} validationSchema={passwordConfirmSchema}
                         onSubmit={onSubmit}>
      {({isSubmitting, handleReset, handleSubmit, errors}) => (
        <Stack component='form' onReset={handleReset} onSubmit={handleSubmit} spacing={defaultSpacing}>
          <Stack spacing={defaultSpacing}>
            <FTextField
              name='password'
              label='Password'
              placeholder="•••••••"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockOutlined/>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOffOutlined/> : <VisibilityOutlined/>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FTextField
              name='passwordConfirm'
              label='Confirm Password'
              placeholder="•••••••"
              type={showPasswordConfirm ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockOutlined/>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>
                      {showPasswordConfirm ? <VisibilityOffOutlined/> : <VisibilityOutlined/>}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack direction="column" spacing={defaultSpacing}>
            <Stack direction='row' spacing={4}>
              <Button fullWidth disabled={isSubmitting} type='submit' variant="contained">
                { submitLabel || "Change Password" }
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Formik>
  )
}
