// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {CallOptions, ClientError, ClientMiddlewareCall, Status} from "nice-grpc-common";
import {
  BadRequest,
  BadRequest_FieldViolation,
  errorDetailsClientMiddleware,
  RichClientError
} from "nice-grpc-error-details";
import {Client, createChannel, createClientFactory, FetchTransport, WebsocketTransport} from "nice-grpc-web";
import {AuthDefinition} from "./generated/api/v1/auth.service";
import {ExecutionsDefinition} from "./generated/api/v1/executions.service";
import {OrganisationsDefinition} from "./generated/api/v1/organisations.service";
import {ReleasesDefinition} from "./generated/api/v1/releases.service";
import {UsersDefinition} from "./generated/api/v1/users.service";

export const DOWNLOAD_RELEASE_LINK = '/downloads/releases?id='
export interface APIError {
  status: Status;
  message: string;
  fieldViolations?: BadRequest_FieldViolation[];
}

export interface API {
  auth: Client<AuthDefinition>
  users: Client<UsersDefinition>
  organisations: Client<OrganisationsDefinition>
  releases: Client<ReleasesDefinition>
  exec: Client<ExecutionsDefinition>
}

export const createAPI = (url: string): API => {
  const channel = createChannel(url, FetchTransport({credentials: "include"}));
  const auth = createClientFactory().use(errorDetailsClientMiddleware).use(loggingMiddleware).create(AuthDefinition, channel);
  const create = createClientFactory()
    .use(errorDetailsClientMiddleware)
    .use(loggingMiddleware).create;
    // .use(authMiddleware(auth)).create;
  return {
    auth,
    users: create(UsersDefinition, channel),
    organisations: create(OrganisationsDefinition, channel),
    releases: create(ReleasesDefinition, channel),
    exec: create(ExecutionsDefinition, channel),
  }
}

export function isAbortError(error: unknown): boolean {
  return typeof error === "object" && error !== null && (error as any).name === "AbortError";
}

export const watch = async <T>(fn: (opts: CallOptions) => AsyncIterable<T>, callback: (res: T) => void, signal: AbortSignal) => {
  console.log("start watching");
  try {
    // @ts-ignore
    for await (const res of fn({signal})) {
      console.log(res);
      callback(res);
    }
  } catch (e) {
    if (isAbortError(e)) {
      console.log("request cancelled");
      return;
    }
    console.log("watch failed:", e);
  }
  setTimeout(() => watch(fn, callback, signal), 1000);
};

async function* loggingMiddleware<Request, Response>(call: ClientMiddlewareCall<Request, Response>, options: CallOptions) {
  const { path } = call.method;

  console.log("Client call", path, "start");
  console.debug(call.request);

  try {
    const result = yield* call.next(call.request, options);

    console.log("Client call", path, "end: OK");
    console.debug(result);
    return result;
  } catch (error: any) {
    if (error instanceof RichClientError && error.code === Status.INVALID_ARGUMENT) {
      const fieldViolations: BadRequest_FieldViolation[] = [];
      for (const detail of error.extra) {
        if (detail.$type === BadRequest.$type) {
          fieldViolations.push(...detail.fieldViolations);
        }
      }
      console.log("[Detailed error] Client call", path, `end: ${Status[error.code]}`, call.request, fieldViolations);
      throw {
        status: error.code,
        message: error.details,
        fieldViolations
      } as APIError;
    } else if (error instanceof ClientError) {
      if (error.code === Status.UNAUTHENTICATED) {
        console.log(
          "[Client error] Client call",
          path,
          "token",
          options.metadata?.get("authorization"),
          `end: ${Status[error.code]}: ${error.details}`,
          error
        );
      } else {
        console.log("[Client error] Client call", path, `end: ${Status[error.code]}: ${error.details}`, error);
      }
      throw {
        status: error.code,
        message: error.details === "Response closed without headers" ? "Router unavailable" : error.details
      } as APIError;
    } else if (isAbortError(error)) {
      console.log("[Abort error] Client call", path, "cancel");
    } else {
      console.log("[Unknown Error] Client call", path, `error: ${error?.stack}`);
    }

    throw error;
  }
}
