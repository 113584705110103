// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {Avatar, Box, Card, CardContent, CardHeader, Chip, Link, Typography} from "@mui/material";
import md5 from "md5";
import React from "react";
import {User} from "../../api/generated/api/v1/types";
import {RoleName} from "../../api/roles";

export interface UserCardProps {
  user: User;
  organisationLink?: (organisation?: string) => string;
}

export const UserCard = ({user, organisationLink}: UserCardProps) => (
  <Card>
    <CardHeader
      avatar={
        <Avatar
          src={`https://www.gravatar.com/avatar/${md5(user.email || user.name + "@unknown.org")}?d=retro`}
        />
      }
      title={user.name}
      subheader={<Link href={`mailto:${user.email}`}>{user.email}</Link>}
      action={
        <Box>
          <Typography component={Link} href={organisationLink?.(user.organisation?.name)}>{user.organisation?.name}</Typography>
        </Box>
      }
    />
    <CardContent>
      <Box sx={{display: "inline-flex", direction: "row", flexWrap: "wrap", gap: 2}}>
        <Chip label={RoleName(user.role)}/>
      </Box>
    </CardContent>
  </Card>
)
