// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {
  DarkModeOutlined,
  LightModeOutlined,
  LogoutOutlined, NavigateNext,
  PowerSettingsNewOutlined,
  SettingsBrightnessOutlined
} from "@mui/icons-material";
import {
  Breadcrumbs,
  ButtonGroup,
  IconButton,
  Link,
  LinkProps,
  Stack,
  StackProps,
  Tooltip,
  Typography
} from "@mui/material";
import React from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {useAPI} from "../../api/useAPI";
import {MainRoutesRegistry} from "../../routes";
import {useUiMode} from "@ags-oditoo/theme";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any}/>;

export interface HeaderProps extends StackProps {
  disableBreadcrumb?: boolean;
}

const ThreeStateButton = ({
                            mode,
                            setMode
                          }: { mode: "light" | "dark" | undefined; setMode: (mode: "light" | "dark" | undefined) => void }) => {
  if (mode === "light") {
    return (
      <Tooltip title={`Switch to Dark mode`}>
        <IconButton onClick={() => setMode("dark")}>
          <DarkModeOutlined/>
        </IconButton>
      </Tooltip>
    );
  } else if (mode === "dark") {
    return (
      <Tooltip title={`Switch to Light mode`}>
        <IconButton onClick={() => setMode("light")}>
          <LightModeOutlined/>
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={`Use system settings`}>
        <IconButton onClick={() => setMode(undefined)}>
          <SettingsBrightnessOutlined/>
        </IconButton>
      </Tooltip>
    );
  }
}

export const Header = ({disableBreadcrumb, ...props}: HeaderProps) => {
  const [mode, setMode] = useUiMode();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const routeLabel = (path: string) => Object.values(MainRoutesRegistry).find((r) => r.path === path)?.label;

  const {user, logout} = useAPI();
  return (
    <Stack component="header" direction="row" {...props}>
      {
        !disableBreadcrumb
          ? (
            <Breadcrumbs sx={{flex: 1, alignSelf: "center"}} aria-label="breadcrumb" separator={<NavigateNext fontSize="small" />}>
              <LinkRouter underline="hover" color="inherit" to="/">
                {routeLabel("/")}
              </LinkRouter>
              {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join("/")}`;
                const label = routeLabel(to);
                return last ? (
                  <Typography color="text.primary" key={to}>
                    {label || decodeURI(value)}
                  </Typography>
                ) : (
                  <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                    {label}
                  </LinkRouter>
                );
              })}
            </Breadcrumbs>
          )
          : (
            <Stack flex={1}/>
          )
      }
      <ButtonGroup>
        <Tooltip title={`Switch to ${mode === "light" ? "Dark" : "Light"} mode`}>
          <IconButton onClick={() => setMode(mode === "light" ? "dark" : "light")}>
            {mode === "light" ? <DarkModeOutlined/> : <LightModeOutlined/>}
          </IconButton>
        </Tooltip>
        {
          !!user &&
          <Tooltip title={`Logout`}>
            <IconButton onClick={logout}>
              <PowerSettingsNewOutlined/>
            </IconButton>
          </Tooltip>
        }
      </ButtonGroup>
    </Stack>
  );
};
