// Copyright (C) Agenium Space - All Rights Reserved.
//
// THE CONTENTS OF THIS PROJECT ARE PROPRIETARY AND CONFIDENTIAL.
// UNAUTHORIZED COPYING, TRANSFERRING OR REPRODUCTION OF THE CONTENTS OF THIS PROJECT, VIA ANY MEDIUM IS STRICTLY PROHIBITED.
//
// The receipt or possession of the source code and/or any parts thereof does not convey or imply any right to use them
// for any purpose other than the purpose for which they were provided to you.
//
// The software is provided "AS IS", without warranty of any kind, express or implied, including but not limited to
// the warranties of merchantability, fitness for a particular purpose and non infringement.
// In no event shall the authors or copyright holders be liable for any claim, damages or other liability,
// whether in an action of contract, tort or otherwise, arising from, out of or in connection with the software
// or the use or other dealings in the software.
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import {GroupsOutlined} from "@mui/icons-material";
import {Button, InputAdornment, Stack} from "@mui/material";
import {Formik} from "formik";
import React from "react";
import {DeepPartial, Organisation} from "../../api/generated/api/v1/types";
import {organisationSchema} from "../../api/schemas/organisations";
import {defaultSpacing} from "@ags-oditoo/theme";
import {FTextField} from "../Form";

export interface OrganisationFormProps {
  organisation: DeepPartial<Organisation>
  submitLabel?: string
  onSubmit: (user: DeepPartial<Organisation>) => Promise<void>
  onCancel?: () => void
}

export const OrganisationForm = ({organisation, submitLabel, onSubmit, onCancel}: OrganisationFormProps) => {
  return (
    <Formik<DeepPartial<Organisation>> initialValues={{name:'', ...organisation}} validationSchema={organisationSchema} onSubmit={onSubmit}>
      {({isSubmitting, handleReset, handleSubmit}) => (
        <Stack component='form' onReset={handleReset} onSubmit={handleSubmit} spacing={defaultSpacing}>
          <Stack spacing={defaultSpacing}>
            <FTextField
              name='name'
              label='Name'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <GroupsOutlined/>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack direction='row' spacing={4}>
            {onCancel && <Button fullWidth disabled={isSubmitting} onClick={onCancel} variant="outlined">Cancel</Button>}
            <Button fullWidth disabled={isSubmitting} type='submit' variant="contained">
              {submitLabel || "Save Organisation"}
            </Button>
          </Stack>
        </Stack>
      )}
    </Formik>
  )
}
